// @ts-nocheck

import { useSVG } from "./SVGContext";

export function BottomBar() {
  const { setOpenDialog } = useSVG();

  const style = {
    display: "flex",
    justifyContent: "start",
    gap: "20px",
    padding: "10px 10px",
    fontSize: "0.7em",
    backgroundColor: "#eee",
  };
  return (
    <div className="BottomBar" style={style}>
      <a href="/terms">Terms</a>
      <a href="/privacy">Privacy</a>
      <a href="/contact">Contact</a>
    </div>
  );
}
