import { Slider } from "@mui/material";
import { useState } from "react";
import { useSVG } from "./SVGContext";

export function ZoomSlider({}) {
  const { zoomIn, zoomOut, zoomLevel } = useSVG();

  return (
    <Slider
      size="small"
      min={70}
      max={150}
      step={1}
      value={zoomLevel}
      onChange={(e) => {
        e.stopPropagation();
        e.preventDefault();

        const newZoom = e.target.value;
        if (newZoom > zoomLevel) {
          zoomIn();
        } else {
          zoomOut();
        }
      }}
      style={{
        width: 160,
      }}
    />
  );
}
