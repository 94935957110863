import React, { useState } from "react";
import axios from "axios";
import { req } from "./utils/ServerUtils";
import { useSVG } from "./SVGContext";
import { Button } from "@mui/material";
import { SVGGroupView } from "./views/SVGGroupView";
import SVGDef from "./views/SvgDefView";

const RazorpayPayment = ({ format, setPaymentDialog }) => {
  const { fileManager, pages, setSelectedPage, svgRef, width, height, defs, designTitle, setFullscreenLoading, userInfo } = useSVG();

  const [itemName, setItemName] = useState(designTitle);
  const [amount, setAmount] = useState(10);
  const [btnText, setBtnText] = useState("PURCHASE");

  async function startDownload() {
    await fileManager.save(
      {
        type: format,
        filename: designTitle,
        svgRef,
        width,
        height,
        defs,
        pageCount: pages.length,
        scale: 5
      },
      setSelectedPage,
      function () {
        setFullscreenLoading(false);
        setPaymentDialog(null);
      },
    );
  }

  const initiatePayment = async (cb) => {
    const order = await req("/create_order", "POST", JSON.stringify({ amount, item_name: itemName, user_id: userInfo.id }));
    const { razorpay_order_id, amount: amountInPaise } = order;

    const options = {
      key: "rzp_live_jDwCdhlapaXxjh",
      amount: amountInPaise,
      currency: "INR",
      name: "Design Kart",
      description: `Payment for ${itemName}`,
      order_id: razorpay_order_id,
      handler: async (response) => {
        req(
          "/verify_payment",
          "POST",
          JSON.stringify({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            user_id: userInfo.id,
            item_name: itemName,
            amount,
          }),
        )
          .then(async (result) => {
            console.log(result);
            console.log("Payment Successful");
            cb();
            setFullscreenLoading(true);
            await startDownload();
          })
          .catch((err) => {
            console.error(err);
            cb();
            console.log("Payment Failed");
          });
      },
      prefill: {
        name: "User Name",
        email: "user@example.com",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        minWidth: "400px",
        minHeight: "400px",
      }}
    >
      <div
        style={{
          width: "100%",
          marginBottom: 20
        }}
      >
        <div style={rowStyle}>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Buy Template - {itemName}
          </div>
        </div>
        <AllPages />
        <div style={rowStyle}>
          <div>Amount:</div>
          <div>Rs. {amount}</div>
        </div>
      </div>
      <Button
        variant="contained"
        onClick={() => {
          setBtnText("Processing...");
          initiatePayment(() => {
            setBtnText("PURCHASE");
          });
        }}
        style={{
          width: "100%",
        }}
      >
        {btnText}
      </Button>
    </div>
  );
};

export default RazorpayPayment;

function AllPages({}) {
  const { pages, svgRef, width, height } = useSVG();
  let h = 500;
  const aspectRatio = pages[0]?.width / pages[0]?.height;
  let w = h * aspectRatio;

  if (w > 500) {
    w = 500;
    h = w / aspectRatio;
  } 
  if (h > 500) {
    h = 500;
    w = h * aspectRatio;
  }
  return (
    <div style={{
      width: "100%",
      padding: "10px 5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 10
    }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}  dangerouslySetInnerHTML={{ __html: svgRef.current.firstChild.innerHTML }} style={{
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          width: w,
          height: h,
          border: "1px solid #ddd",
          pointerEvents: "none",
          cursor: "default",
          userSelect: "none",

        }}/>
        {pages.length > 1 && (<div style={{
          textAlign: "center",
        }}>{pages.length} pages</div>)}
    </div>
  );
}
