export function Banner({}) {
  return (
    <div
      style={{
        background: "rgb(33 33 33)",
        padding: 10,
        textAlign: "center",
        fontSize: 40,
        cursor: "pointer",
      }}
      onClick={() => {
        window.location.href = "/";
      }}
    >
      <span style={{ color: "#ff5757" }}>
        <strong>DESIGN</strong>
      </span>
      <span style={{ color: "#ffffff" }}>
        <strong>KART</strong>
      </span>
    </div>
  );
}
