import { Button, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { useEffect, useState } from "react";

export function ArrangeBtn({}) {
  const { setRightPanel } = useSVG();
  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => {
        setRightPanel("arrange");
      }}
    >
      Arrange
    </Button>
  );
}
