// @ts-nocheck
import { useState } from "react";
import { useSVG } from "./SVGContext";
import { Cursor } from "./values/enums";
import { getBoundingBox, isSamePoint, updateBoxes } from "./utils";
import Smartguide from "./Smartguide";
import { transform } from "./utils/transformUtils";
import { duplicate } from "./utils/ClipboardUtils";
import useMultiSelector from "./useMultiSelector";
import { getBoundingBoxOfDomElement, normalize, toDegree, toRadians } from "./utils/utils";
import { PointsOverlays } from "../components/PointsOverlays";
import { findAncestralTransform, getRotateCursor, getScaleCursor, getUnmovingPoint, isBottomRightOfSomeBoundingBox } from "./utils/PointerUtils";

function usePointer(provider) {
  const { setItems, svgRef, commands, setCursor, setOutline, setMultiSelectBox, snapItem, setSelectedIds, setReplacingItemId, setViewBox } = provider;

  let [dragging, setDragging] = useState(null);
  let [mouseDownPoint, setMouseDownPoint] = useState({ x: 0, y: 0 });
  let [mouseMovePoint, setMouseMovePoint] = useState({ x: 0, y: 0 });
  let [mouseWasDown, setMouseWasDownInternal] = useState(false);

  const setMouseWasDown = (v) => {
    mouseWasDown = v;
    setMouseWasDownInternal(v);
  };
  const onToolUnselect = () => {
    setOutline(null);
  };
  const onMouseDownNew = ({ event, xy, target, items, groups, selectedIds, cursor, outline, multiSelectBox }) => {
    setMouseWasDown(true);

    let sc = getScaleCursor(xy, selectedIds, multiSelectBox);
    dragging = null;
    if (sc) {
      if (sc == Cursor.ResizeN || sc == Cursor.ResizeS) {
        dragging = "scaleY";
      } else if (sc == Cursor.ResizeE || sc == Cursor.ResizeW) {
        dragging = "scaleX";
      } else if (sc == Cursor.ResizeNE || sc == Cursor.ResizeSW || sc == Cursor.ResizeNW || sc == Cursor.ResizeSE) {
        dragging = "scale";
      }
      cursor = sc;
      setCursor(sc);
    } else if (getRotateCursor(xy, items, selectedIds, multiSelectBox)) {
      dragging = "rotate";
    } else if (selectedIds.length == 0 || Array.from(target?.classList).includes("Board")) {
      dragging = "moveBoard";
    } else {
      dragging = "translate";
    }
    setDragging(dragging);

    mouseDownPoint = xy;
    setMouseDownPoint(xy);

    mouseMovePoint = xy;
    setMouseMovePoint(xy);
  };

  const onMouseMoveFree = ({ event, xy, target, z }) => {
    // this is mousemove without any clicking
    let itemId;
    while (target) {
      itemId = target.getAttribute("id");
      if (itemId) break;
      target = target.parentElement;
    }
    if (itemId == z.outline?.id) return;

    let box = getBoundingBoxOfDomElement(svgRef, target);
    z.outline = {
      d: `M${box.x} ${box.y} h${box.width} v${box.height} h${-box.width} Z`,
      stroke: "blue",
      id: target.getAttribute("id"),
      pid: target.getAttribute("pid"),
    };
    setOutline(z.outline);
  };

  const onMouseMoveNew = ({ event, xy, target, items, groups, defs, selectedIds, cursor, multiSelectBox, z }) => {
    if (!mouseWasDown) {
      onMouseMoveFree({ event, xy, target, z });
      return;
    }
    if (dragging) {
      if (dragging == "moveBoard") {
        const mult = 1;
        const nx = z.viewBox.x - mult * (xy.x - mouseMovePoint.x);
        const ny = z.viewBox.y - mult * (xy.y - mouseMovePoint.y);
        z.viewBox.x = nx;
        z.viewBox.y = ny;
        setViewBox(z.viewBox);
        event.preventDefault();
        event.stopPropagation();
      } else {
        snapItem({ xy, items });

        let transformation,
          minX = Infinity,
          minY = Infinity,
          maxX = -Infinity,
          maxY = -Infinity;
        let rotateCenter = { x: 0, y: 0 };
        for (let i of selectedIds) {
          const it = items[i];

          rotateCenter.x += it.center?.x;
          rotateCenter.y += it.center?.y;
          const box = it.box;
          if (!box) continue;
          minX = Math.min(minX, box.x);
          minY = Math.min(minY, box.y);
          maxX = Math.max(maxX, box.x + box.width);
          maxY = Math.max(maxY, box.y + box.height);
        }
        rotateCenter.x /= selectedIds.length;
        rotateCenter.y /= selectedIds.length;
        let box = { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
        if (minX > 1000000 || minY > 1000000 || maxX < -1000000 || maxY < -1000000) {
          box = null;
        }

        for (let i = 0; i < selectedIds.length; i++) {
          let selectedId = selectedIds[i];
          let item = items[selectedId];
          if (item.locked) continue;

          if (dragging.startsWith("scale")) {
            let signX = 1,
              signY = 1;
            if (cursor == Cursor.ResizeN || cursor == Cursor.ResizeNW || cursor == Cursor.ResizeNE) {
              signY = -1;
            }
            if (cursor == Cursor.ResizeW || cursor == Cursor.ResizeNW || cursor == Cursor.ResizeSW) {
              signX = -1;
            }
            let center = getUnmovingPoint(box, cursor);
            if (!center) {
              console.log("cannot transform");
              continue;
            }

            let xDiff = 1 + (signX * (xy.x - mouseMovePoint.x)) / 200;
            let yDiff = 1 + (signY * (xy.y - mouseMovePoint.y)) / 200;
            if (dragging == "scaleX") yDiff = 1;
            if (dragging == "scaleY") xDiff = 1;
            if (event.shiftKey) xDiff = yDiff = Math.max(xDiff, yDiff);
            transformation = { type: "scale", x: xDiff, y: yDiff, center };
          } else if (dragging == "rotate") {
            // GOTCHA & TODO: if this is a text path, then that path has to be translated/rotated
            // if (item.pathId) {
            //   item = defs.find((d) => d.id == item.pathId);
            // }

            let angle1 = Math.atan2(mouseMovePoint.y - rotateCenter.y, mouseMovePoint.x - rotateCenter.x);
            let angle2 = Math.atan2(xy.y - rotateCenter.y, xy.x - rotateCenter.x);
            let angle = (angle2 - angle1) / 2; // divide by something to rotate slowly

            if (event.shiftKey) {
              angle = toDegree(angle);
              angle = (angle + 405) % 360;
              angle = Math.round(angle / 45) * 45;
              angle = toRadians(angle);
            }

            transformation = { type: "rotate", center: rotateCenter, angle };
          } else if (dragging == "translate") {
            // GOTCHA & TODO: if this is a text path, then that path has to be translated/rotated
            // if (item.pathId) {
            //   item = defs.find((d) => d.id == item.pathId);
            // }
            transformation = {
              type: "translate",
              x: xy.x - mouseMovePoint.x,
              y: xy.y - mouseMovePoint.y,
            };
          }

          item = transform(item, transformation);
        }
      }

      setItems({ ...items });
      setOutline(null);

      multiSelectBox = null;
      setMultiSelectBox(null);

      mouseMovePoint = xy;
      setMouseMovePoint(xy);
      return;
    }

    // here we set cursors
    if (isBottomRightOfSomeBoundingBox(xy, multiSelectBox)) {
      cursor = Cursor.scaleSE;
      setCursor(Cursor.scaleSE);
      return;
    }
    const rotateCursor = getRotateCursor(xy, items, selectedIds, multiSelectBox);
    if (rotateCursor) {
      cursor = rotateCursor;
      setCursor(rotateCursor);
    } else if (cursor != Cursor.Default) {
      cursor = Cursor.Default;
      setCursor(Cursor.Default);
    }
  };
  const onMouseUpNew = ({ event, xy, target, items, groups, selectedIds, cursor, multiSelectBox, z }) => {
    if (z.outline) {
      // set selection
      setSelectedIds([z.outline.id]);

      // clear replacing icon (turned on when an svg is clicked and "replace" button is clicked)
      setReplacingItemId(null);

      setMouseWasDown(false);
      return;
    }
    if (mouseWasDown && Array.from(target?.classList).includes("Board")) {
      setSelectedIds([]);
      setMouseWasDown(false);
      return;
    }
    if (dragging == "scale" || dragging == "rotate" || dragging == "translate") {
      items = updateBoxes(svgRef, items, selectedIds);
      setItems({ ...items });
    }
    dragging = null;
    setDragging(dragging);

    setMouseWasDown(false);
  };
  return {
    onMouseMoveNew,
    onMouseDownNew,
    onMouseUpNew,
    onToolUnselect,
    isPointer: true,
    name: "pointer",
  };
}

export default usePointer;
