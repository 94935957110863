// @ts-nocheck
import { Box, IconButton } from "@mui/material";
import { useSVG } from "../SVGContext";
import { MpImageView } from "./MpImageView";
import { ShapeView } from "./ShapeView";
import { TextView } from "./TextView";
import { RotateBtn } from "./RotateBtn";

const SVGItemView = ({ item, overlay }) => {
  if (!item) return null;
  const { croppingItemId, selectedIds } = useSVG();

  if (overlay) {
    if (!selectedIds.includes(item.id)) return;
    if (croppingItemId && item.type !== "mpimage") return;
  }

  if (!item.mpTransformStr) return <SVGItemViewWithoutGroup item={item} overlay={overlay} />;

  return (
    <g transform={item.mpTransformStr}>
      <SVGItemViewWithoutGroup item={item} overlay={overlay} />
      <Commons item={item} overlay={overlay} />
    </g>
  );
};

const Commons = ({ item, overlay }) => {
  if (!overlay) return null;
  return <RotateBtn item={item} />;
};

const SVGItemViewWithoutGroup = ({ item, overlay }) => {
  const { croppingItemId } = useSVG();

  if (!item || item.hidden) return null;
  if (overlay && croppingItemId && item.type !== "mpimage") return;

  const transforms = gatherTransforms(item);
  if (transforms) {
    // <svg> doesn't rotate itself when I pass this as a transform to it, so I'm wrapping it & every other type of item in a <g> tag
    return (
      <g transform={transforms}>
        <SVGItemViewWithoutGroup item={{ ...item, ignoreTransforms: true }} />
      </g>
    );
  }

  // can delete "background"
  if (!overlay) {
    if (["background", "rectangle", "circle", "ellipse", "triangle", "star", "pentagon", "hexagon", "semi-circle", "quarter-circle", "straight"].includes(item.type)) return <ShapeView item={item} overlay={overlay} />;
    if (item.type == "path") return <path {...item} overlay={overlay} />;
    if (item.type == "icon") return <svg id={item.id} viewBox={item.viewbox} x={item.x} y={item.y} width={item.width} height={item.height} fill={item.fill} stroke={item.stroke} strokeWidth={item.strokeWidth} dangerouslySetInnerHTML={{ __html: item.svg }} overlay={overlay} />;
    if (item.type == "style") return <style>{item.content}</style>;
  }
  if (item.type == "mpimage") return <MpImageView item={item} overlay={overlay} />;
  if (item.proseMirrorData) return <TextView item={item} item={item} overlay={overlay} />;
};

export default SVGItemView;

export function gatherTransforms(item) {
  if (item.ignoreTransforms) return null;
  const transforms = [];
  if (item.rotateDeg) transforms.push(`rotate(${item.rotateDeg} ${item.x + item.width / 2} ${item.y + item.height / 2})`);

  return transforms.join(" ");
}
