import IconButton from "@mui/material/IconButton";

const MyIconButton = ({ isPressed, children, ...others }) => {
  return (
    <IconButton
      name="button"
      {...others}
      sx={{
        backgroundColor: isPressed ? "rgba(0, 0, 0, 0.08)" : "transparent",
        borderRadius: 0,
      }}
    >
      {children}
    </IconButton>
  );
};

export default MyIconButton;
