import { DEFTYPES } from "./enums";

// @ts-nocheck
let debug = false;

export const ROOT_GROUP_ID = "group-root";

export const COPY_PROPS = ["fill", "gradient", "stroke", "strokeWidth", "strokeOpacity", "strokeDasharray", "strokeLinecap", "strokeLinejoin", "strokeMiterlimit", "opacity"];

export const constants = {
  debug: debug,
  shapeBuilder: debug
    ? { dRad: 0.05, d: 0.1, animationInterval: 700, bisectorLineDist: 1000 }
    : {
        dRad: 0.001,
        d: 0.1,
        animationInterval: 700,
        bisectorLineDist: 1000,
      },
  pathFinder: { extraPointsDist: 5 },

  SAMPLE_TEXT: "Text",
};

export const RIGHT_PANEL_WIDTH_COLLAPSED = 50;
export const RIGHT_PANEL_WIDTH_EXPANDED = 220;

export const animatableProps = [
  {
    name: "Opacity",
    action: "opacity",
    defaultValue: 1,
    type: "number",
  },
  {
    name: "X",
    action: "x",
    defaultValue: 0,
    type: "number",
  },
  {
    name: "Y",
    action: "y",
    defaultValue: 0,
    type: "number",
  },
  {
    name: "Rotate",
    action: "rotate",
    type: "number",
  },
  {
    name: "Move Along Path",
    action: "moveAlongPath",
    type: "percentage",
  },
];

export const SITEROOT = "https://designkart.in";

export const ARROW_MARKER_DEFS = [
  {
    type: DEFTYPES.MARKER,
    id: "start-arrow",
    orient: "auto-start-reverse",
    viewBox: "0 0 10 10",
    refX: 10,
    refY: 5,
    paths: [
      {
        d: "M 3 0 L 10 5 L 3 10",
        strokeLinejoin: "miter",
        fill: "rgb(255, 255, 255)",
      },
    ],
  },
  {
    type: DEFTYPES.MARKER,
    id: "end-arrow",
    orient: "auto",
    viewBox: "0 0 10 10",
    refX: 10,
    refY: 5,
    paths: [
      {
        d: "M 0 0 L 10 5 L 0 10",
        strokeLinejoin: "miter",
        fill: "rgb(255, 255, 255)",
      },
    ],
  },
];

export const FRAME_DEFS = [
  {
    id: "frame_square",
    type: DEFTYPES.CLIPPATH,
    clipPathUnits: "objectBoundingBox",
    innerHTML: `<rect x="0" y="0" width="1" height="1" />`,
  },
  {
    id: "frame_circle",
    type: DEFTYPES.CLIPPATH,

    clipPathUnits: "objectBoundingBox",
    innerHTML: `<circle cx="0.5" cy="0.5" r="0.5" />`,
  },
  {
    id: "frame_ellipse",
    type: DEFTYPES.CLIPPATH,

    clipPathUnits: "objectBoundingBox",
    innerHTML: `<ellipse cx="0.5" cy="0.5" rx="0.5" ry="0.5" />`,
  },
  {
    id: "frame_triangle",
    type: DEFTYPES.CLIPPATH,

    clipPathUnits: "objectBoundingBox",
    innerHTML: `<polygon points="0.5 0, 0 1, 1 1" />`,
  },
  {
    id: "frame_diamond",
    type: DEFTYPES.CLIPPATH,

    clipPathUnits: "objectBoundingBox",
    innerHTML: `<polygon points="0.5 0, 1 0.5, 0.5 1, 0 0.5" />`,
  },
  {
    id: "frame_star",
    type: DEFTYPES.CLIPPATH,

    clipPathUnits: "objectBoundingBox",
    innerHTML: `<polygon points="0.5 0, 0.63 0.38, 1 0.38, 0.69 0.59, 0.82 1, 0.5 0.75, 0.18 1, 0.31 0.59, 0 0.38, 0.37 0.38"></polygon>`,
  },
  {
    id: "frame_pointedcircle",
    type: DEFTYPES.CLIPPATH,

    clipPathUnits: "objectBoundingBox",
    innerHTML: `<polygon points="1, 0.51, 0.87, 0.57, 0.97, 0.68, 0.82, 0.7, 0.88, 0.83, 0.74, 0.8, 0.75, 0.95, 0.63, 0.86, 0.59, 1, 0.5, 0.89, 0.41, 1, 0.37, 0.86, 0.25, 0.95, 0.26, 0.8, 0.12, 0.83, 0.18, 0.7, 0.03, 0.68, 0.13, 0.57, 0, 0.51, 0.13, 0.44, 0.03, 0.33, 0.18, 0.32, 0.12, 0.18, 0.26, 0.22, 0.25, 0.07, 0.37, 0.15, 0.41, 0.01, 0.5, 0.13, 0.59, 0.01, 0.63, 0.15, 0.75, 0.07, 0.74, 0.22, 0.88, 0.18, 0.82, 0.32, 0.97, 0.33, 0.87, 0.44"></polygon>`,
  },
];
