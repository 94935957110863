import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import TopbarText from "./topbar/TopbarText";
import { TopbarShape } from "./topbar/TopbarShape";
import { TopbarMain } from "./topbar/TopbarMain";
import { TopbarIcon } from "./topbar/TopbarIcon";
import { TopbarImage } from "./topbar/TopbarImage";

export function Topbar({ selectedIds }) {
  const [item, setItem] = useState(null);
  const { items } = useSVG();

  useEffect(() => {
    if (selectedIds.length === 1) {
      setItem(items[selectedIds[0]]);
    } else {
      setItem(null);
    }
  }, [selectedIds]);

  if (item?.proseMirrorData) {
    return <TopbarText item={item} />;
  } else if (["rectangle", "ellipse", "circle", "straight", "polyline", "polygon", "path"].includes(item?.type)) {
    return <TopbarShape item={item} />;
  } else if (item?.type == "icon") {
    return <TopbarIcon item={item} />;
  } else if (item?.type == "mpimage") {
    return <TopbarImage item={item} />;
  } else {
    return <TopbarMain />;
  }
}
