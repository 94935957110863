// @ts-nocheck
import { Box, Button, ClickAwayListener, createSvgIcon, IconButton, Menu, MenuItem, Popper, Slider, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { transform } from "../utils/transformUtils";
import { toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import { findAncestralTransform } from "../utils/PointerUtils";
import CropRoundedIcon from "@mui/icons-material/CropRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { RotateBtn } from "./RotateBtn";
import { TopbarCommons } from "./TopbarCommons";
import MyButton from "./MyButton";

export const TopbarImage = ({ item }) => {
  return (
    <>
      <Replace item={item} />
      <Frame item={item} />
      <Crop item={item} />
      <Adjust item={item} />
      <Resize item={item} />

      <TopbarCommons item={item} />
    </>
  );
};

function Replace({ item }) {
  const { replacingItemId, setReplacingItemId, clearTopbarSelectedButtons, setLeftPanel } = useSVG();
  return (
    <MyButton
      size="small"
      variant="outlined"
      isPressed={replacingItemId}
      onClick={() => {
        clearTopbarSelectedButtons();
        setReplacingItemId(item.id);
        setLeftPanel("images");
      }}
    >
      Replace
    </MyButton>
  );
}
function Crop({ item }) {
  const { setCroppingItemId, clearTopbarSelectedButtons } = useSVG();

  return (
    <IconButton
      size="small"
      variant="outlined"
      onClick={() => {
        clearTopbarSelectedButtons();
        setCroppingItemId(item.id);
      }}
    >
      <CropRoundedIcon />
    </IconButton>
  );
}

function Adjust({ item }) {
  const { setRightPanel, clearTopbarSelectedButtons } = useSVG();
  return (
    <IconButton
      size="small"
      variant="outlined"
      onClick={() => {
        clearTopbarSelectedButtons();
        setRightPanel("imageAdjust");
      }}
    >
      <AutoAwesomeRoundedIcon />
    </IconButton>
  );
}
function Frame({ item }) {
  const { setShowImageFramePanel, clearTopbarSelectedButtons, items, setItems } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);

  function setFrame(frame) {
    items[item.id].frame = frame;
    setItems({ ...items });
  }
  return (
    <>
      <IconButton
        size="small"
        variant="outlined"
        onClick={(e) => {
          clearTopbarSelectedButtons();
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <Circle />
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{
          width: 180,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",

            backgroundColor: "white",
            border: "solid 1px #ccc",
            borderRadius: 5,
            boxShadow: "0px 5px 5px #929292",
            marginTop: 5,
          }}
        >
          {frames.map((frame, i) => (
            <IconButton
              key={i}
              onClick={() => {
                setFrame(frame.name);
                setAnchorEl(null);
              }}
              style={{
                background: frame.name == item.frame ? "#ccc" : "",
              }}
            >
              {frame.icon}
            </IconButton>
          ))}
        </Box>
      </Popper>
    </>
  );
}

const stroke = "#333",
  strokeWidth = 1;

const Circle = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <circle cx="12" cy="12" r="10" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  </svg>,
  "Circle",
);
const Rect = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <rect x="4" y="6" width="18" height="12" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Rect",
);
const Square = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <rect x="4" y="4" width="16" height="16" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Square",
);
const Ellipse = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <ellipse cx="12" cy="12" rx="7" ry="10" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  </svg>,
  "Ellipse",
);
const Triangle = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <polygon points="12,2 2,22 22,22" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Triangle",
);
const Star = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <path d="M12 2 l 2 6 l 6 0 l -5 3 l 2 6 l -6 -4 l -6 4 l 2 -6 l -5 -3 l 6 0 Z" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  </svg>,
  "Star",
);

const Diamond = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <polygon points="12,2 22,12 12,22 2,12" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Diamond",
);

const Hexagon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <polygon points="12,2 22,7 22,17 12,22 2,17 2,7" fill="transparent" stroke={stroke} strokeWidth={strokeWidth} rx={2} />
    </g>
  </svg>,
  "Hexagon",
);

const frames = [
  {
    name: "Rectangle",
    icon: <Rect />,
  },
  {
    name: "Square",
    icon: <Square />,
  },
  {
    name: "Circle",
    icon: <Circle />,
  },
  {
    name: "Ellipse",
    icon: <Ellipse />,
  },
  {
    name: "Diamond",
    icon: <Diamond />,
  },
  {
    name: "Triangle",
    icon: <Triangle />,
  },
  {
    name: "Star",
    icon: <Star />,
  },
  {
    name: "Hexagon",
    icon: <Hexagon />,
  },
];

function Resize({ item }) {
  const { clearTopbarSelectedButtons, items, setItems } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        size="small"
        variant="outlined"
        onClick={(e) => {
          clearTopbarSelectedButtons();
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <AspectRatioIcon />
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{
          width: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",

            backgroundColor: "white",
            border: "solid 1px #ccc",
            borderRadius: 5,
            boxShadow: "0px 5px 5px #929292",
            marginTop: 5,
            padding: 20,
          }}
        >
          <TextField
            type="number"
            label="Width"
            value={item.widthPx}
            onChange={(e) => {
              // find aspect ratio
              const aspectRatio = item.widthPx / item.heightPx;

              item.widthPx = e.target.value;
              item.heightPx = item.widthPx / aspectRatio;
              items[item.id] = item;
              setItems({ ...items });
            }}
            style={{
              width: 120,
            }}
          />
          <TextField
            type="number"
            label="Height"
            value={item.heightPx}
            onChange={(e) => {
              // find aspect ratio
              const aspectRatio = item.widthPx / item.heightPx;
              item.heightPx = e.target.value;
              item.widthPx = item.heightPx * aspectRatio;
              items[item.id] = item;
              setItems({ ...items });
            }}
            style={{
              width: 120,
            }}
          />
        </div>
      </Popper>
    </>
  );
}
