import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import StackedTemplateCategories from "./StackedTemplateCategories";
import SingleTemplate from "./SingleTemplate";
import { MainNavBar } from "./MainNavBar";
import { BottomBar } from "./BottomBar";
import { Designs } from "./Designs";
import { EditorNavBar } from "./EditorNavBar";
import Editor from "./Editor";
import { useSVG } from "./SVGContext";
import { Terms } from "./terms/terms";
import { Privacy } from "./terms/privacy";
import { Contact } from "./terms/contact";
import { Banner } from "./Banner";

export function MyRoutes() {
  const { userInfo } = useSVG();
  return (
    <Router basename="/">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Login />} />

        <Route path="/" element={<StackedTemplateCategories />} />
        <Route path="/templates/:cat" element={<SingleTemplate />} />

        <Route
          path="/designs"
          element={
            <>
              <MainNavBar />
              <Designs />
              <BottomBar />
            </>
          }
        />
        <Route
          path="/design/:design_id_from_url"
          element={
            <>
              <EditorNavBar />
              <Editor />
            </>
          }
        />

        {["terms", "privacy", "contact"].map((p, i) => (
          <Route
            key={i}
            path={"/" + p}
            element={
              <div>
                <Banner />
                <div style={{ padding: "0 20px 20px 20px", marginBottom: 30 }}>
                  {p === "terms" && <Terms />}
                  {p === "privacy" && <Privacy />}
                  {p === "contact" && <Contact />}
                </div>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                  }}
                >
                  <BottomBar />
                </div>
              </div>
            }
          />
        ))}
      </Routes>
    </Router>
  );
}
