import { Box, IconButton, Popper, Slider, TextField } from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { useState } from "react";
import { useSVG } from "../SVGContext";

export function RoundCorners({ item }) {
  if (item.type != "rectangle") return null;

  const [anchorEl, setAnchorEl] = useState(null);
  const { items, setItems } = useSVG();

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <HighlightAltIcon />
      </IconButton>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            justifyContent: "start",
            alignItems: "center",

            backgroundColor: "white",
            border: "solid 1px #ccc",
            borderRadius: 5,
            boxShadow: "0px 5px 5px #929292",
            marginTop: 5,
            padding: 10,
          }}
        >
          <Slider
            min={0}
            max={100}
            value={item.rx}
            onChange={(e, value) => {
              item.rx = value;
              setItems({ ...items });
            }}
            style={{
              width: 200,
            }}
          />
          <TextField
            value={item.rx}
            type="number"
            size="small"
            style={{
              width: 80,
            }}
            onChange={(e) => {
              item.rx = e.target.value;
              setItems({ ...items });
            }}
          />
          %
        </Box>
      </Popper>
    </>
  );
}
