// @ts-nocheck
import { Box, Button, ClickAwayListener, IconButton, Menu, MenuItem, Popper, Select, Slider, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { transform } from "../utils/transformUtils";
import { getCoordsInSvg, toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import Crop from "@mui/icons-material/Crop";

export const TopbarMain = ({}) => {
  const { pages, selectedPage, setSelectedPage } = useSVG();

  return (
    <>
      <FillColor />
      <Select
        sx={{
          fontSize: "0.75rem",
        }}
        size="small"
        value={selectedPage}
        onChange={(e) => {
          setSelectedPage(e.target.value);
        }}
      >
        {pages.map((page, index) => (
          <MenuItem key={index} value={index}>
            Page {index + 1}
          </MenuItem>
        ))}
      </Select>
      {/* <CropToContent /> */}
    </>
  );
};
function FillColor({}) {
  let { items, setItems } = useSVG();

  return (
    <ColorSquare
      color={items["background"]?.fill || "white"}
      onChange={(color) => {
        items["background"].fill = color;
        setItems({ ...items });
      }}
    />
  );
}

function CropToContent({}) {
  let { items, changeDimensions, svgRef } = useSVG();
  return (
    <IconButton onClick={crop}>
      <Crop />
    </IconButton>
  );

  function crop() {
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    for (let id in items) {
      if (id == "background") continue;

      const it = items[id];
      let box = document.getElementById(id).getBoundingClientRect();
      let p1 = {
          x: box.left,
          y: box.top,
        },
        p2 = {
          x: box.left + box.width,
          y: box.top + box.height,
        };
      p1 = getCoordsInSvg(svgRef, p1);
      p2 = getCoordsInSvg(svgRef, p2);

      minX = Math.min(minX, p1.x);
      minY = Math.min(minY, p1.y);
      maxX = Math.max(maxX, p2.x);
      maxY = Math.max(maxY, p2.y);
      console.log(id, box);
    }
    let width = maxX - minX;
    let height = maxY - minY;
    let x = minX;
    let y = minY;
    const viewbox = { x, y, width, height };
    changeDimensions({ viewbox, width, height });
    // change background
  }
}
