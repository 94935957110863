import React, { useState, useEffect, useRef, useCallback } from "react";
import { ImageWithSkeleton } from "./ImageWithSkeleton";

const LazyImage = ({ src, alt, style, onClick, svg, price, containerStyle }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef();

  const handleIntersection = useCallback((entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }
    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [handleIntersection]);

  return (
    <div
      ref={imageRef}
      style={
        containerStyle || {
          width: "100%",
          height: "fit-content",
          padding: 5,
        }
      }
      className="lazy-image"
    >
      {isVisible && <ImageWithSkeleton src={src} alt={alt} onClick={onClick} />}
      {isVisible && svg && <div dangerouslySetInnerHTML={{ __html: svg }} alt={alt} style={style} onClick={onClick} />}

      {price && (
        <span className="price" style={{}}>
          {price}
        </span>
      )}
    </div>
  );
};

export default LazyImage;
