// @ts-nocheck

import { useEffect, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import { useSVG } from "../SVGContext";
import ShapesMap from "../values/ShapesMap";
import { rand } from "../utils";
import { ROOT_GROUP_ID } from "../values/constants";
import SVGItemView from "../views/SVGItemView";

// NOTE: d values are parsed each time shapes are loaded
export function ShapesPanel({ close }) {
  const style = { padding: "10px", width: "100%", overflowY: "scroll" };
  const svgStyle = { margin: "10px", width: "60px" };
  const [fill, setFill] = useState("white");
  const [stroke, setStroke] = useState("black");
  const { addShape } = useSVG();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorSquare
          color={fill}
          onChange={(color) => {
            setFill(color.hex);
          }}
        />
        <ColorSquare
          color={stroke}
          onChange={(color) => {
            setStroke(color.hex);
          }}
        />
      </div>
      <div style={style}>
        {ShapesMap.map((s, index) => {
          return (
            <svg
              key={index}
              style={svgStyle}
              viewBox="-10 -10 100 100"
              onClick={() => {
                if (s.length) { 
                  s = {
                    d: s.join(" ")
                  }
                }
                
                addShape(s, fill, stroke);
              }}
            >
              <RenderedShape shape={s} fill={fill} stroke={stroke} />
            </svg>
          );
        })}
      </div>
    </>
  );
}

function RenderedShape({shape, fill, stroke}) {
  const [d, setD] = useState('');
  useEffect(() => {
    if (shape.length) {
      setD(shape.join(' '));
    }
  }, [shape]);

  if (d) {
    return <path d={d} fill={fill} stroke={stroke} strokeWidth="2" />
  } else {
    return <SVGItemView item={{
      ...shape,
      fillColor: [fill] ,
      border: {
        color: stroke,
        width: 2
      }
    }} />
  }
}