// @ts-nocheck
import { COPY_PROPS, constants } from "../values/constants";
import { transform } from "./transformUtils";

export const writeToClipboard = (json) => {
  navigator.clipboard.writeText(json).catch((error) => {
    console.error("Failed to write to clipboard:", error);
  });
};

export const readFromClipboard = () => {
  return navigator.clipboard.readText().catch((error) => {
    console.error("Failed to read from clipboard:", error);
  });
};

// cut
export function cut({ items, addItems, selectedIds, deleteItems }) {
  writeToClipboard(JSON.stringify(selectedIds.map((i) => items[i])));

  deleteItems(selectedIds);
  return true;
}

// copy
export async function copy({ asFormat, items, svgRef, width, height, selectedIds, fileManager }) {
  if (asFormat == "json") {
    writeToClipboard(JSON.stringify(selectedIds.map((i) => items[i])));
  } else if (asFormat == "svg") {
    let copiedItems = await fileManager.getSVG({ svgRef });
    writeToClipboard(copiedItems);
  } else if (asFormat == "png") {
    fileManager.getPNG({ svgRef, width, height }, (pngBlob) => {
      const data = [new ClipboardItem({ "image/png": pngBlob })];
      return navigator.clipboard.write(data);
    });
  }
  return true;
}

// paste
export function paste({ addItems }) {
  readFromClipboard()
    .then((json) => {
      // TODO
    })
    .catch((e) => {
      console.error("Could not paste", e);
    });
  return true;
}
