// @ts-nocheck

import { ROOT_GROUP_ID } from "../values/constants";

function toSelectedObjects(selectedIds, items, groups) {
  let selectedGroupOrItems = [];
  for (let i of selectedIds) {
    if (items[i].group == ROOT_GROUP_ID) {
      // this means this item belongs to mainGroup
      selectedGroupOrItems.push(items[i]);
    } else {
      // this means this item belongs to a group other than mainGroup
      selectedGroupOrItems.push(groups[items[i].group]);
    }
  }
  return Array.from(new Set(selectedGroupOrItems));
}

export const arrange = ({ type, selectedIds, items, groups, setGroups, setSelectedIds }) => {
  const selectedObject = toSelectedObjects(selectedIds, items, groups)[0]; // item or group
  if (!selectedObject) return;

  const group = groups[selectedObject.group];
  const idx = group.children.indexOf(selectedObject.id);
  group.children = group.children.filter((id) => id != selectedObject.id);

  if (type == "bringtofront") {
    // all the way to the front of this group
    group.children.push(selectedObject.id);
  }
  if (type == "bringforward") {
    // one step forward
    group.children.splice(idx + 1, 0, selectedObject.id);
  }
  if (type == "sendbackward") {
    // one step backward
    group.children.splice(idx - 1, 0, selectedObject.id);
  }
  if (type == "sendtoback") {
    // all the way to the back
    group.children.unshift(selectedObject.id);
  }
  setGroups({ ...groups });
};
