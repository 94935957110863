import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, IconButton } from "@mui/material";
import { useSVG } from "./SVGContext";

export function AlignPanel({}) {
  const { arrange } = useSVG();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch",
        gap: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          gap: 10,
        }}
      >
        <Button
          style={{
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
          onClick={() => arrange("bringforward")}
        >
          <KeyboardArrowUpIcon />
          Forward
        </Button>
        <Button
          style={{
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
          onClick={() => arrange("bringtofront")}
        >
          <KeyboardArrowUpIcon />
          Fully Front
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          gap: 10,
        }}
      >
        <Button
          style={{
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
          onClick={() => arrange("sendbackward")}
        >
          <KeyboardArrowDownIcon />
          Backward
        </Button>
        <Button
          style={{
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
          onClick={() => arrange("sendtoback")}
        >
          <KeyboardArrowDownIcon />
          Fully Back
        </Button>
      </div>
    </div>
  );
}
