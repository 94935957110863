import { AddTemplateBtn } from "../AddtemplateBtn";
import { getTemplatePreviewUrl } from "../utils/utils";
import { ImageWithSkeleton } from "./ImageWithSkeleton";

export function LazyImageWithAddBtn({ src, alt, onClick, svg, price, addString, height, width }) {
  if (height) {
    width = "fit-content";
  } else {
    height = "fit-content";
  }
  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        position: "relative",
        padding: 10,
      }}
      className="add-btn-wrapper price-wrapper"
      onClick={() => {
        onClick();
      }}
    >
      {!svg && <ImageWithSkeleton src={src} alt={alt} height={height} width={width} />}
      {svg && <div dangerouslySetInnerHTML={{ __html: svg }} alt={alt} />}

      {price && <span className="price">{price}</span>}

      <AddTemplateBtn addString={addString} />
    </div>
  );
}
