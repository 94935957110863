// @ts-nocheck

// import { angleAtB } from "./ShapeBuilderUtils";
import { D, pointAt, toDegree, toRadians } from "./utils";

const THRESHOLD = 15;
const lineSize = 100;
function getSnapPoints(item) {
  return [item.center, ...item.points];
}
function angleAtB() {
  // todo
}
export const snapAngle = ({ xy, pxy, center }) => {
  let angle = angleAtB(center, pxy, xy);
  // is it clockwise or counter clockwise?
  const clockwise = angle > 0;
  const radians45 = toRadians(45);
  angle = Math.round(angle / radians45) * radians45;
  if (clockwise) {
    angle += radians45;
  }
  const handleSize = D(center, pxy);
  //find point
  const p = pointAt(center, 50, angle);
  xy.x = p.x;
  xy.y = p.y;
};
export const snapMouse = ({ xy, selectedIds, items, snapLines, setSnapLines }) => {
  snapLines = [];
  setSnapLines([]);
  for (let id in items) {
    if (selectedIds.includes(id)) continue;
    const item = items[id];
    const snapPoints = getSnapPoints(item);
    for (let p of snapPoints) {
      if (Math.abs(xy.x - p.x) < THRESHOLD) {
        snapLines.push({ p1: p, p2: { x: p.x, y: xy.y } });
        xy.x = p.x;
        setSnapLines(snapLines);
        setTimeout(() => {
          setSnapLines([]);
        }, 3000);
        return;
      }
      if (Math.abs(xy.y - p.y) < THRESHOLD) {
        snapLines.push({ p1: p, p2: { x: xy.x, y: p.y } });
        xy.y = p.y;
        setSnapLines(snapLines);
        setTimeout(() => {
          setSnapLines([]);
        }, 3000);
        return;
      }
    }
  }
};
export const snapItem = ({ xy, selectedIds = [], items, snapLines, setSnapLines }) => {
  return;

  // not yet working
  let itemPoints = [xy];
  for (let id of selectedIds) {
    itemPoints = itemPoints.concat([items[id].center]); //, ...items[id].points]);
  }

  snapLines = [];
  setSnapLines([]);
  let dx = 0,
    dy = 0;
  for (let id in items) {
    if (selectedIds.includes(id)) continue;

    const item = items[id];
    const snapPoints = getSnapPoints(item);
    for (let p of snapPoints) {
      for (let q of itemPoints) {
        // if (Math.abs(q.x - p.x) < THRESHOLD) {
        //   dx = p.x - q.x;
        //   snapLines.push({ p1: p, p2: { x: p.x, y: q.y } });

        //   xy.x += dx;
        //   setSnapLines(snapLines);
        //   return;
        // }

        if (Math.abs(q.y - p.y) < THRESHOLD) {
          dy = p.y - q.y;
          snapLines.push({ p1: p, p2: { x: q.x, y: p.y } });

          // q.y needs to move by dy
          // how much should xy move?
          // dy = p.y - q.y
          // xy.y + dy = p.y
          // xy.y = p.y - dy
          xy.y += dy;

          setSnapLines(snapLines);
          return;
        }
      }
    }
  }
};
