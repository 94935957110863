import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete, Menu, ListItem, List, Button, Select, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { TEMPLATE_CATEGORIES } from "./values/TemplateCategories";
import { useParams } from "react-router-dom";
import { SUBCATEGORIES } from "./values/Subcategories";
import { cap } from "./utils/utils";

const SingleTemplate = memo(() => {
  const { cat } = useParams();

  let [category, setCategory] = useState(cat);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  function handleSearch() {}
  function handleKeyPress() {}
  return (
    <div
      style={{
        padding: 15,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "stretch",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          fullWidth
          inputValue={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onInputChange={(event, newValue) => {
            if (TEMPLATE_CATEGORIES.includes(newValue)) {
              category = newValue;
              setCategory(category);
              setQuery(category);
              setCurrentPage(1);

              handleSearch();
            } else {
              setCategory(null);
              setQuery(newValue);
            }
          }}
          onKeyDown={handleKeyPress}
          placeholder="Search thousands of templates"
        />
      </div>

      <Select value={category} onChange={(e) => setCategory(e.target.value)}>
        {Object.keys(SUBCATEGORIES).map((key) => (
          <option
            value={key}
            style={{
              padding: 10,
            }}
          >
            {cap(key)}
          </option>
        ))}
      </Select>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "stretch",

          height: "100%",
        }}
      >
        {/* {results.templates && <Gallery data={results.templates} onClick={onClick} />} */}
      </div>
    </div>
  );
});
export default SingleTemplate;
