import React, { memo, useEffect, useRef, useState } from "react";
import { TextField, IconButton, Autocomplete, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LazyImage from "./LazyImage";
import { TEMPLATE_CATEGORIES } from "../values/TemplateCategories";
import { fetchTemplates, searchTemplates } from "../utils/ServerUtils";
import { CircularProgress } from "@mui/material";
import { cap, getTemplatePreviewUrl } from "../utils/utils";
import { SUBCATEGORIES } from "../values/Subcategories";
import { AddTemplateBtn } from "../AddtemplateBtn";
import { LazyImageWithAddBtn } from "./LazyImageWithAddBtn";

// find a random category
const randomCat = () => Object.keys(SUBCATEGORIES)[Math.floor(Math.random() * Object.keys(SUBCATEGORIES).length)];

const TemplateSearch = memo(({ onTemplateSelect }) => {
  let [category, setCategory] = useState(randomCat);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [cache, setCache] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const containerRef = useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setQuery(category);
    fetchMoreTemplates();
  }, []);

  const fetchMoreTemplates = async () => {
    if (loading || currentPage > totalPages) return;

    if (category) {
      // check in cache
      if (cache[category] && cache[category].length >= currentPage * 10) {
        return;
      }

      setLoading(true);
      const res = await fetchTemplates(category, currentPage);
      setCurrentPage((prev) => prev + 1);
      setTotalPages(res.totalPages);

      let prev = cache[category] || [];
      prev = [...prev, ...res.templates];
      setCache((prevCache) => ({
        ...prevCache,
        [category]: prev,
      }));
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container.scrollHeight - container.scrollTop <= container.clientHeight * 1.1) {
      setCurrentPage((prev) => prev + 1);
      fetchMoreTemplates();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [currentPage, totalPages]);

  const handleSearch = async () => {
    if (category) {
      fetchMoreTemplates();
      setResults([]);
    } else if (query) {
      searchTemplates(query).then((results) => {
        setResults(results.templates);
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  let toShow = results.length > 0 ? results : cache[category] ? cache[category] : [];
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", padding: "0 20px" }}>
        <Autocomplete
          fullWidth
          freeSolo
          options={TEMPLATE_CATEGORIES}
          inputValue={query}
          onInputChange={(event, newValue) => {
            if (TEMPLATE_CATEGORIES.includes(newValue)) {
              category = newValue;
              setCategory(category);
              setQuery(category);
              setCurrentPage(1);

              handleSearch();
            } else {
              setCategory(null);
              setQuery(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth variant="outlined" onKeyPress={handleKeyPress} placeholder="Search Templates" />}
        />

        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </div>

      <div ref={containerRef} style={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", overflowY: "scroll" }}>
        {toShow.map((result, j) => (
          <LazyImageWithAddBtn key={j} src={getTemplatePreviewUrl(result.id)} onClick={() => onTemplateSelect(result.id)} alt={result.title} width={"100%"} price={"₹20"} addString="Add to Design" />
        ))}
        {loading && (
          <div style={{ textAlign: "left", padding: "10px" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
});
export default TemplateSearch;
