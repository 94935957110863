import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TEMPLATE_CATEGORIES } from "./values/TemplateCategories";

const SlidingNav = ({ children }) => {
  const navRef = React.useRef(null);

  const scrollLeft = () => {
    navRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    navRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        width: "100%",
      }}
    >
      {/* <IconButton onClick={scrollLeft}>
        <ArrowBackIosIcon />
      </IconButton> */}
      <Box
        ref={navRef}
        display="flex"
        overflow="auto"
        whiteSpace="nowrap"
        sx={{
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        style={{
          width: "100%",
        }}
      >
        {children}
      </Box>
      {/* <IconButton onClick={scrollRight}>
        <ArrowForwardIosIcon />
      </IconButton> */}
    </Box>
  );
};

export default SlidingNav;
