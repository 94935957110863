// @ts-nocheck

import { BezierItem } from "../interfaces/BezierItem";
import { toDegree, toRadians } from "../utils/utils";
import { DEFTYPES } from "../values/enums";

// function calculateGradientTransform(line, initialLine) {
//   const { p1, p2 } = line;

//   const dx = p2.x - p1.x;
//   const dy = p2.y - p1.y;

//   const length = Math.sqrt(dx * dx + dy * dy);
//   const origLength = Math.sqrt((initialLine.p2.x - initialLine.p1.x) ** 2 + (initialLine.p2.y - initialLine.p1.y) ** 2);
//   const scale = length / origLength;

//   const deg = toDegree(Math.atan2(dy, dx));
//   return `rotate(${deg}) scale(${scale})`;
// }
function Stops({ def }) {
  if (!def) {
    return null;
  }
  if (def.stops) {
    return (
      <>
        {def.stops.map((stop, i) => (
          <stop key={i} offset={stop.offset} stopColor={stop.stopColor} stopOpacity={stop.stopOpacity || 1} />
        ))}
      </>
    );
  } else if (def.colors) {
    return (
      <>
        {def.colors.map((color, i) => (
          <stop key={i} offset={i / (def.colors.length - 1)} stopColor={color} />
        ))}
      </>
    );
  }
}
function LinearGradient({ def }) {
  const props = { ...def };
  delete props.stops;
  props.gradientTransform = props.gradientTransform;
  let x1 = def.x1 || 0;
  let x2 = def.x2 || 0;
  let y1 = def.y1 || 0;
  let y2 = def.y2 || 0;

  if (def.angle) {
    x1 = 0;
    y1 = 1;
    x2 = Math.cos(toRadians(def.angle));
    y2 = Math.sin(toRadians(def.angle));
  }
  return (
    <linearGradient {...props} x1={x1} x2={x2} y1={y1} y2={y2}>
      <Stops def={def} />
    </linearGradient>
  );
}

function RadialGradient({ def }) {
  const props = { ...def };
  delete props.stops;
  props.gradientTransform = props.gradientTransform;
  return (
    <radialGradient {...props}>
      <Stops def={def} />
    </radialGradient>
  );
}

function ImportUrl({ def }) {
  return <style>@import url('{def.url}');</style>;
}
function Path({ def }) {
  return <path {...def} />;
}
function Marker({ def }) {
  const defWithoutChildren = { ...def, children: undefined };
  return (
    <marker def={defWithoutChildren}>
      {def.paths.map((path, i) => (
        <path key={i} {...path} />
      ))}
    </marker>
  );
}

function SVGDef({ def }) {
  if (!def) return null;
  return (
    <>
      {def.type === DEFTYPES.LINEARGRADIENT && <LinearGradient def={def} />}
      {def.type === DEFTYPES.RADIALGRADIENT && <RadialGradient def={def} />}
      {def.type === DEFTYPES.IMPORTURL && <ImportUrl def={def} />}
      {def.type === DEFTYPES.CLIPPATH && <clipPath id={def.id} clipPathUnits={def.clipPathUnits} dangerouslySetInnerHTML={{ __html: def.innerHTML }}></clipPath>}
      {def.type === DEFTYPES.MARKER && <Marker def={def} />}
    </>
  );
}

export default SVGDef;
