const BASE_URL = "https://api.designkart.in";

export function req(path, method = "GET", bodyData, json = true) {
  const opts = {
    method,
    headers: {},
    credentials: "include",
  };
  if (window.location.hostname === "localhost") {
    opts.headers.authorization = "supersecrettoken";
  }
  if (bodyData) {
    opts.body = bodyData;
  }
  if (json) {
    opts.headers["Content-Type"] = "application/json";
  }
  const url = BASE_URL + path;
  return fetch(url, opts)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Server error:", error);
      throw error;
    });
}
export const createDesignFromTemplateServer = (templateId) => {
  return req(
    "/design_from_template",
    "POST",
    JSON.stringify({
      template_id: templateId,
    }),
  );
};
export const createDesignServer = (designData, cb) => {
  console.log("designData", designData);
  req(
    "/designs",
    "POST",
    JSON.stringify({
      design_json: designData,
    }),
  ).then(cb);
};

export const updateDesignServer = (designId, data) => {
  return req(`/designs/${designId}`, "PUT", JSON.stringify(data));
};

export const deleteDesignServer = (designId, cb) => {
  req(`/designs/${designId}`, "DELETE").then(cb);
};

export const fetchDesigns = () => {
  return req(`/designs`);
};

export const fetchDesign = (designId) => {
  return req(`/designs/${designId}`);
};

export const fetchImages = (cb) => {
  req("/images").then(cb);
};

export const uploadImage = (formData) => {
  return req("/upload", "POST", formData, false);
};

export const deleteImage = (imageId, cb) => {
  req(`/image/${imageId}`, "DELETE").then(cb);
};

export const searchPexelsImages = (query) => {
  // clean query
  query = query.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
  return req(`/search_images?query=${query}`);
};

export const searchIcons = (query) => {
  // clean query
  query = query.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
  return req(`/icons?q=${query}`);
};

export const fetchTemplates = (category, page) => {
  return req(`/templates?category=${category}&page=${page}`);
};

export const fetchTemplate = (id) => {
  return req(`/template?id=${id}`);
};

export const searchTemplates = (query) => {
  return req(`/templates_search?q=${query}`);
};

export const verifyPayment = () => {
  // TODO
};

export const fetchUserInfo = () => {
  return req("/user_info");
};
