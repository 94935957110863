// @ts-nocheck

import React, { useState } from "react";
import MenuListComposition from "./MenuListComposition";
import { useSVG } from "./SVGContext";
import { WindowsLikeMenu } from "./WindowsLikeMenu";
import { useRightMenu } from "./values/useRightMenu";
import { flip, getBoundingBox, toRadians } from "./utils/utils";
import { transform } from "./utils/transformUtils";

// @ts-nocheck
const RightClickMenu = ({ x, y, setShowMenu }) => {
  const { fileManager, svgRef, items, width, height, undo, redo, zoomIn, zoomOut, group, ungroup, place, cut, copy, paste, duplicate, arrange, pathfind, selectedIds, setItems, setOutline, multiSelectBox } = useSVG();
  const menus = useRightMenu();

  const onMenuClicked = (arg) => {
    if (["bringtofront", "bringforward", "sendbackward", "sendtoback"].includes(arg)) {
      arrange(arg);
    }
    if (arg === "cut") cut();
    if (arg === "copy") copy();
    if (arg === "paste") paste();
    if (arg == "duplicate") duplicate();
    if (arg === "undo") undo();
    if (arg === "redo") redo();
    if (arg.startsWith("flip") && multiSelectBox) {
      const isHoriz = arg === "fliphorizontal";
      const isVert = arg === "flipvertical";
      setOutline(null);
      for (let i of selectedIds) {
        items[i] = flip(items[i], multiSelectBox.center, isHoriz, isVert);
        items[i].box = getBoundingBox(svgRef, items[i]);
        // TODO: re-set multibox
      }
      setItems({ ...items });
    }
    if (arg.startsWith("rotate") && multiSelectBox) {
      setOutline(null);
      const rad = toRadians(parseInt(arg.replace("rotate", "")));
      for (let i of selectedIds) {
        items[i] = transform(items[i], { type: "rotate", angle: rad, center: multiSelectBox.center });
      }
      setItems({ ...items });
    }

    setShowMenu(false);
  };
  const anchorEl = {
    getBoundingClientRect: () => ({
      top: y,
      left: x,
      right: x,
      bottom: y,
      width: 0,
      height: 0,
    }),
    clientWidth: 0,
    clientHeight: 0,
  };
  return <WindowsLikeRightClickMenu onMenuClicked={onMenuClicked} menus={menus} anchorEl={anchorEl} />;
};

export default RightClickMenu;

function WindowsLikeRightClickMenu({ menus, onMenuClicked, anchorEl }) {
  const [open1, setOpen1] = useState(anchorEl);
  const [anchorEl1, setAnchorEl1] = React.useState(anchorEl);
  const [menuItems1, setMenuItems1] = React.useState(menus);

  const [open2, setOpen2] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [menuItems2, setMenuItems2] = React.useState([]);

  const onMenuClickedInternal = (action) => {
    setAnchorEl1(null);
    setAnchorEl2(null);

    setMenuItems1([]);
    setMenuItems2([]);

    onMenuClicked(action);
  };

  const onMouseOver = (event, item) => {
    if (!item.children) {
      setOpen2(false);
      setAnchorEl2(null);
      setMenuItems2([]);
    } else {
      setOpen2(true);
      setAnchorEl2(event.currentTarget);
      setMenuItems2(item.children);
    }
  };

  return (
    <>
      <MenuListComposition
        items={menuItems1}
        onMenuClicked={onMenuClickedInternal}
        open={open1}
        setOpen={(op) => {
          setOpen1(op);
        }}
        anchorEl={anchorEl1}
        setAnchorEl={setAnchorEl1}
        onMouseOver={onMouseOver}
      />

      <MenuListComposition items={menuItems2} onMenuClicked={onMenuClickedInternal} open={open2} setOpen={setOpen2} anchorEl={anchorEl2} setAnchorEl={setAnchorEl2} place="right-start" />
    </>
  );
}
