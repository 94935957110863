export function Contact() {
  return (
    <div class="container mt-5">
      <h1>Contact Us</h1>

      <h4>Email</h4>
      <p>
        For any inquiries or support, please reach out to us at <a href="mailto:support@movingpencils.com">support@movingpencils.com</a>.
      </p>

      <h4>Address</h4>
      <p>
        45, L Narayana Reddy Main Rd,
        <br />
        Sector B, Vignan Nagar,
        <br />
        Doddanekkundi, Bengaluru,
        <br />
        Karnataka 560075
      </p>
    </div>
  );
}
