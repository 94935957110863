import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import RazorpayPayment from "./RazorpayPayment";
import { LOGIN_URL, useSVG } from "./SVGContext";
import { Button, CircularProgress, Dialog, Drawer, IconButton, Link, List, ListItem, ListItemText, Menu, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import HambergerMenu from "@mui/icons-material/Menu";
import { EditableDiv } from "./EditableDiv";
import { updateDesignServer } from "./utils/ServerUtils";
import DoneIcon from "@mui/icons-material/Done";

export function EditorNavBar({}) {
  const { isMobile, designId, setDrawerOpen, userInfo } = useSVG();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 5,
        justifyContent: "space-between",
        alignItems: "center",
        padding: 8,

        background: "linear-gradient(33deg, rgb(130 114 255) 30%, rgb(161 5 186) 60%)",
        color: "white",
      }}
    >
      <IconButton
        style={{
          color: "#ccc",
        }}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <HambergerMenu />
      </IconButton>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          justifyContent: "space-between",
          alignItems: "center",

          background: "transparent",
        }}
      >
        {designId && (
          <>
            {!isMobile && <Title />}
            <Save />
            <Download />
          </>
        )}

        {!userInfo && (
          <Link
            underline="none"
            variant="button"
            href={LOGIN_URL}
            style={{
              textTransform: "none",
              color: "white",
            }}
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
}
function Title() {
  const { designId, designTitle, setDesignTitle } = useSVG();
  return (
    <EditableDiv
      text={designTitle}
      setText={setDesignTitle}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      className="design-title"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.target.blur();
        }
        if (e.key === "Escape") {
          e.target.innerText = designTitle;
          e.target.blur();
        }
      }}
      onBlur={(e) => {
        setDesignTitle(e.target.innerText);
        updateDesignServer(designId, {
          title: e.target.innerText,
        });
      }}
    />
  );
}
function Download() {
  const [anchorEl, setAnchor] = useState(null);
  const [paymentDialog, setPaymentDialog] = useState(false);
  let [format, setFormat] = useState("png");

  async function download(f) {
    setAnchor(null);
    format = f;
    setFormat(format);
    setPaymentDialog(true);
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={(e) => setAnchor(anchorEl ? null : e.currentTarget)}
        sx={{
          borderRadius: "12px", // Set the desired border radius
          textTransform: "none",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          background: "rgb(229 229 229)",
          border: "1px solid white",
          color: "#333",
          borderRadius: 5,
        }}
      >
        <DownloadIcon fontSize="small" />
        Download
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem onClick={async () => await download("png")}>Download PNG</MenuItem>
        <MenuItem onClick={async () => await download("pdf")}>Download PDF</MenuItem>
      </Menu>

      <Dialog
        open={paymentDialog}
        onClose={() => setPaymentDialog(false)}
        style={{
          padding: 20,
        }}
      >
        <RazorpayPayment format={format} setPaymentDialog={setPaymentDialog} />
      </Dialog>
    </>
  );
}
function Save() {
  const [saving, setSaving] = useState(false);
  const { designId, pages, fileManager, svgRef, width, height, defs } = useSVG();

  return (
    <IconButton
      variant="outline"
      style={{
        color: "#ccc",
      }}
      onClick={() => {
        setSaving(true);
        fileManager.getPreviewImage({
          svgRef, width, height, defs, scale: 0.25
        }).then((canvas) => {
          const base64 = canvas.toDataURL("image/png");
          updateDesignServer(designId, {
            pages,
            preview: base64,
            previewWidth: canvas.width,
            previewHeight: canvas.height,
          })
          .then(() => {
            setSaving("saved");
            setTimeout(() => {
              setSaving(false);
            }, 3000);
          })
          .catch((e) => {
            setSaving(false);
          });
        });
      }}>
      {saving == true && (
        <CircularProgress
          style={{
            height: 24,
            width: 24,
          }}
        />
      )}
      {saving == false && <SaveIcon />}
      {saving == "saved" && <DoneIcon />}
    </IconButton>
  );
}
