import { useEffect, useState } from "react";
import { deleteImage, fetchImages, searchIcons, searchPexelsImages, uploadImage } from "../utils/ServerUtils";
import { DARKTHEME, THEME_BG, useSVG } from "../SVGContext";
import "./ImagesPanel.css";
import { Box, CircularProgress, IconButton, ImageList, ImageListItem, Tab, Tabs, TextField, ThemeProvider } from "@mui/material";

import { ImageUploads } from "./ImageUploads";
import LazyImage from "./LazyImage";

const unselectedTabBackground = THEME_BG;
const unselectedTabText = "#aaa";

const selectedTabBackground = "black";
const selectedTabText = "white";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  if (value === index) return <>{children}</>;
}

export function ImagesPanel() {
  const [images, setImages] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    reload();
  }, []);

  function reload() {
    fetchImages(setImages);
  }
  return (
    <>
      <div>
        <Tabs
          value={tabValue}
          onChange={(e, v) => setTabValue(v)}
          TabIndicatorProps={{
            sx: { display: "none" },
          }}
        >
          <Tab
            label="Uploads"
            value={0}
            style={{
              background: tabValue === 0 ? selectedTabBackground : unselectedTabBackground,
              color: tabValue === 0 ? selectedTabText : unselectedTabText,
              textTransform: "none",
            }}
          />
          <Tab
            label="Library"
            value={1}
            style={{
              background: tabValue === 1 ? selectedTabBackground : unselectedTabBackground,
              color: tabValue === 1 ? selectedTabText : unselectedTabText,
              textTransform: "none",
            }}
          />
        </Tabs>
      </div>
      <CustomTabPanel role="tabpanel" value={tabValue} index={0}>
        <ImageUploads images={images} reload={reload} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Library />
      </CustomTabPanel>
    </>
  );
}

function Library({}) {
  const [searchTabValue, setSearchTabValue] = useState(0);
  const [searchResults, setSearchResultsInt] = useState([]);
  let [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function search(query) {
    setIsLoading(true);
    if (searchTabValue == 0) {
      searchPexelsImages(query).then(setSearchResults);
    } else {
      searchIcons(query).then(parseSvgResults).then(setSearchResults);
    }
  }

  function setSearchResults(res) {
    setSearchResultsInt(res);
    setIsLoading(false);
  }

  function parseSvgResults(results) {
    for (let i = 0; i < results.length; i++) {
      const svgCode = results[i];
      const svgTag = svgCode.match(/<svg[^>]*>/)[0];

      // replace width="24" or width="24em" or width="24px" with empty string but not stroke-width="24"
      let newSvgTag = svgTag.replace(/ width="[^"]*"/, "");
      // replace height="24" or height="24em" or height="24px" with empty string
      newSvgTag = newSvgTag.replace(/ height="[^"]*"/, "");

      results[i] = svgCode.replace(svgTag, newSvgTag);
    }
    return results;
  }
  useEffect(() => {
    setSearchResults([]);
    query && search(query);
  }, [searchTabValue]);

  return (
    <>
      <ThemeProvider theme={DARKTHEME}>
        <div
          style={{
            background: selectedTabBackground,
            padding: 10,
            color: selectedTabText,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Images"
            value={query}
            onChange={(e) => {
              query = e.target.value;
              setQuery(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search(query);
              }
            }}
          />
        </div>

        {(query.length > 0 || searchResults.length > 0) && (
          <div
            style={{
              background: selectedTabBackground,
              color: selectedTabText,
            }}
          >
            <Tabs value={searchTabValue} onChange={(e, v) => setSearchTabValue(v)}>
              <Tab
                label="Images"
                value={0}
                style={{
                  textTransform: "none",
                }}
              />
              <Tab
                label="Icons"
                value={1}
                style={{
                  textTransform: "none",
                }}
              />
            </Tabs>
          </div>
        )}

        <CustomTabPanel value={searchTabValue} index={0}>
          <SearchResults searchResults={searchResults} searchTabValue={searchTabValue} isLoading={isLoading} />
        </CustomTabPanel>
        <CustomTabPanel value={searchTabValue} index={1}>
          <SearchResults searchResults={searchResults} searchTabValue={searchTabValue} isLoading={isLoading} />
        </CustomTabPanel>
      </ThemeProvider>
    </>
  );
}

function SearchResults({ searchResults, searchTabValue, isLoading }) {
  const { addImage, addIcon } = useSVG();

  if (searchResults.length == 0) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: 10,
          width: "100%",
          background: selectedTabBackground,
          color: selectedTabText,
          flexGrow: 1,
        }}
      >
        {isLoading && <CircularProgress />}
        {!isLoading && <span>So empty!</span>}
      </div>
    );
  }

  return (
    <div
      style={{
        overflowY: "scroll",
        padding: 10,
        background: selectedTabBackground,
        color: selectedTabText,
      }}
    >
      <ImageList cols={2} rowHeight={164}>
        {searchResults &&
          searchResults.map((image, j) => (
            <ImageListItem key={j}>
              {searchTabValue == 0 && <img src={image.src} alt={image.alt} onClick={() => addImage(image)} />}
              {searchTabValue == 1 && (
                <LazyImage
                  key={j}
                  minHeight={100}
                  svg={image}
                  style={{ width: "100%", border: "1px solid #e0e0e0" }}
                  onClick={() => {
                    addIcon(image);
                  }}
                />
              )}
            </ImageListItem>
          ))}
      </ImageList>
    </div>
  );
}
