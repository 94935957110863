// @ts-nocheck
import { memo, useRef, useState } from "react";
import RightClickMenu from "./RightClickMenu";
import { useSVG } from "./SVGContext";
import SVGDef from "./views/SvgDefView";
import { getCoordsInSvg } from "./utils/utils";
import { SvgGrid } from "./SvgGrid";
import { ROOT_GROUP_ID } from "./values/constants";
import { SVGGroupView } from "./views/SVGGroupView";
import DivWithEvents from "./DivWithEvents";
import { MultiselectBox } from "./MultiselectBox";
import { Topbar } from "./Topbar";
import { MousePosition } from "./MousePosition";
import { RightPanel } from "./RightPanel";
import { CircularProgress } from "@mui/material";
import { FRAME_DEFS } from "./values/constants";
import { ARROW_MARKER_DEFS } from "./values/constants";
import { ZoomSlider } from "./ZoomSlider";

const SVGContainer = memo(({}) => {
  const z = useSVG();

  const { viewBox, tool, items, groups, defs, fill, stroke, selectedIds, resizeRect, selectedCorners, outline, mouseReceivers, svgDecorators, multiSelectBox, croppingItemId, clearTopbarSelectedButtons, fullscreenLoading, pages, selectedPage, setMousePos } = useSVG();
  const { width, height, svgRef, cursor } = useSVG();

  /*********** right click menu ********/
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);

  const handleRightClick = (event) => {
    // BUG: right click is also going to onMouseDown (or onMouseUp) -- try right clicking when shape tool is on
    event.preventDefault(); // Prevent default browser right-click menu
    event.stopPropagation(); // Prevent right-click from bubbling up to the window
    setShowMenu(true);
    setMenuPosition({ x: event.pageX, y: event.pageY });
  };
  /************************************/

  const fakeEvent = (cb, event, items) => {
    let ncb = cb + "New";
    if (!tool) return;

    if (!tool[ncb] && !tool[cb]) return;

    let x, y;
    if (event.touches && event.touches.length == 1) {
      x = event.touches[0].clientX;
      y = event.touches[0].clientY;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    let xy = getCoordsInSvg(svgRef, { x, y });

    // let target;
    // target = findTargetInSvgForFakeMouseEvent(event, svgRef, items);
    let target = event.target;
    try {
      tool[ncb] && tool[ncb]({ event, xy, target, items, groups, defs, selectedIds, fill, stroke, resizeRect, cursor, multiSelectBox, selectedCorners, viewBox, z, history: z.history, outline });
    } catch (e) {
      console.error(e);
    }
  };

  const onOverlayMouseDown = (event) => {
    setShowMenu(false);

    fakeEvent("onMouseDown", event, items);
  };
  const onOverlayMouseMove = (event) => {
    // mouseCoords = getCoordinatesInSVG(svgRef, viewBox, width, height, event);

    // setMousePos(
    //   getCoordsInSvg(svgRef, {
    //     x: event.nativeEvent.clientX,
    //     y: event.nativeEvent.clientY,
    //   }),
    // );
    fakeEvent("onMouseMove", event, items);
  };
  const onOverlayMouseUp = (event) => {
    fakeEvent("onMouseUp", event, items);
  };
  const onOverlayMouseOut = (event) => {
    fakeEvent("onMouseOut", event, items);
  };
  const onOverlayTouchMove = (event) => {
    fakeEvent("onMouseMove", event, items);
  };
  const onOverlayTouchUp = (event) => {
    fakeEvent("onMouseUp", event, items);
  };
  const onOverlayTouchDown = (event) => {
    fakeEvent("onMouseDown", event, items);
  };
  const eventHandlers = {
    onMouseDown: onOverlayMouseDown,
    onMouseMove: onOverlayMouseMove,
    onMouseUp: onOverlayMouseUp,
    onMouseOut: onOverlayMouseOut,
    onTouchStart: onOverlayTouchDown,
    onTouchMove: onOverlayTouchMove,
    onTouchEnd: onOverlayTouchUp,
    onTouchCancel: onOverlayTouchUp,
    onContextMenu: handleRightClick,
  };

  return (
    <div
      style={{
        position: "relative",
        flexGrow: 1,

        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      <div className="topbar">
        <Topbar selectedIds={selectedIds} />
      </div>
      <div
        className="hide-scrollbar"
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: "stretch",
          alignItems: "stretch",

          overflow: "scroll",
        }}
      >
        <DivWithEvents className="SVGContainer" style={{ backgroundColor: "lightgray", cursor, flex: 1 }} {...eventHandlers}>
          <svg
            key="svg1"
            xmlns="http://www.w3.org/2000/svg"
            tabIndex="0"
            className="Board"
            ref={svgRef}
            viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
            style={{
              opacity: croppingItemId ? 0.2 : 1,
            }}
            height="100%"
            width="100%"
          >
            <g>
              <defs>
                {[...FRAME_DEFS, ...ARROW_MARKER_DEFS].map((def, index) => (
                  <SVGDef key={index} def={def} />
                ))}
                {defs.map((def, index) => (
                  <SVGDef key={index} def={def} />
                ))}
              </defs>
              <SVGGroupView group={groups[ROOT_GROUP_ID]} />
            </g>

            {
              <g id="overlay">
                <MultiselectBox multiSelectBox={multiSelectBox} selectedIds={selectedIds} />

                {outline && <path id="hover-outline" d={outline.d} fill="none" stroke={outline.stroke} strokeWidth="2" transform={outline.transform} />}

                <SVGGroupView group={groups[ROOT_GROUP_ID]} overlay={true} />
              </g>
            }
          </svg>
          <svg
            id="overlay"
            height="100%"
            width="100%"
            viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              display: croppingItemId ? "block" : "none",
            }}
            onMouseUp={(e) => {
              clearTopbarSelectedButtons();
            }}
          >
            <SVGGroupView group={groups[ROOT_GROUP_ID]} overlay={true} />
          </svg>

          {fullscreenLoading && (
            <div className="loading-overlay">
              <CircularProgress />
            </div>
          )}
        </DivWithEvents>
        <RightPanel selectedIds={selectedIds} />
      </div>
      <div
        classname="editorbottombar"
        style={{
          position: "static",
          bottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
          padding: 5,
        }}
      >
        <ZoomSlider />
      </div>
      {/* <MousePosition /> */}
      {showMenu && <RightClickMenu x={menuPosition.x} y={menuPosition.y} setShowMenu={setShowMenu} />}
    </div>
  );
});

export default SVGContainer;
