// @ts-nocheck

export function transform(item, transformation) {
  item.mp = item.mp || {};
  item.mpTransforms = item.mpTransforms || {
    translate: { x: 0, y: 0 },
    scale: { x: 1, y: 1 },
    rotate: { angle: 0 },
  };
  const t = transformation,
    mp = item.mpTransforms;
  if (t.type == "translate") {
    mp.translate.x = +mp.translate.x + +t.x;
    mp.translate.y = +mp.translate.y + +t.y;
  } else if (t.type == "scale") {
    if (item.width) {
      item.width = item.width * t.x;
    } else if (item.rx) {
      item.rx = item.rx * t.x;
    } else {
      mp.scale.x = +mp.scale.x * +t.x;
    }
    if (item.height) {
      item.height = item.height * t.y;
    } else if (item.ry) {
      item.ry = item.ry * t.y;
    } else {
      mp.scale.y = +mp.scale.y * +t.y;
    }
  } else if (t.type == "rotate") {
    mp.rotate.angle = +mp.rotate.angle + +t.angle;
  }
  item.mpTransforms = mp;
  item.mpTransformStr = getTransformStr(item);

  return item;
}

export function getTransformStr(item) {
  const t = item.mpTransforms;
  const translate = `translate(${t.translate.x}, ${t.translate.y})`;
  const scale = `scale(${t.scale.x}, ${t.scale.y})`;
  let rotate;
  let b = document.getElementById(item.id).getBBox();
  const center = {
    x: b.x + b.width / 2,
    y: b.y + b.height / 2,
  };
  rotate = `rotate(${t.rotate.angle}, ${center.x}, ${center.y})`;
  return `${translate} ${scale} ${rotate}`;
}
