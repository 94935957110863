import { Button, ButtonGroup, Divider, IconButton, InputLabel, ListSubheader, Menu, MenuItem, Select, Slider, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSVG } from "../SVGContext";
import { GoogleFontFamilies } from "../values/GoogleFontFamilies";
import React, { useEffect, useState } from "react";
import { allGoogleFonts } from "../values/all-google-fonts";
import AlignLeft from "@mui/icons-material/FormatAlignLeft";
import AlignCenter from "@mui/icons-material/FormatAlignCenter";
import AlignRight from "@mui/icons-material/FormatAlignRight";
import { ColorSquare } from "../ColorSquare";
import { addFontDef } from "../utils/utils";
import { RotateBtn } from "./RotateBtn";
import { FontsList } from "../FontsList";
import { TopbarCommons } from "./TopbarCommons";
import MyIconButton from "./MyIconButton";

const TopbarText = ({ item }) => {
  let { items, setItems, selectedIds, defs, setDefs, editor, selectedFont } = useSVG();

  // useEffect(() => {
  //   if (!item) return;

  //   let style = item.p[0]?.style;
  //   if (!style) return;

  //   console.log(style["font-family"]);
  //   setStyle(style);
  //   // font family
  //   if (style["font-family"]) {
  //     const pat = style["font-family"];
  //     if (allGoogleFonts[pat]) {
  //       // if the font is not present on the list shown here, but is a google font, then add it to the list
  //       fonts.push(pat);
  //       setFonts([...fonts]);
  //       setSelectedFontIdx(fonts.length - 1);
  //     }
  //   }
  // }, [item]);

  if (!item) return;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        alignItems: "center",
        justifyContent: "start",
      }}
      className="hide-scrollbar"
    >
      <FontColor item={item} />
      <FontsList />
      <FontSize item={item} />

      {multiButtons.map((button) => {
        const prop = "text" + button.name.charAt(0).toUpperCase() + button.name.slice(1);
        return (
          <div key={button.name}>
            <ToggleButtonGroup
              value={item[prop]}
              exclusive
              onChange={(e, value) => {
                for (let i = 0; i < item.p.length; i++) {
                  item.p[i].style = item.p[i].style || {};
                  item.p[i].style[button.name] = value;
                }
                items[item.id] = item;
                setItems({ ...items });
              }}
            >
              {button.options.map((option) => (
                <ToggleButton size="small" value={option} key={option}>
                  <span
                    style={{
                      textDecoration: option,
                      fontSize: "0.8em",
                      lineHeight: "1.5em",
                    }}
                  >
                    A
                  </span>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        );
      })}
      <BoldItalicUnderline item={item} />
      {/* <Align item={item} /> */}

      {/* <IconButton>
        <HeightIcon />
      </IconButton> */}
      <TopbarCommons item={item} />
    </div>
  );
};

export default TopbarText;

const multiButtons = [
  {
    name: "decoration",
    options: ["underline", "line-through", "overline"],
  }
];

function FontSize() {
  const { editor, editorState, setEditorState } = useSVG();

  const fontSizeNum = +editorState.fontSize.replace("px", "");

  function setFontSize(size) {
    console.log("setting", size);
    editor.chain().focus().setFontSize(size+"px").run();
    editor.view.dispatch(editor.state.tr);
  }

  return (
    <ButtonGroup size="small">
      <Button onClick={() => setFontSize(fontSizeNum - 1)}>
        <span>-</span>
      </Button>
      <Button>{fontSizeNum}</Button>
      <Button onClick={() => setFontSize(fontSizeNum + 1)}>
        <span>+</span>
      </Button>
    </ButtonGroup>
  );
}

function Align({ item }) {
  const { items, setItems } = useSVG();
  const [anchorEl, setAnchor] = useState(null);

  let alignment = item.p[0]?.style.textAlign;

  return (
    <div>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        {(!alignment || alignment === "left") && <AlignLeft />}
        {alignment === "center" && <AlignCenter />}
        {alignment === "right" && <AlignRight />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {["Left", "Center", "Right"].map((choice) => (
          <MenuItem
            onClick={() => {
              for (let i = 0; i < item.p.length; i++) {
                item.p[i].style = item.p[i].style || {};
                item.p[i].style = { ...item.p[i].style, textAlign: choice.toLowerCase() };
              }
              items[item.id] = item;
              setItems({ ...items });

              setAnchor(null);
            }}
          >
            {choice === "Left" && <AlignLeft />}
            {choice === "Center" && <AlignCenter />}
            {choice === "Right" && <AlignRight />}
            <span style={{ marginLeft: 8 }}>{choice}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const editorValue = {
  B: "Bold",
  I: "Italic",
  U: "Underline",
};
function BoldItalicUnderline({ item }) {
  const {  editor } = useSVG();

  return ["B", "I", "U"].map((t, i) => (
    <MyIconButton
      key={i}
      size="small"
      isPressed={editor.isActive(editorValue[t].toLowerCase())}
      onClick={() => {
        const fn = "toggle" + editorValue[t];
        editor.chain().focus()[fn]().run();
      }}
    >
      <span
        style={{
          textDecoration: t,
          fontSize: "0.8em",
          lineHeight: "1.5em",
        }}
      >
        {t}
      </span>
    </MyIconButton>
  ));
}

function FontColor() {
  const { editor, editorState } = useSVG();

  return (
    <ColorSquare
      color={editorState.color}
      onChange={(color) => {
        editor.chain().focus().setColor(color.hex).run();
        editor.view.dispatch(editor.state.tr);
      }}
    />
  );
}
