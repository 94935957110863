import { ArrangeBtn } from "./ArrangeBtn";
import { RotateBtn } from "./RotateBtn";

export function TopbarCommons({ item }) {
  return (
    <>
      <RotateBtn item={item} />
      <ArrangeBtn />
    </>
  );
}
