// @ts-nocheck

import { useRef, useState } from "react";
import MySketchPicker from "./MySketchPicker";
import { Popper } from "@mui/material";

export function ColorSquare({ color, isStroke = false, onChange }) {
  const [showPicker, setShowPicker] = useState(false);
  const colorRef = useRef(null);

  const style = {
    width: 30,
    height: 30,
    backgroundColor: color,
    borderRadius: 5,
    border: "solid 1px #555",
  };
  if (isStroke) {
    style.border = "solid 1px " + color;
    style.boxShadow = "0 0 0 1px #555";
    style.backgroundColor = "transparent";
  }

  return (
    <div>
      <div
        ref={colorRef}
        style={style}
        onClick={() => {
          setShowPicker(true);
        }}
      ></div>
      <Popper open={showPicker} anchorEl={colorRef.current} placement="bottom-start" style={{ zIndex: 1000 }}>
        <MySketchPicker setShowPicker={setShowPicker} color={color} onChangeComplete={onChange} />
      </Popper>
    </div>
  );
}
