// @ts-nocheck

import { Cursor } from "../values/enums";
import { getBoundingBox, isSamePoint } from "./utils";

// when a bunch of items are selected, this creates multi-select box for those items
export function createMultiSelectBox(items, selectedIds, setMultiSelectBox, svgRef) {
  let minX = Infinity,
    minY = Infinity,
    maxEndX = -Infinity,
    maxEndY = -Infinity;

  for (let i = 0; i < selectedIds.length; i++) {
    if (!items[selectedIds[i]]) continue;
    let box = getBoundingBox(svgRef, items[selectedIds[i]]);
    if (!box) continue;
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxEndX = Math.max(maxEndX, box.x + box.width);
    maxEndY = Math.max(maxEndY, box.y + box.height);
  }

  if (minX == Infinity || minY == Infinity || maxEndX == -Infinity || maxEndY == -Infinity) {
    setMultiSelectBox(null);
    return;
  }
  const x = minX,
    y = minY,
    width = maxEndX - minX,
    height = maxEndY - minY,
    center = { x: x + width / 2, y: y + height / 2 };
  let multiSelectBox = {
    x,
    y,
    width,
    height,
    center,
  };
  setMultiSelectBox(multiSelectBox);
}
export function getScaleCursor(xy, selectedIds, b) {
  if (!b || selectedIds.length == 0) return null;
  const points = [
    {
      x: b.x,
      y: b.y,
      cursor: "nw-resize",
    },
    {
      x: b.x + b.width / 2,
      y: b.y,
      cursor: "n-resize",
    },
    {
      x: b.x + b.width,
      y: b.y,
      cursor: "ne-resize",
    },
    {
      x: b.x + b.width,
      y: b.y + b.height / 2,
      cursor: "e-resize",
    },
    {
      x: b.x + b.width,
      y: b.y + b.height,
      cursor: "se-resize",
    },
    {
      x: b.x + b.width / 2,
      y: b.y + b.height,
      cursor: "s-resize",
    },
    {
      x: b.x,
      y: b.y + b.height,
      cursor: "sw-resize",
    },
    {
      x: b.x,
      y: b.y + b.height / 2,
      cursor: "w-resize",
    },
  ];
  for (let p of points) {
    if (isSamePoint(xy, p, 5)) return p.cursor;
  }
}
export function getUnmovingPoint(box, cursor) {
  if (!box) return null;
  let center;
  if (cursor == Cursor.ResizeNW) {
    // top left
    center = { x: box.x + box.width, y: box.y + box.height };
  } else if (cursor == Cursor.ResizeN) {
    // top middle
    center = { x: box.x + box.width / 2, y: box.y + box.height };
  } else if (cursor == Cursor.ResizeNE) {
    // top right
    center = { x: box.x, y: box.y + box.height };
  } else if (cursor == Cursor.ResizeE) {
    // right middle
    center = { x: box.x, y: box.y + box.height / 2 };
  } else if (cursor == Cursor.ResizeSE) {
    // bottom right
    center = { x: box.x, y: box.y };
  } else if (cursor == Cursor.ResizeS) {
    // bottom middle
    center = { x: box.x + box.width / 2, y: box.y };
  } else if (cursor == Cursor.ResizeSW) {
    // bottom left
    center = { x: box.x + box.width, y: box.y };
  } else if (cursor == Cursor.ResizeW) {
    // left middle
    center = { x: box.x + box.width, y: box.y + box.height / 2 };
  }
  return center;
}

export function isBottomRightOfSomeBoundingBox(xy, multiSelectBox) {
  let box = multiSelectBox;
  if (!box) return false;

  const bottomRightOfBoundingBox = {
    x: box.x + box.width,
    y: box.y + box.height,
  };
  return isSamePoint(xy, bottomRightOfBoundingBox, 3);
}

export function getRotateCursor(xy, items, selectedIds, multiSelectBox) {
  if (selectedIds.length == 0) return null;

  let box;
  if (selectedIds.length == 1) {
    box = items[selectedIds[0]].box;
  } else {
    box = multiSelectBox;
  }
  if (!box) return null;
  let dx = xy.x - box.x,
    dy = xy.y - box.y;
  if (dx < 0 && dx > -40 && dy < 0 && dy > -40) return Cursor.RotateNW;

  dx = xy.x - (box.x + box.width);
  dy = xy.y - box.y;
  if (dx > 0 && dx < 40 && dy < 0 && dy > -40) return Cursor.RotateNE;

  dx = xy.x - box.x;
  dy = xy.y - (box.y + box.height);
  if (dx < 0 && dx > -40 && dy > 0 && dy < 40) return Cursor.RotateSW;

  dx = xy.x - (box.x + box.width);
  dy = xy.y - (box.y + box.height);
  if (dx > 0 && dx < 40 && dy > 0 && dy < 40) return Cursor.RotateSE;
}

export const findAncestralTransform = (target) => {
  let transform = "";
  while (target) {
    const t = target.getAttribute("transform");
    if (t) {
      transform = t + " " + transform;
    }
    target = target.parentElement;
  }
  return transform;
};
