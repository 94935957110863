import { Skeleton } from "@mui/material";
import React, { useState } from "react";

export const ImageWithSkeleton = ({ src, alt, height, width }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && <Skeleton variant="rectangular" width={200} height={height} />}
      <img
        src={src}
        alt={alt}
        style={{
          width,
          height,
          objectFit: "cover",
          display: loaded ? "block" : "none",
        }}
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(true)} // Hide skeleton even if load fails
      />
    </>
  );
};
