import { deleteImage, fetchImages, searchIcons, searchPexelsImages, uploadImage } from "../utils/ServerUtils";
import { useSVG } from "../SVGContext";
import "./ImagesPanel.css";
import { IconButton, ImageList, ImageListItem } from "@mui/material";
import { Button } from "@mui/material";
import { useRef, useState } from "react";

const selectedTabBackground = "black";
const selectedTabText = "white";

export function ImageUploads({ images, reload }) {
  const { addImage, setFullscreenLoading } = useSVG();

  return (
    <div
      style={{
        background: selectedTabBackground,
        color: selectedTabText,
        flexGrow: 1,
        padding: 10,
      }}
    >
      <Upload reload={reload} setFullscreenLoading={setFullscreenLoading} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          flexWrap: "wrap",
          justifyContent: "start",
          marginTop: 10,
          background: selectedTabBackground,
          color: selectedTabText,
        }}
      >
        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {images &&
            images.map((image) => (
              <ImageListItem key={image.id} className="image-container">
                <img src={image.src} alt={image.name} onClick={() => addImage(image)} />
                <div className="options">
                  <IconButton
                    size="small"
                    onClick={() => {
                      deleteImage(image.id, reload);
                    }}
                    style={{
                      color: "#9f0303",
                    }}
                  >
                    {/* <DeleteIcon /> */}
                  </IconButton>
                </div>
              </ImageListItem>
            ))}
        </ImageList>
      </div>
    </div>
  );
}
function Upload({ reload, setFullscreenLoading }) {
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  return (
    <div>
      <Button
        type="contained"
        onClick={() => {
          inputRef.current.click();
        }}
        style={{
          width: "100%",
          background: "purple",
        }}
      >
        + Upload Image
      </Button>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          setFullscreenLoading(true);
          uploadImageWithDimensions(
            e.target.files[0],
            () => {
              reload();
              // clear the input
              e.target.value = "";
              setFullscreenLoading(false);
            },
            function (err) {
              console.error(err);
              e.target.value = "";
              setFullscreenLoading(false);
              setError(err);
              setTimeout(() => {
                setError(null);
              }, 10000);
            },
          );
        }}
      />
      <div
        style={{
          color: "red",
        }}
      >
        {error}
      </div>
    </div>
  );
}

const uploadImageWithDimensions = (file, cb, error) => {
  if (!file.type.startsWith("image/")) {
    error("File is not an image.");
    return;
  }

  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", width);
      formData.append("height", height);

      uploadImage(formData)
        .then(cb)
        .catch((e) => error("Error uploading image.", e));
    };
    img.src = event.target.result;
  };

  reader.readAsDataURL(file);
};
