export const SUBCATEGORIES = {
  "album covers": {
    bold: ["template_18438.jpg", "template_22539.jpg"],
    artistic: ["template_2651.jpg", "template_14165.jpg"],
    party: ["template_18087.jpg", "template_22812.jpg"],
    "hip hop": ["template_15509.jpg", "template_11732.jpg"],
    minimalist: ["template_16676.jpg", "template_15882.jpg"],
    "electronic music": ["template_9736.jpg", "template_11443.jpg"],
    jazz: ["template_14691.jpg", "template_17386.jpg"],
    mixtape: ["template_13610.jpg", "template_20419.jpg"],
    "r&b": ["template_21281.jpg", "template_22723.jpg"],
    dj: ["template_4228.jpg", "template_2754.jpg"],
    rock: ["template_18647.jpg", "template_6375.jpg"],
    sad: ["template_14329.jpg", "template_1852.jpg"],
  },
  banners: {
    "facebook banners": ["template_7869.jpg", "template_573.jpg"],
    "twitter banners": ["template_17147.jpg", "template_15634.jpg"],
    "linkedin banners": ["template_3857.jpg", "template_13632.jpg"],
    "instagram banners": ["template_22439.jpg", "template_15846.jpg"],
    "youtube banners": ["template_10102.jpg", "template_2527.jpg"],
    "email headers": ["template_4231.jpg", "template_23877.jpg"],
    "blog headers": ["template_20866.jpg", "template_6489.jpg"],
    birthday: ["template_5171.jpg", "template_20110.jpg"],
    "grand opening": ["template_5332.jpg", "template_19680.jpg"],
    sales: ["template_10681.jpg", "template_21917.jpg"],
    "black friday": ["template_9564.jpg", "template_22862.jpg"],
    wedding: ["template_14286.jpg", "template_8836.jpg"],
    "new year": ["template_9824.jpg", "template_7455.jpg"],
    graduation: ["template_1238.jpg", "template_17447.jpg"],
    gaming: ["template_5166.jpg", "template_17234.jpg"],
    football: ["template_13488.jpg", "template_19694.jpg"],
    holidays: ["template_17022.jpg", "template_21696.jpg"],
    christmas: ["template_3720.jpg", "template_11847.jpg"],
    halloween: ["template_3127.jpg", "template_12641.jpg"],
    diwali: ["template_10345.jpg", "template_15571.jpg"],
    "back to school": ["template_22786.jpg", "template_20282.jpg"],
    thanksgiving: ["template_6534.jpg", "template_19253.jpg"],
    "veterans day": ["template_22606.jpg", "template_4293.jpg"],
    fashion: ["template_8074.jpg", "template_19710.jpg"],
    product: ["template_19236.jpg", "template_10589.jpg"],
    food: ["template_7656.jpg", "template_3259.jpg"],
    clothing: ["template_12231.jpg", "template_14348.jpg"],
    "lunar new year": ["template_10640.jpg", "template_4406.jpg"],
    math: ["template_19154.jpg", "template_18001.jpg"],
    "mothers day": ["template_21277.jpg", "template_3671.jpg"],
  },
  "book covers": {
    inspirational: ["template_20659.jpg", "template_2924.jpg"],
    romance: ["template_18010.jpg", "template_2190.jpg"],
    thriller: ["template_2764.jpg", "template_8401.jpg"],
    creative: ["template_8070.jpg", "template_6430.jpg"],
    photo: ["template_4257.jpg", "template_5035.jpg"],
    notebook: ["template_14120.jpg", "template_11089.jpg"],
    vintage: ["template_19571.jpg", "template_15288.jpg"],
    childrens: ["template_17649.jpg", "template_16120.jpg"],
    art: ["template_11684.jpg", "template_2726.jpg"],
    illustration: ["template_10315.jpg", "template_3367.jpg"],
    architecture: ["template_10380.jpg", "template_13731.jpg"],
    recipe: ["template_18187.jpg", "template_17157.jpg"],
    novel: ["template_13987.jpg", "template_20267.jpg"],
    "food journal": ["template_11878.jpg", "template_20925.jpg"],
    "prayer journal": ["template_11457.jpg", "template_19611.jpg"],
    business: ["template_9907.jpg", "template_10829.jpg"],
    fashion: ["template_17919.jpg", "template_8295.jpg"],
    "graphic design": ["template_2004.jpg", "template_16491.jpg"],
    autobiography: ["template_23434.jpg", "template_21955.jpg"],
    collage: ["template_8414.jpg", "template_3007.jpg"],
    fiction: ["template_456.jpg", "template_23535.jpg"],
    "science fiction": ["template_15615.jpg", "template_9870.jpg"],
    photography: ["template_22992.jpg", "template_3539.jpg"],
    "non fiction": ["template_11431.jpg", "template_2276.jpg"],
    christmas: ["template_11375.jpg", "template_3081.jpg"],
    classic: ["template_962.jpg", "template_22055.jpg"],
    modern: ["template_12055.jpg", "template_728.jpg"],
    comic: ["template_6427.jpg", "template_7896.jpg"],
    "self help": ["template_5421.jpg", "template_4723.jpg"],
    poetry: ["template_1074.jpg", "template_4441.jpg"],
    typography: ["template_17316.jpg", "template_3380.jpg"],
    family: ["template_10549.jpg", "template_11843.jpg"],
    minimalist: ["template_21626.jpg", "template_9225.jpg"],
  },
  brochures: {
    "travel brochures": ["template_11101.jpg", "template_12125.jpg"],
    "tri fold brochures": ["template_19173.jpg", "template_13688.jpg"],
    "pamphlet brochures": ["template_12921.jpg", "template_22272.jpg"],
    "bi fold brochures": ["template_2618.jpg", "template_21494.jpg"],
    "real estate brochures": ["template_78.jpg", "template_18809.jpg"],
    "event program": ["template_1782.jpg", "template_15928.jpg"],
    hotel: ["template_17769.jpg", "template_2133.jpg"],
    spa: ["template_15574.jpg", "template_11384.jpg"],
    wedding: ["template_11011.jpg", "template_6368.jpg"],
    fitness: ["template_8082.jpg", "template_7875.jpg"],
    church: ["template_22194.jpg", "template_2008.jpg"],
    car: ["template_19937.jpg", "template_2770.jpg"],
    charity: ["template_3803.jpg", "template_11657.jpg"],
    company: ["template_15487.jpg", "template_11946.jpg"],
    construction: ["template_15150.jpg", "template_8648.jpg"],
    dental: ["template_17188.jpg", "template_22235.jpg"],
    food: ["template_11380.jpg", "template_6919.jpg"],
    college: ["template_6581.jpg", "template_21147.jpg"],
    education: ["template_12924.jpg", "template_16283.jpg"],
    art: ["template_15999.jpg", "template_8870.jpg"],
    gym: ["template_835.jpg", "template_5001.jpg"],
    school: ["template_20183.jpg", "template_16305.jpg"],
    photography: ["template_11711.jpg", "template_6647.jpg"],
    product: ["template_6064.jpg", "template_17119.jpg"],
    funeral: ["template_15876.jpg", "template_1030.jpg"],
    catering: ["template_2361.jpg", "template_1072.jpg"],
    hospitality: ["template_14976.jpg", "template_4605.jpg"],
    technology: ["template_11618.jpg", "template_2077.jpg"],
    "government and civic information": ["template_2212.jpg", "template_14179.jpg"],
    bakery: ["template_6931.jpg", "template_19775.jpg"],
    salon: ["template_2749.jpg", "template_585.jpg"],
    landscaping: ["template_11072.jpg", "template_17807.jpg"],
    "pet services": ["template_2688.jpg", "template_3308.jpg"],
    cleaning: ["template_23131.jpg", "template_5545.jpg"],
    "financial services": ["template_4670.jpg", "template_100.jpg"],
    environmental: ["template_18724.jpg", "template_3453.jpg"],
    "home improvement": ["template_22821.jpg", "template_8460.jpg"],
    agriculture: ["template_19212.jpg", "template_13456.jpg"],
    insurance: ["template_6953.jpg", "template_126.jpg"],
    transportation: ["template_16391.jpg", "template_7457.jpg"],
    "product launch": ["template_18120.jpg", "template_14465.jpg"],
    "trade show and exhibition": ["template_17401.jpg", "template_15188.jpg"],
    "conference and seminar": ["template_21397.jpg", "template_20965.jpg"],
    "z fold": ["template_11642.jpg", "template_22626.jpg"],
    "accordion fold": ["template_9842.jpg", "template_18169.jpg"],
    "half fold": ["template_2201.jpg", "template_14493.jpg"],
    "double parallel fold": ["template_8452.jpg", "template_295.jpg"],
    "roll fold": ["template_22252.jpg", "template_15981.jpg"],
    "c fold": ["template_9772.jpg", "template_6084.jpg"],
    "gate fold": ["template_18792.jpg", "template_10207.jpg"],
    "double gate fold": ["template_13587.jpg", "template_4653.jpg"],
    "one page": ["template_31000", "template_31001"],
    medical: ["template_15215.jpg", "template_2969.jpg"],
    "mental health": ["template_6928.jpg", "template_4485.jpg"],
    sports: ["template_2172.jpg", "template_4141.jpg"],
    sales: ["template_19999.jpg", "template_19448.jpg"],
    restaurant: ["template_23041.jpg", "template_16552.jpg"],
    event: ["template_1089.jpg", "template_21548.jpg"],
    fundraising: ["template_2257.jpg", "template_3425.jpg"],
    corporate: ["template_12682.jpg", "template_9408.jpg"],
    legal: ["template_21798.jpg", "template_22538.jpg"],
    "grand opening": ["template_5814.jpg", "template_20808.jpg"],
    museum: ["template_12760.jpg", "template_3540.jpg"],
  },
  "business cards": {
    music: ["template_1277.jpg", "template_15854.jpg"],
    trainer: ["template_17465.jpg", "template_1455.jpg"],
    artist: ["template_13345.jpg", "template_4541.jpg"],
    photography: ["template_17569.jpg", "template_5225.jpg"],
    "real estate": ["template_8260.jpg", "template_9217.jpg"],
    professional: ["template_6927.jpg", "template_23579.jpg"],
    personal: ["template_4255.jpg", "template_12451.jpg"],
    "nail art": ["template_7060.jpg", "template_16702.jpg"],
    construction: ["template_18240.jpg", "template_7283.jpg"],
    barber: ["template_10540.jpg", "template_14031.jpg"],
    church: ["template_44.jpg", "template_10937.jpg"],
    tattoo: ["template_17424.jpg", "template_22151.jpg"],
    electrician: ["template_1284.jpg", "template_6256.jpg"],
    aesthetician: ["template_6392.jpg", "template_5197.jpg"],
    lash: ["template_18331.jpg", "template_7009.jpg"],
    "hair salon": ["template_13957.jpg", "template_18649.jpg"],
    "massage therapist": ["template_1853.jpg", "template_1795.jpg"],
    bartender: ["template_19466.jpg", "template_4856.jpg"],
    florist: ["template_12927.jpg", "template_11050.jpg"],
    automotive: ["template_10874.jpg", "template_9551.jpg"],
    "qr code": ["template_9051.jpg", "template_1009.jpg"],
    cleaning: ["template_10921.jpg", "template_15161.jpg"],
    landscaping: ["template_8703.jpg", "template_9621.jpg"],
    photo: ["template_17152.jpg", "template_11408.jpg"],
    simple: ["template_18192.jpg", "template_8019.jpg"],
    minimalist: ["template_12207.jpg", "template_22083.jpg"],
    restaurant: ["template_15197.jpg", "template_4671.jpg"],
    beauty: ["template_3959.jpg", "template_9849.jpg"],
    corporate: ["template_17803.jpg", "template_9449.jpg"],
    cute: ["template_15969.jpg", "template_22158.jpg"],
    fitness: ["template_15194.jpg", "template_3900.jpg"],
    student: ["template_12319.jpg", "template_23508.jpg"],
    contractor: ["template_3979.jpg", "template_16378.jpg"],
    fashion: ["template_7378.jpg", "template_6714.jpg"],
    cake: ["template_22782.jpg", "template_3307.jpg"],
    "makeup artist": ["template_18817.jpg", "template_7718.jpg"],
    "pressure washing": ["template_1152.jpg", "template_4123.jpg"],
    yoga: ["template_21152.jpg", "template_150.jpg"],
    wedding: ["template_18082.jpg", "template_15419.jpg"],
    writer: ["template_18534.jpg", "template_7906.jpg"],
    creative: ["template_463.jpg", "template_17505.jpg"],
    babysitting: ["template_3609.jpg", "template_7126.jpg"],
    actor: ["template_7953.jpg", "template_8554.jpg"],
    handyman: ["template_15850.jpg", "template_3476.jpg"],
    modern: ["template_11960.jpg", "template_16452.jpg"],
    luxury: ["template_5725.jpg", "template_6394.jpg"],
    catering: ["template_14134.jpg", "template_4201.jpg"],
    painter: ["template_2682.jpg", "template_4035.jpg"],
    dental: ["template_3254.jpg", "template_1858.jpg"],
    bakery: ["template_14052.jpg", "template_23430.jpg"],
    medical: ["template_12945.jpg", "template_20666.jpg"],
    black: ["template_21536.jpg", "template_16216.jpg"],
    "interior design": ["template_12923.jpg", "template_11248.jpg"],
    teacher: ["template_16857.jpg", "template_14485.jpg"],
    "graphic design": ["template_7835.jpg", "template_4446.jpg"],
    networking: ["template_13218.jpg", "template_2865.jpg"],
    dj: ["template_10630.jpg", "template_3662.jpg"],
    vintage: ["template_393.jpg", "template_8540.jpg"],
    lawyer: ["template_18708.jpg", "template_5067.jpg"],
    appointment: ["template_14029.jpg", "template_10863.jpg"],
    "event planner": ["template_15994.jpg", "template_12040.jpg"],
    military: ["template_7452.jpg", "template_941.jpg"],
  },
  cards: {
    sympathy: ["template_17552.jpg", "template_5630.jpg"],
    "eid al fitr": ["template_18409.jpg", "template_8782.jpg"],
    graduation: ["template_5853.jpg", "template_9690.jpg"],
    halloween: ["template_7530.jpg", "template_22603.jpg"],
    "hannukah cards": ["template_13771.jpg", "template_6723.jpg"],
    apology: ["template_14462.jpg", "template_18812.jpg"],
    "sales battlecards": ["template_9693.jpg", "template_3686.jpg"],
    loyalty: ["template_18960.jpg", "template_5824.jpg"],
    id: ["template_20940.jpg", "template_12202.jpg"],
    recipe: ["template_1316.jpg", "template_8334.jpg"],
    obituary: ["template_4429.jpg", "template_4134.jpg"],
    "veterans day": ["template_9916.jpg", "template_10018.jpg"],
    congratulations: ["template_23905.jpg", "template_10647.jpg"],
    "holiday cards": ["template_17078.jpg", "template_18223.jpg"],
    "fathers day cards": ["template_10003.jpg", "template_1650.jpg"],
    "birthday cards": ["template_23355.jpg", "template_12642.jpg"],
    "christmas cards": ["template_4262.jpg", "template_10267.jpg"],
    "get well cards": ["template_18805.jpg", "template_18442.jpg"],
    "valentines day cards": ["template_18198.jpg", "template_17853.jpg"],
    "thanksgiving cards": ["template_15371.jpg", "template_16498.jpg"],
    "mothers day cards": ["template_10679.jpg", "template_17250.jpg"],
    "thank you cards": ["template_5635.jpg", "template_11911.jpg"],
    "greeting cards": ["template_6403.jpg", "template_11421.jpg"],
    anniversary: ["template_21529.jpg", "template_2742.jpg"],
    "teachers day": ["template_20908.jpg", "template_525.jpg"],
    "baby shower": ["template_10746.jpg", "template_208.jpg"],
    "chinese new year": ["template_9561.jpg", "template_15027.jpg"],
    easter: ["template_23787.jpg", "template_7253.jpg"],
    farewell: ["template_6186.jpg", "template_7229.jpg"],
    "new year": ["template_14294.jpg", "template_7624.jpg"],
    "good luck": ["template_22384.jpg", "template_10243.jpg"],
    photo: ["template_10036.jpg", "template_23711.jpg"],
    "4th of july": ["template_2109.jpg", "template_22811.jpg"],
    "pet sympathy": ["template_23546.jpg", "template_20758.jpg"],
    "thinking of you": ["template_18054.jpg", "template_16633.jpg"],
    "save the date": ["template_9670.jpg", "template_10548.jpg"],
    wedding: ["template_2747.jpg", "template_15008.jpg"],
    baptism: ["template_10790.jpg", "template_7826.jpg"],
    "st patricks day": ["template_534.jpg", "template_18860.jpg"],
    "lunar new year": ["template_13235.jpg", "template_23139.jpg"],
    "grandparents day": ["template_22081.jpg", "template_17412.jpg"],
    "rosh hashanah": ["template_21498.jpg", "template_87.jpg"],
    appointment: ["template_10550.jpg", "template_16003.jpg"],
    "groundhog day": ["template_12386.jpg", "template_21774.jpg"],
    friendship: ["template_13884.jpg", "template_766.jpg"],
    love: ["template_11701.jpg", "template_12538.jpg"],
    retirement: ["template_6145.jpg", "template_5740.jpg"],
    ramadan: ["template_1987.jpg", "template_5175.jpg"],
    welcome: ["template_20161.jpg", "template_11815.jpg"],
    engagement: ["template_8308.jpg", "template_1531.jpg"],
  },
  catalogs: {
    course: ["template_23125.jpg", "template_6421.jpg"],
    furniture: ["template_23380.jpg", "template_19961.jpg"],
    fashion: ["template_16019.jpg", "template_22074.jpg"],
    product: ["template_13880.jpg", "template_18687.jpg"],
    jewelry: ["template_8268.jpg", "template_17998.jpg"],
    business: ["template_5877.jpg", "template_16855.jpg"],
    "home decor": ["template_18161.jpg", "template_9932.jpg"],
    service: ["template_14858.jpg", "template_12969.jpg"],
  },
  certificates: {
    completion: ["template_23025.jpg", "template_15696.jpg"],
    recognition: ["template_3907.jpg", "template_12894.jpg"],
    sport: ["template_17524.jpg", "template_23878.jpg"],
    membership: ["template_3456.jpg", "template_4484.jpg"],
    "tooth fairy": ["template_1045.jpg", "template_6082.jpg"],
    academic: ["template_19056.jpg", "template_14644.jpg"],
    achievement: ["template_16270.jpg", "template_16987.jpg"],
    internship: ["template_12851.jpg", "template_23793.jpg"],
    appreciation: ["template_15646.jpg", "template_15971.jpg"],
    attendance: ["template_19919.jpg", "template_3746.jpg"],
    award: ["template_20260.jpg", "template_23696.jpg"],
    course: ["template_5283.jpg", "template_23080.jpg"],
    diploma: ["template_11818.jpg", "template_11387.jpg"],
    funny: ["template_12186.jpg", "template_5823.jpg"],
    participation: ["template_656.jpg", "template_19487.jpg"],
    professional: ["template_15877.jpg", "template_8695.jpg"],
    project: ["template_2087.jpg", "template_22561.jpg"],
    school: ["template_16988.jpg", "template_23107.jpg"],
    simple: ["template_15233.jpg", "template_7547.jpg"],
    student: ["template_14594.jpg", "template_17654.jpg"],
    training: ["template_8456.jpg", "template_3273.jpg"],
    medical: ["template_6558.jpg", "template_8340.jpg"],
    work: ["template_11785.jpg", "template_14624.jpg"],
    workshop: ["template_8940.jpg", "template_9147.jpg"],
    birthday: ["template_18627.jpg", "template_19369.jpg"],
    "employee of the month": ["template_2919.jpg", "template_14573.jpg"],
  },
  charts: {
    "mixed charts": ["template_12913.jpg", "template_19124.jpg"],
    "single chart": ["template_4533.jpg", "template_23661.jpg"],
    wordcloud: ["template_22617.jpg", "template_3607.jpg"],
    "scatterplot chart": ["template_8416.jpg", "template_5071.jpg"],
    "pie chart": ["template_19098.jpg", "template_4195.jpg"],
    "area chart": ["template_19367.jpg", "template_21350.jpg"],
    "line chart": ["template_20198.jpg", "template_1236.jpg"],
    editorial: ["template_2224.jpg", "template_18613.jpg"],
    "bubble chart": ["template_21933.jpg", "template_1953.jpg"],
    "stacked chart": ["template_14853.jpg", "template_9387.jpg"],
    "bar chart": ["template_14782.jpg", "template_19266.jpg"],
    gauge: ["template_3682.jpg", "template_21978.jpg"],
    funnel: ["template_16496.jpg", "template_20494.jpg"],
    pictogram: ["template_15416.jpg", "template_20175.jpg"],
    map: ["template_17867.jpg", "template_2800.jpg"],
    histogram: ["template_22533.jpg", "template_18022.jpg"],
    column: ["template_12867.jpg", "template_23511.jpg"],
    table: ["template_3732.jpg", "template_8052.jpg"],
    pareto: ["template_10080.jpg", "template_3389.jpg"],
  },
  checklist: {
    daily: ["template_9315.jpg", "template_19702.jpg"],
    work: ["template_31011", "template_31012"],
    "wedding checklist": ["template_12970.jpg", "template_15486.jpg"],
    "shopping checklist": ["template_13250.jpg", "template_15936.jpg"],
    cleaning: ["template_19756.jpg", "template_2856.jpg"],
  },
  collages: {
    photo: ["template_22441.jpg", "template_7399.jpg"],
    school: ["template_7653.jpg", "template_15762.jpg"],
    "mood boards": ["template_12311.jpg", "template_7774.jpg"],
    art: ["template_5254.jpg", "template_9656.jpg"],
    love: ["template_6709.jpg", "template_7619.jpg"],
    shape: ["template_14269.jpg", "template_7493.jpg"],
    music: ["template_5126.jpg", "template_13715.jpg"],
    "hair design": ["template_2264.jpg", "template_14930.jpg"],
    baby: ["template_9237.jpg", "template_15764.jpg"],
    "vision board": ["template_16723.jpg", "template_13946.jpg"],
    aesthetic: ["template_15839.jpg", "template_2457.jpg"],
    cool: ["template_8556.jpg", "template_6045.jpg"],
    "heart shaped": ["template_4072.jpg", "template_17765.jpg"],
    birthday: ["template_20736.jpg", "template_560.jpg"],
    holiday: ["template_13586.jpg", "template_5208.jpg"],
    wedding: ["template_16141.jpg", "template_4457.jpg"],
    travel: ["template_18536.jpg", "template_21900.jpg"],
    family: ["template_170.jpg", "template_14426.jpg"],
    "interior design": ["template_4353.jpg", "template_5372.jpg"],
    christmas: ["template_15948.jpg", "template_136.jpg"],
  },
  contracts: {
    termination: ["template_31014", "template_30141"],
    pet: ["template_30010", "template_30125"],
    nda: ["template_11132.jpg", "template_8688.jpg"],
    lease: ["template_7417.jpg", "template_30352"],
    investor: ["template_9721.jpg", "template_5120.jpg"],
    "purchase and sale": ["template_5848.jpg", "template_30624"],
    loan: ["template_175.jpg", "template_10779.jpg"],
    employment: ["template_30167", "template_30450"],
    photography: ["template_30459", "template_30365"],
    installation: ["template_30731", "template_30417"],
    cleaning: ["template_30617", "template_30665"],
    service: ["template_30641", "template_30500"],
    maintenance: ["template_30567", "template_30036"],
    "digital services": ["template_30152", "template_30110"],
    music: ["template_30396", "template_30572"],
    automobile: ["template_30362", "template_30061"],
    wedding: ["template_30016", "template_30177"],
    marketing: ["template_30009", "template_31015"],
    medical: ["template_30002", "template_30685"],
    behavior: ["template_30506", "template_30356"],
    business: ["template_30451", "template_30289"],
    rent: ["template_30694", "template_30114"],
    construction: ["template_30179", "template_30456"],
  },
  coupons: {
    "gift certificates": ["template_7381.jpg", "template_18167.jpg"],
    christmas: ["template_1450.jpg", "template_2593.jpg"],
    vouchers: ["template_21478.jpg", "template_599.jpg"],
    love: ["template_18878.jpg", "template_8201.jpg"],
  },
  diagrams: {
    er: ["template_2173.jpg", "template_6938.jpg"],
    block: ["template_9848.jpg", "template_8628.jpg"],
    spider: ["template_19642.jpg", "template_14907.jpg"],
    "5 whys": ["template_8099.jpg", "template_10965.jpg"],
    "circle map": ["template_14800.jpg", "template_12676.jpg"],
    "circular flow": ["template_1780.jpg", "template_2871.jpg"],
    ecomap: ["template_1056.jpg", "template_1936.jpg"],
    "empathy map": ["template_21758.jpg", "template_12922.jpg"],
    "family tree": ["template_8752.jpg", "template_19005.jpg"],
    "impact effort matrix": ["template_4742.jpg", "template_22297.jpg"],
    "logic model": ["template_22893.jpg", "template_15408.jpg"],
    "pugh matrix": ["template_6625.jpg", "template_883.jpg"],
    raci: ["template_5812.jpg", "template_13258.jpg"],
    "roles responsibilities framework": ["template_10449.jpg", "template_23401.jpg"],
    sequence: ["template_17820.jpg", "template_19728.jpg"],
    sipoc: ["template_3238.jpg", "template_13721.jpg"],
    stakeholder: ["template_12047.jpg", "template_20748.jpg"],
    storyboard: ["template_12904.jpg", "template_19340.jpg"],
    "t chart": ["template_23348.jpg", "template_539.jpg"],
    tree: ["template_23263.jpg", "template_15022.jpg"],
    "user persona": ["template_17430.jpg", "template_20427.jpg"],
    "comparison chart": ["template_18259.jpg", "template_19279.jpg"],
    cladogram: ["template_14788.jpg", "template_8420.jpg"],
    kinship: ["template_848.jpg", "template_10117.jpg"],
    "food web": ["template_5733.jpg", "template_3349.jpg"],
    state: ["template_13116.jpg", "template_7779.jpg"],
    pert: ["template_19145.jpg", "template_9787.jpg"],
    "work breakdown structure": ["template_5653.jpg", "template_23780.jpg"],
    network: ["template_16331.jpg", "template_16427.jpg"],
    "dichotomous key": ["template_21407.jpg", "template_22190.jpg"],
    "data flow": ["template_21995.jpg", "template_1396.jpg"],
    genogram: ["template_22338.jpg", "template_22937.jpg"],
    "site map": ["template_14890.jpg", "template_11565.jpg"],
    "swot analysis": ["template_12528.jpg", "template_8999.jpg"],
    "use case": ["template_10601.jpg", "template_16533.jpg"],
    venn: ["template_20112.jpg", "template_1021.jpg"],
    wireframe: ["template_9317.jpg", "template_18029.jpg"],
    flow: ["template_10718.jpg", "template_14520.jpg"],
    gantt: ["template_12113.jpg", "template_6071.jpg"],
    organizational: ["template_9837.jpg", "template_5053.jpg"],
    fishbone: ["template_2798.jpg", "template_13879.jpg"],
  },
  ebooks: {
    "simple ebooks": ["template_1672.jpg", "template_414.jpg"],
    "ebook covers": ["template_6412.jpg", "template_5335.jpg"],
  },
  education: {
    "lesson plans": ["template_7416.jpg", "template_890.jpg"],
    "teaching aids": ["template_9511.jpg", "template_5900.jpg"],
  },
  estimates: {
    repair: ["template_2028.jpg", "template_7589.jpg"],
    landscape: ["template_23551.jpg", "template_11316.jpg"],
    painting: ["template_6799.jpg", "template_11646.jpg"],
    construction: ["template_15140.jpg", "template_5705.jpg"],
    roof: ["template_3099.jpg", "template_4532.jpg"],
    cost: ["template_31042", "template_31051"],
    medical: ["template_31043", "template_31052"],
    cleaning: ["template_31044", "template_31053"],
    remodel: ["template_31045", "template_18691.jpg"],
    service: ["template_31046", "template_31054"],
    "business and it": ["template_31047", "template_31055"],
    finance: ["template_31048", "template_31057"],
    marketing: ["template_31049", "template_31058"],
    photography: ["template_31050", "template_31059"],
  },
  flyers: {
    restaurant: ["template_12974.jpg", "template_14949.jpg"],
    "product flyers": ["template_212.jpg", "template_9416.jpg"],
    "business flyers": ["template_6453.jpg", "template_18155.jpg"],
    "event flyers": ["template_22460.jpg", "template_31207"],
    cleaning: ["template_13265.jpg", "template_17284.jpg"],
    "bake sale": ["template_14277.jpg", "template_21619.jpg"],
    fall: ["template_23033.jpg", "template_4750.jpg"],
    art: ["template_20214.jpg", "template_1199.jpg"],
    "happy hour": ["template_20078.jpg", "template_16735.jpg"],
    marketing: ["template_23097.jpg", "template_18529.jpg"],
    landscaping: ["template_16588.jpg", "template_11659.jpg"],
    class: ["template_18139.jpg", "template_12182.jpg"],
    concert: ["template_7240.jpg", "template_1184.jpg"],
    club: ["template_17890.jpg", "template_4460.jpg"],
    christmas: ["template_23228.jpg", "template_2296.jpg"],
    church: ["template_12488.jpg", "template_21436.jpg"],
    "car wash": ["template_20740.jpg", "template_4108.jpg"],
    fundraiser: ["template_21575.jpg", "template_8341.jpg"],
    advertising: ["template_15568.jpg", "template_2006.jpg"],
    band: ["template_10087.jpg", "template_3846.jpg"],
    "grand opening": ["template_13718.jpg", "template_13859.jpg"],
    halloween: ["template_12272.jpg", "template_9279.jpg"],
    fitness: ["template_8119.jpg", "template_8564.jpg"],
    "food drive": ["template_10281.jpg", "template_11562.jpg"],
    charity: ["template_13398.jpg", "template_9961.jpg"],
    music: ["template_6855.jpg", "template_8018.jpg"],
    "yard sale": ["template_6079.jpg", "template_10680.jpg"],
    computer: ["template_19620.jpg", "template_7827.jpg"],
    daycare: ["template_1452.jpg", "template_19787.jpg"],
    cupcake: ["template_4782.jpg", "template_14161.jpg"],
    "dog walker": ["template_12832.jpg", "template_22793.jpg"],
    election: ["template_9161.jpg", "template_21721.jpg"],
    construction: ["template_23668.jpg", "template_18989.jpg"],
    photography: ["template_16662.jpg", "template_23664.jpg"],
    "mothers day": ["template_1211.jpg", "template_22356.jpg"],
    animal: ["template_7850.jpg", "template_8080.jpg"],
    workshop: ["template_31206", "template_22904.jpg"],
    health: ["template_14947.jpg", "template_1506.jpg"],
    holiday: ["template_4602.jpg", "template_17579.jpg"],
    "lost dog": ["template_4363.jpg", "template_22859.jpg"],
    birthday: ["template_22195.jpg", "template_20194.jpg"],
    babysitting: ["template_18901.jpg", "template_15164.jpg"],
    easter: ["template_12552.jpg", "template_19591.jpg"],
    "black friday": ["template_5493.jpg", "template_5767.jpg"],
    "real estate": ["template_22979.jpg", "template_2714.jpg"],
    wedding: ["template_21946.jpg", "template_1863.jpg"],
    thanksgiving: ["template_19904.jpg", "template_20478.jpg"],
    school: ["template_8121.jpg", "template_23347.jpg"],
    "summer camp": ["template_15109.jpg", "template_13831.jpg"],
    sports: ["template_16733.jpg", "template_13617.jpg"],
    tutor: ["template_11469.jpg", "template_3591.jpg"],
    sale: ["template_9457.jpg", "template_19580.jpg"],
    "talent show": ["template_23048.jpg", "template_12788.jpg"],
    party: ["template_21559.jpg", "template_5596.jpg"],
    modern: ["template_18271.jpg", "template_19263.jpg"],
    "valentines day": ["template_1781.jpg", "template_21938.jpg"],
    game: ["template_17150.jpg", "template_18765.jpg"],
    "hair salon": ["template_19463.jpg", "template_14684.jpg"],
    volunteer: ["template_209.jpg", "template_5796.jpg"],
  },
  forms: {
    booking: ["template_23822.jpg", "template_13196.jpg"],
    appointment: ["template_23010.jpg", "template_19248.jpg"],
    questionnaire: ["template_9254.jpg", "template_21526.jpg"],
    application: ["template_6828.jpg", "template_17372.jpg"],
    audit: ["template_16384.jpg", "template_8303.jpg"],
    banking: ["template_11603.jpg", "template_18313.jpg"],
    business: ["template_4992.jpg", "template_535.jpg"],
    consent: ["template_17970.jpg", "template_660.jpg"],
    construction: ["template_17003.jpg", "template_3558.jpg"],
    donation: ["template_14920.jpg", "template_14127.jpg"],
    "customer service": ["template_14352.jpg", "template_11927.jpg"],
    interview: ["template_21969.jpg", "template_6384.jpg"],
    medical: ["template_617.jpg", "template_21368.jpg"],
    sponsor: ["template_3650.jpg", "template_8451.jpg"],
    voting: ["template_14875.jpg", "template_19877.jpg"],
    abstract: ["template_14124.jpg", "template_8837.jpg"],
    "joint venture agreement": ["template_16684.jpg", "template_23250.jpg"],
    "pet service": ["template_17847.jpg", "template_20661.jpg"],
    petition: ["template_7932.jpg", "template_17476.jpg"],
    reservation: ["template_18403.jpg", "template_4120.jpg"],
    quiz: ["template_16091.jpg", "template_5131.jpg"],
    hr: ["template_7764.jpg", "template_755.jpg"],
    membership: ["template_21395.jpg", "template_7786.jpg"],
    "web design": ["template_6987.jpg", "template_17494.jpg"],
    survey: ["template_10342.jpg", "template_21520.jpg"],
    "lead generation": ["template_14629.jpg", "template_23127.jpg"],
    contractor: ["template_6753.jpg", "template_20631.jpg"],
    sports: ["template_16128.jpg", "template_23826.jpg"],
    services: ["template_16933.jpg", "template_19235.jpg"],
    enrollment: ["template_30055", "template_30163"],
    tracking: ["template_20192.jpg", "template_213.jpg"],
    subscription: ["template_5353.jpg", "template_915.jpg"],
    ecommerce: ["template_7013.jpg", "template_2000.jpg"],
    content: ["template_7208.jpg", "template_18176.jpg"],
    contact: ["template_23670.jpg", "template_19479.jpg"],
    personal: ["template_4497.jpg", "template_20335.jpg"],
    inspection: ["template_13065.jpg", "template_14142.jpg"],
    insurance: ["template_5277.jpg", "template_14026.jpg"],
    education: ["template_9804.jpg", "template_15915.jpg"],
    employment: ["template_20890.jpg", "template_2095.jpg"],
    evaluation: ["template_13237.jpg", "template_13349.jpg"],
    "event registration": ["template_22023.jpg", "template_14819.jpg"],
    feedback: ["template_9519.jpg", "template_18250.jpg"],
    health: ["template_4692.jpg", "template_14833.jpg"],
    request: ["template_19274.jpg", "template_11096.jpg"],
    "volunteer and community service": ["template_509.jpg", "template_9146.jpg"],
  },
  "human resources": {
    handbooks: ["template_18380.jpg", "template_13134.jpg"],
    "offer letters": ["template_7315.jpg", "template_4056.jpg"],
    itinerary: ["template_31219", "template_31220"],
  },
  infographics: {
    medical: ["template_16295.jpg", "template_23372.jpg"],
    space: ["template_15316.jpg", "template_4634.jpg"],
    list: ["template_20597.jpg", "template_11347.jpg"],
    timeline: ["template_21586.jpg", "template_6349.jpg"],
    "real estate": ["template_16147.jpg", "template_1616.jpg"],
    informational: ["template_20487.jpg", "template_11615.jpg"],
    nonprofit: ["template_1973.jpg", "template_8439.jpg"],
    process: ["template_6817.jpg", "template_2054.jpg"],
    "business infographic": ["template_4068.jpg", "template_31293"],
    "fun data": ["template_775.jpg", "template_2166.jpg"],
    statistical: ["template_1649.jpg", "template_2829.jpg"],
    comparison: ["template_17589.jpg", "template_15456.jpg"],
    animal: ["template_16280.jpg", "template_5684.jpg"],
    wine: ["template_4925.jpg", "template_10950.jpg"],
    travel: ["template_2842.jpg", "template_19681.jpg"],
    science: ["template_3588.jpg", "template_10529.jpg"],
    research: ["template_1414.jpg", "template_16404.jpg"],
    photography: ["template_7392.jpg", "template_18964.jpg"],
    people: ["template_17704.jpg", "template_3039.jpg"],
    "memorial day": ["template_9899.jpg", "template_11479.jpg"],
    "cinco de mayo": ["template_22288.jpg", "template_13627.jpg"],
    music: ["template_13391.jpg", "template_2862.jpg"],
    car: ["template_6149.jpg", "template_9526.jpg"],
    building: ["template_6099.jpg", "template_1984.jpg"],
    fun: ["template_12420.jpg", "template_9229.jpg"],
    art: ["template_7765.jpg", "template_10736.jpg"],
    tree: ["template_21022.jpg", "template_1909.jpg"],
    "self care": ["template_16706.jpg", "template_19774.jpg"],
    dogs: ["template_19137.jpg", "template_16473.jpg"],
    health: ["template_22134.jpg", "template_10486.jpg"],
    nutrition: ["template_22100.jpg", "template_15134.jpg"],
    marketing: ["template_10382.jpg", "template_19766.jpg"],
    history: ["template_23596.jpg", "template_19509.jpg"],
    sports: ["template_12105.jpg", "template_19829.jpg"],
    technology: ["template_10257.jpg", "template_5134.jpg"],
    "mental health": ["template_20989.jpg", "template_19524.jpg"],
    finance: ["template_17318.jpg", "template_22232.jpg"],
    safety: ["template_7432.jpg", "template_3742.jpg"],
    food: ["template_20649.jpg", "template_8213.jpg"],
    fitness: ["template_3310.jpg", "template_16514.jpg"],
    company: ["template_7747.jpg", "template_18617.jpg"],
    brain: ["template_4223.jpg", "template_3948.jpg"],
    water: ["template_3359.jpg", "template_18127.jpg"],
    "human resources": ["template_9336.jpg", "template_9110.jpg"],
    smoke: ["template_12007.jpg", "template_4641.jpg"],
    college: ["template_5891.jpg", "template_18760.jpg"],
    covid: ["template_11717.jpg", "template_10270.jpg"],
    chemistry: ["template_2050.jpg", "template_2312.jpg"],
    product: ["template_13433.jpg", "template_13217.jpg"],
    coffee: ["template_21132.jpg", "template_49.jpg"],
    "graphic design": ["template_19245.jpg", "template_23331.jpg"],
    circle: ["template_7449.jpg", "template_14845.jpg"],
    soccer: ["template_22740.jpg", "template_1016.jpg"],
    "black history month": ["template_22377.jpg", "template_19593.jpg"],
    success: ["template_1178.jpg", "template_76.jpg"],
    bee: ["template_8970.jpg", "template_1372.jpg"],
    teacher: ["template_14421.jpg", "template_14713.jpg"],
    christmas: ["template_7395.jpg", "template_9255.jpg"],
    house: ["template_12769.jpg", "template_5611.jpg"],
    halloween: ["template_20801.jpg", "template_1938.jpg"],
    education: ["template_16059.jpg", "template_6591.jpg"],
    vintage: ["template_13899.jpg", "template_14484.jpg"],
    university: ["template_14652.jpg", "template_8156.jpg"],
    event: ["template_21506.jpg", "template_479.jpg"],
    math: ["template_9469.jpg", "template_334.jpg"],
    winter: ["template_11040.jpg", "template_21757.jpg"],
    wedding: ["template_23686.jpg", "template_22581.jpg"],
    women: ["template_31230", "template_31262"],
    chocolate: ["template_31231", "template_31263"],
    fall: ["template_31232", "template_31264"],
    podcast: ["template_31233", "template_31265"],
    anime: ["template_31234", "template_31266"],
    charity: ["template_31235", "template_31267"],
    tea: ["template_31236", "template_31268"],
    city: ["template_31237", "template_31269"],
    hospital: ["template_31238", "template_31271"],
    fashion: ["template_31239", "template_31272"],
    baseball: ["template_31240", "template_31273"],
    puzzle: ["template_31241", "template_31274"],
    cloud: ["template_31242", "template_31275"],
    environment: ["template_31243", "template_31276"],
    law: ["template_31244", "template_31270"],
    gaming: ["template_31245", "template_31277"],
    dental: ["template_31246", "template_31278"],
    ocean: ["template_31247", "template_31279"],
    running: ["template_31248", "template_31280"],
    film: ["template_31249", "template_31281"],
    office: ["template_31250", "template_31282"],
    cartoon: ["template_31251", "template_31283"],
    horror: ["template_31252", "template_31284"],
    yoga: ["template_31253", "template_31285"],
    pet: ["template_31254", "template_31286"],
    physics: ["template_31256", "template_31287"],
    mountain: ["template_31257", "template_31288"],
    cooking: ["template_31258", "template_31289"],
    computer: ["template_31259", "template_31290"],
    library: ["template_31260", "template_31291"],
    geographic: ["template_2299.jpg", "template_4819.jpg"],
    tutorials: ["template_13006.jpg", "template_22672.jpg"],
    creative: ["template_9048.jpg", "template_14837.jpg"],
    school: ["template_11171.jpg", "template_16409.jpg"],
  },
  invitations: {
    "save the date invitations": ["template_14658.jpg", "template_10884.jpg"],
    "party invitations": ["template_4007.jpg", "template_13509.jpg"],
    "holiday invitations": ["template_14806.jpg", "template_16847.jpg"],
    "bridal shower invitations": ["template_8279.jpg", "template_10722.jpg"],
    "wedding invitations": ["template_4091.jpg", "template_4631.jpg"],
    "baby shower invitations": ["template_5215.jpg", "template_3689.jpg"],
    "birthday invitations": ["template_10363.jpg", "template_15277.jpg"],
    sleepover: ["template_23836.jpg", "template_19432.jpg"],
    brunch: ["template_20003.jpg", "template_18763.jpg"],
    luau: ["template_15333.jpg", "template_335.jpg"],
    "company event": ["template_16454.jpg", "template_11332.jpg"],
    "emoji party": ["template_2336.jpg", "template_22145.jpg"],
    butterfly: ["template_19875.jpg", "template_10272.jpg"],
    luncheon: ["template_4836.jpg", "template_6944.jpg"],
    easter: ["template_9776.jpg", "template_978.jpg"],
    "21st birthday": ["template_4099.jpg", "template_14489.jpg"],
    "surprise party": ["template_4961.jpg", "template_1114.jpg"],
    "open house": ["template_22120.jpg", "template_22687.jpg"],
    "50th anniversary": ["template_1166.jpg", "template_17917.jpg"],
    "18th birthday": ["template_6978.jpg", "template_23745.jpg"],
    "movie night": ["template_19894.jpg", "template_19896.jpg"],
    "game night": ["template_6512.jpg", "template_17450.jpg"],
    "boarding pass": ["template_3684.jpg", "template_17909.jpg"],
    "30th birthday": ["template_20456.jpg", "template_925.jpg"],
    "60th birthday": ["template_22706.jpg", "template_13317.jpg"],
    "kids party": ["template_17981.jpg", "template_19736.jpg"],
    confirmation: ["template_9094.jpg", "template_9095.jpg"],
    formal: ["template_3409.jpg", "template_11866.jpg"],
    photo: ["template_14140.jpg", "template_3005.jpg"],
    church: ["template_17720.jpg", "template_2817.jpg"],
    picnic: ["template_16998.jpg", "template_21050.jpg"],
    masquerade: ["template_11279.jpg", "template_19042.jpg"],
    "engagement party": ["template_19138.jpg", "template_10107.jpg"],
    gala: ["template_18201.jpg", "template_3398.jpg"],
    "pajama party": ["template_2454.jpg", "template_1723.jpg"],
    "bridal tea": ["template_12187.jpg", "template_6893.jpg"],
    circus: ["template_8169.jpg", "template_1923.jpg"],
    cocktail: ["template_3128.jpg", "template_16242.jpg"],
    "art party": ["template_13512.jpg", "template_19260.jpg"],
    bachelorette: ["template_4179.jpg", "template_15808.jpg"],
    "lingerie shower": ["template_15289.jpg", "template_16642.jpg"],
    "first communion": ["template_3859.jpg", "template_4927.jpg"],
    casino: ["template_9577.jpg", "template_9509.jpg"],
    bowling: ["template_14451.jpg", "template_13784.jpg"],
    "rehearsal dinner": ["template_15287.jpg", "template_23754.jpg"],
    football: ["template_8948.jpg", "template_16955.jpg"],
    "70th birthday": ["template_6446.jpg", "template_17105.jpg"],
    "40th birthday": ["template_9652.jpg", "template_18151.jpg"],
    "black tie": ["template_17089.jpg", "template_329.jpg"],
    banquet: ["template_20082.jpg", "template_17827.jpg"],
    "beach birthday": ["template_9481.jpg", "template_16543.jpg"],
    bridesmaid: ["template_15340.jpg", "template_1220.jpg"],
    "baseball party": ["template_23301.jpg", "template_11381.jpg"],
    rainbow: ["template_7093.jpg", "template_2860.jpg"],
    "block party": ["template_10459.jpg", "template_17327.jpg"],
    "happy hour": ["template_3341.jpg", "template_22563.jpg"],
    diwali: ["template_2583.jpg", "template_18160.jpg"],
    gatsby: ["template_13930.jpg", "template_20783.jpg"],
    "get together": ["template_10870.jpg", "template_3153.jpg"],
    superhero: ["template_20850.jpg", "template_14116.jpg"],
    sports: ["template_17605.jpg", "template_9140.jpg"],
    "awards night": ["template_6127.jpg", "template_17935.jpg"],
    vintage: ["template_16572.jpg", "template_14716.jpg"],
    "halloween birthday": ["template_12181.jpg", "template_12854.jpg"],
    nautical: ["template_19258.jpg", "template_6103.jpg"],
    "mardi gras": ["template_2560.jpg", "template_11462.jpg"],
    "wine tasting": ["template_21343.jpg", "template_21341.jpg"],
    groomsman: ["template_5415.jpg", "template_4471.jpg"],
    "business meeting": ["template_19205.jpg", "template_8475.jpg"],
    hollywood: ["template_4374.jpg", "template_16798.jpg"],
    "costume party": ["template_23183.jpg", "template_22287.jpg"],
    "celebration of life": ["template_8287.jpg", "template_164.jpg"],
    "sweet 16": ["template_22289.jpg", "template_12844.jpg"],
    graduation: ["template_10946.jpg", "template_8132.jpg"],
    christmas: ["template_4874.jpg", "template_20692.jpg"],
    "pool party": ["template_4434.jpg", "template_23393.jpg"],
    housewarming: ["template_20366.jpg", "template_5432.jpg"],
    "retirement party": ["template_1571.jpg", "template_470.jpg"],
    halloween: ["template_23536.jpg", "template_18929.jpg"],
    "gender reveal": ["template_19754.jpg", "template_11119.jpg"],
    baptism: ["template_20782.jpg", "template_6131.jpg"],
    funeral: ["template_22708.jpg", "template_8421.jpg"],
    thanksgiving: ["template_8145.jpg", "template_10696.jpg"],
    quinceanera: ["template_2477.jpg", "template_9027.jpg"],
    "50th birthday": ["template_14405.jpg", "template_19275.jpg"],
    bbq: ["template_23222.jpg", "template_23458.jpg"],
    "tea party": ["template_21899.jpg", "template_18832.jpg"],
    fiesta: ["template_13507.jpg", "template_17950.jpg"],
    "1st birthday": ["template_3637.jpg", "template_10919.jpg"],
    princess: ["template_14468.jpg", "template_201.jpg"],
    "farewell party": ["template_11062.jpg", "template_18866.jpg"],
  },
  invoices: {
    business: ["template_12428.jpg", "template_4899.jpg"],
    consultation: ["template_19323.jpg", "template_20289.jpg"],
    cleaning: ["template_486.jpg", "template_21388.jpg"],
    commercial: ["template_21781.jpg", "template_6047.jpg"],
    service: ["template_10381.jpg", "template_7595.jpg"],
    "graphic design": ["template_5840.jpg", "template_22851.jpg"],
    contractor: ["template_19007.jpg", "template_2085.jpg"],
    freelance: ["template_3685.jpg", "template_1232.jpg"],
    rental: ["template_2590.jpg", "template_5033.jpg"],
    artist: ["template_23005.jpg", "template_2610.jpg"],
    "self employed": ["template_12209.jpg", "template_10280.jpg"],
    construction: ["template_19120.jpg", "template_6482.jpg"],
    catering: ["template_23093.jpg", "template_7.jpg"],
    royalty: ["template_11141.jpg", "template_14669.jpg"],
  },
  labels: {
    wine: ["template_22761.jpg", "template_11312.jpg"],
    beer: ["template_19846.jpg", "template_15776.jpg"],
    kitchen: ["template_22868.jpg", "template_15468.jpg"],
    storage: ["template_20899.jpg", "template_5385.jpg"],
    clothing: ["template_21842.jpg", "template_21183.jpg"],
    candle: ["template_7814.jpg", "template_18484.jpg"],
    address: ["template_19688.jpg", "template_20457.jpg"],
    wedding: ["template_3560.jpg", "template_18626.jpg"],
    soap: ["template_18143.jpg", "template_15622.jpg"],
    jar: ["template_11675.jpg", "template_9003.jpg"],
    product: ["template_31.jpg", "template_16686.jpg"],
    food: ["template_21669.jpg", "template_23698.jpg"],
    "water bottle": ["template_16815.jpg", "template_11556.jpg"],
    "lip balm": ["template_7492.jpg", "template_14816.jpg"],
  },
  layouts: {
    "": ["template_3413.jpg", "template_10649.jpg"],
  },
  letterheads: {
    "law firm": ["template_19458.jpg", "template_21120.jpg"],
    "business letterheads": ["template_16330.jpg", "template_10924.jpg"],
    "it and software": ["template_2811.jpg", "template_23121.jpg"],
    construction: ["template_4870.jpg", "template_2876.jpg"],
    creative: ["template_5843.jpg", "template_14830.jpg"],
    company: ["template_19985.jpg", "template_13868.jpg"],
    church: ["template_23096.jpg", "template_14083.jpg"],
    charity: ["template_2037.jpg", "template_11005.jpg"],
    university: ["template_11270.jpg", "template_3267.jpg"],
    personal: ["template_7339.jpg", "template_5213.jpg"],
    hospital: ["template_967.jpg", "template_21187.jpg"],
  },
  letters: {
    "letter of recommendation": ["template_12566.jpg", "template_12375.jpg"],
    promotion: ["template_15361.jpg", "template_23855.jpg"],
    "salary increase": ["template_6162.jpg", "template_10985.jpg"],
    sales: ["template_12064.jpg", "template_21643.jpg"],
    sponsorship: ["template_15751.jpg", "template_276.jpg"],
    termination: ["template_18575.jpg", "template_4901.jpg"],
    transmittal: ["template_14452.jpg", "template_17045.jpg"],
    offer: ["template_16550.jpg", "template_21705.jpg"],
  },
  logos: {
    "creative logo": ["template_1545.jpg", "template_3342.jpg"],
    "business logo": ["template_3292.jpg", "template_881.jpg"],
  },
  "magazine covers": {
    food: ["template_1757.jpg", "template_4245.jpg"],
    gossip: ["template_22282.jpg", "template_6633.jpg"],
    car: ["template_20772.jpg", "template_9823.jpg"],
    travel: ["template_1110.jpg", "template_16911.jpg"],
    sports: ["template_10166.jpg", "template_20211.jpg"],
    teen: ["template_2547.jpg", "template_18543.jpg"],
    wedding: ["template_1160.jpg", "template_22634.jpg"],
    fashion: ["template_6266.jpg", "template_23843.jpg"],
  },
  menus: {
    "restaurant menus": ["template_19016.jpg", "template_7815.jpg"],
    halloween: ["template_12686.jpg", "template_4308.jpg"],
    dessert: ["template_2678.jpg", "template_22552.jpg"],
    french: ["template_10113.jpg", "template_10407.jpg"],
    chinese: ["template_19047.jpg", "template_7095.jpg"],
    bbq: ["template_13.jpg", "template_14413.jpg"],
    cocktail: ["template_20360.jpg", "template_23271.jpg"],
    football: ["template_6526.jpg", "template_23336.jpg"],
    "take out": ["template_1108.jpg", "template_5675.jpg"],
    diner: ["template_21658.jpg", "template_2643.jpg"],
    "ice cream": ["template_15013.jpg", "template_6263.jpg"],
    lunch: ["template_10179.jpg", "template_23027.jpg"],
    breakfast: ["template_22629.jpg", "template_13532.jpg"],
    wine: ["template_20999.jpg", "template_20040.jpg"],
    italian: ["template_20452.jpg", "template_18721.jpg"],
    "coffee shop": ["template_1605.jpg", "template_23477.jpg"],
    "food truck": ["template_833.jpg", "template_14038.jpg"],
    catering: ["template_9199.jpg", "template_17361.jpg"],
    seafood: ["template_15872.jpg", "template_8374.jpg"],
    spa: ["template_7037.jpg", "template_18203.jpg"],
    pub: ["template_8697.jpg", "template_16219.jpg"],
    pizza: ["template_3285.jpg", "template_12143.jpg"],
    burger: ["template_12782.jpg", "template_5572.jpg"],
    simple: ["template_14838.jpg", "template_14815.jpg"],
    fancy: ["template_6293.jpg", "template_1219.jpg"],
    cafe: ["template_4282.jpg", "template_21662.jpg"],
    monthly: ["template_12794.jpg", "template_16460.jpg"],
    kids: ["template_20822.jpg", "template_1047.jpg"],
    bakery: ["template_22815.jpg", "template_2615.jpg"],
    thanksgiving: ["template_13906.jpg", "template_11834.jpg"],
    mexican: ["template_18698.jpg", "template_13364.jpg"],
    background: ["template_14952.jpg", "template_17056.jpg"],
    drink: ["template_14320.jpg", "template_13953.jpg"],
    wedding: ["template_5498.jpg", "template_19297.jpg"],
    "dinner party": ["template_18490.jpg", "template_1129.jpg"],
    bar: ["template_10252.jpg", "template_5833.jpg"],
  },
  "mind maps": {
    "simple mind map": ["template_1788.jpg", "template_9540.jpg"],
    "customer journey": ["template_20586.jpg", "template_10300.jpg"],
    "business mind map": ["template_7181.jpg", "template_1572.jpg"],
    concept: ["template_3970.jpg", "template_8704.jpg"],
    marketing: ["template_8506.jpg", "template_18571.jpg"],
    product: ["template_1085.jpg", "template_1103.jpg"],
    brainstorm: ["template_15595.jpg", "template_22411.jpg"],
    "bubble map": ["template_16302.jpg", "template_15938.jpg"],
    illustration: ["template_10752.jpg", "template_9853.jpg"],
    process: ["template_11150.jpg", "template_7705.jpg"],
    company: ["template_17587.jpg", "template_3263.jpg"],
    corporate: ["template_21283.jpg", "template_14894.jpg"],
    startup: ["template_1130.jpg", "template_14544.jpg"],
    pink: ["template_21712.jpg", "template_17301.jpg"],
  },
  newsletters: {
    "school newsletter": ["template_4253.jpg", "template_15186.jpg"],
    "real estate": ["template_9585.jpg", "template_8383.jpg"],
    "event newsletter": ["template_6549.jpg", "template_4329.jpg"],
    "holiday newsletter": ["template_8610.jpg", "template_22424.jpg"],
    fashion: ["template_9173.jpg", "template_7914.jpg"],
    family: ["template_11166.jpg", "template_7806.jpg"],
    classroom: ["template_6300.jpg", "template_15467.jpg"],
    church: ["template_12622.jpg", "template_6140.jpg"],
    preschool: ["template_9014.jpg", "template_5912.jpg"],
    sales: ["template_3914.jpg", "template_6218.jpg"],
    "deals and offers": ["template_8864.jpg", "template_22035.jpg"],
    welcome: ["template_17999.jpg", "template_13252.jpg"],
    promotional: ["template_21988.jpg", "template_7433.jpg"],
    "black friday": ["template_1787.jpg", "template_6213.jpg"],
    "product launch": ["template_3912.jpg", "template_22913.jpg"],
    anniversary: ["template_8057.jpg", "template_22478.jpg"],
    "sign up": ["template_8457.jpg", "template_9588.jpg"],
    "updates and news": ["template_20863.jpg", "template_19190.jpg"],
    saas: ["template_14709.jpg", "template_21059.jpg"],
    services: ["template_9591.jpg", "template_13014.jpg"],
    photography: ["template_2833.jpg", "template_6462.jpg"],
    corporate: ["template_23819.jpg", "template_3122.jpg"],
    education: ["template_1035.jpg", "template_7590.jpg"],
    "media and publishing": ["template_16663.jpg", "template_13340.jpg"],
    travel: ["template_4243.jpg", "template_9015.jpg"],
    announcements: ["template_20119.jpg", "template_999.jpg"],
    b2b: ["template_13117.jpg", "template_20611.jpg"],
    b2c: ["template_3552.jpg", "template_19300.jpg"],
    halloween: ["template_10137.jpg", "template_13750.jpg"],
    "email newsletter": ["template_31577", "template_31578"],
    company: ["template_16038.jpg", "template_238.jpg"],
    healthcare: ["template_15477.jpg", "template_10481.jpg"],
    "food and culinary": ["template_2933.jpg", "template_23090.jpg"],
    "financial service": ["template_12080.jpg", "template_5727.jpg"],
    automotive: ["template_20640.jpg", "template_8051.jpg"],
    curated: ["template_2417.jpg", "template_22691.jpg"],
    reporting: ["template_18554.jpg", "template_11196.jpg"],
    marketing: ["template_4860.jpg", "template_23882.jpg"],
    "e commerce": ["template_11547.jpg", "template_8599.jpg"],
    christmas: ["template_12584.jpg", "template_18973.jpg"],
    "event posters": ["template_31591"],
  },
  planners: {
    meal: ["template_13996.jpg", "template_19354.jpg"],
    personal: ["template_19815.jpg", "template_31654"],
    finance: ["template_22714.jpg", "template_20423.jpg"],
    cute: ["template_3003.jpg", "template_8558.jpg"],
    aesthetic: ["template_14441.jpg", "template_7411.jpg"],
  },
  plans: {
    sales: ["template_19909.jpg", "template_4520.jpg"],
    marketing: ["template_21761.jpg", "template_19307.jpg"],
    business: ["template_18345.jpg", "template_12466.jpg"],
    project: ["template_13518.jpg", "template_18041.jpg"],
    "professional development": ["template_23820.jpg", "template_13464.jpg"],
    gantt: ["template_20694.jpg", "template_22329.jpg"],
    "lesson plan": ["template_3457.jpg", "template_6564.jpg"],
    work: ["template_22573.jpg", "template_4198.jpg"],
    strategic: ["template_17782.jpg", "template_19303.jpg"],
    "event planning": ["template_19461.jpg", "template_11864.jpg"],
    communication: ["template_15067.jpg", "template_20869.jpg"],
    training: ["template_20399.jpg", "template_18217.jpg"],
    staffing: ["template_13092.jpg", "template_23891.jpg"],
    financial: ["template_6522.jpg", "template_18388.jpg"],
    "corrective action": ["template_10172.jpg", "template_20745.jpg"],
    "performance improvement": ["template_23190.jpg", "template_20090.jpg"],
    "project communication": ["template_21592.jpg", "template_11773.jpg"],
    unit: ["template_18057.jpg", "template_1609.jpg"],
    implementation: ["template_3281.jpg", "template_17874.jpg"],
    "ux research": ["template_1500.jpg", "template_9033.jpg"],
    behavior: ["template_10161.jpg", "template_1026.jpg"],
    "classroom management": ["template_20780.jpg", "template_16865.jpg"],
    budget: ["template_6782.jpg", "template_21508.jpg"],
    "small business": ["template_1087.jpg", "template_10836.jpg"],
    payment: ["template_468.jpg", "template_15564.jpg"],
    onboarding: ["template_17518.jpg", "template_14417.jpg"],
    test: ["template_2947.jpg", "template_20931.jpg"],
    resource: ["template_12473.jpg", "template_2101.jpg"],
    action: ["template_21176.jpg", "template_16835.jpg"],
    account: ["template_2721.jpg", "template_829.jpg"],
    career: ["template_13978.jpg", "template_13781.jpg"],
    operational: ["template_16052.jpg", "template_13966.jpg"],
    research: ["template_11356.jpg", "template_8339.jpg"],
    qa: ["template_15108.jpg", "template_13015.jpg"],
    recruiting: ["template_1237.jpg", "template_13603.jpg"],
    procurement: ["template_2478.jpg", "template_1370.jpg"],
    "succession planning": ["template_31655", "template_31656"],
  },
  postcards: {
    "real estate": ["template_19614.jpg", "template_18067.jpg"],
    business: ["template_5605.jpg", "template_23499.jpg"],
    "save the date": ["template_457.jpg", "template_7804.jpg"],
    "direct mail": ["template_455.jpg", "template_10890.jpg"],
    birthday: ["template_1289.jpg", "template_3123.jpg"],
    travel: ["template_12703.jpg", "template_7611.jpg"],
    funny: ["template_9324.jpg", "template_16058.jpg"],
    love: ["template_12095.jpg", "template_14611.jpg"],
    "mothers day": ["template_18545.jpg", "template_6402.jpg"],
    "business thank you": ["template_19521.jpg", "template_18755.jpg"],
    "thank you": ["template_1990.jpg", "template_10596.jpg"],
    marketing: ["template_13099.jpg", "template_17698.jpg"],
  },
  posters: {
    jazz: ["template_19074.jpg"],
    advertising: ["template_9644.jpg", "template_17171.jpg"],
    "classroom rules": ["template_8776.jpg", "template_21687.jpg"],
    "drug awareness": ["template_5320.jpg", "template_17754.jpg"],
    "pro choice": ["template_12605.jpg", "template_11317.jpg"],
    recycling: ["template_16024.jpg", "template_5654.jpg"],
    "hiv-aids": ["template_11184.jpg"],
    environment: ["template_22448.jpg", "template_14943.jpg"],
    movie: ["template_10031.jpg", "template_945.jpg"],
    "event posters": ["template_15701.jpg", "template_3913.jpg"],
    gym: ["template_16486.jpg", "template_5968.jpg"],
    hockey: ["template_13520.jpg", "template_17176.jpg"],
    homelessness: ["template_4636.jpg", "template_19935.jpg"],
    kids: ["template_22541.jpg", "template_12652.jpg"],
    love: ["template_13755.jpg", "template_9270.jpg"],
    poverty: ["template_1460.jpg", "template_1086.jpg"],
    "earth day": ["template_270.jpg", "template_12651.jpg"],
    business: ["template_31665", "template_31671"],
    "mental health": ["template_31666", "template_1817.jpg"],
    "holiday posters": ["template_31667", "template_31672"],
    "gay rights": ["template_31668", "template_5395.jpg"],
    educational: ["template_31669", "template_31673"],
    "scientific posters": ["template_31670", "template_1850.jpg"],
    "black and white": ["template_11374.jpg", "template_4094.jpg"],
    birthday: ["template_21098.jpg", "template_16317.jpg"],
    "car wash": ["template_14741.jpg", "template_5471.jpg"],
    music: ["template_2204.jpg", "template_3604.jpg"],
    campaign: ["template_19018.jpg", "template_12054.jpg"],
    graduation: ["template_21993.jpg", "template_11301.jpg"],
    coronavirus: ["template_21323.jpg", "template_1100.jpg"],
    motivational: ["template_12369.jpg", "template_13120.jpg"],
    "lost dog": ["template_14638.jpg", "template_9592.jpg"],
    wanted: ["template_8455.jpg", "template_8160.jpg"],
    "climate change": ["template_17588.jpg", "template_5044.jpg"],
    "anti bullying": ["template_15621.jpg", "template_13146.jpg"],
    fun: ["template_18553.jpg", "template_23235.jpg"],
    halloween: ["template_21510.jpg", "template_13522.jpg"],
    concert: ["template_16716.jpg", "template_11858.jpg"],
    sports: ["template_7447.jpg", "template_16296.jpg"],
    dance: ["template_17211.jpg", "template_14698.jpg"],
    abstract: ["template_13394.jpg", "template_2040.jpg"],
    "domestic violence": ["template_9999.jpg", "template_22749.jpg"],
    school: ["template_14297.jpg", "template_9832.jpg"],
    photo: ["template_17974.jpg", "template_16991.jpg"],
    sale: ["template_16156.jpg", "template_3564.jpg"],
    festival: ["template_5314.jpg", "template_13254.jpg"],
    "world malaria day": ["template_15818.jpg", "template_398.jpg"],
    "valentines day": ["template_15439.jpg", "template_1292.jpg"],
    cute: ["template_19041.jpg", "template_20947.jpg"],
    travel: ["template_13574.jpg", "template_9854.jpg"],
    soccer: ["template_17337.jpg", "template_9176.jpg"],
    typographic: ["template_302.jpg", "template_14247.jpg"],
    conference: ["template_21926.jpg", "template_21450.jpg"],
    "science fair": ["template_11713.jpg", "template_8694.jpg"],
    "world blood donor day": ["template_21087.jpg", "template_5861.jpg"],
    "human rights": ["template_7459.jpg", "template_2286.jpg"],
    "family tree": ["template_15228.jpg", "template_16993.jpg"],
    math: ["template_1756.jpg", "template_6889.jpg"],
    college: ["template_12313.jpg", "template_15226.jpg"],
    friendship: ["template_5510.jpg", "template_23585.jpg"],
    "world no tobacco day": ["template_2234.jpg", "template_8937.jpg"],
    "fathers day": ["template_17622.jpg", "template_3360.jpg"],
    "food waste": ["template_8116.jpg", "template_19011.jpg"],
    aesthetic: ["template_17229.jpg", "template_11440.jpg"],
    congratulations: ["template_3338.jpg", "template_22043.jpg"],
    basketball: ["template_19140.jpg", "template_10700.jpg"],
    golf: ["template_4036.jpg", "template_3006.jpg"],
    vintage: ["template_13907.jpg", "template_156.jpg"],
    boxing: ["template_18816.jpg", "template_5030.jpg"],
    technology: ["template_8095.jpg", "template_7746.jpg"],
    reading: ["template_2471.jpg", "template_17759.jpg"],
    safety: ["template_15282.jpg", "template_19252.jpg"],
    "chinese new year": ["template_8274.jpg", "template_396.jpg"],
    "black friday": ["template_17749.jpg", "template_17137.jpg"],
    "gender equality": ["template_22242.jpg", "template_17043.jpg"],
    art: ["template_9960.jpg", "template_19643.jpg"],
    "about me": ["template_12957.jpg", "template_3805.jpg"],
    floral: ["template_528.jpg", "template_10637.jpg"],
    "human trafficking": ["template_20949.jpg", "template_15506.jpg"],
    "mothers day": ["template_12308.jpg", "template_21913.jpg"],
    "anti war": ["template_20583.jpg", "template_5954.jpg"],
    cheerleading: ["template_8816.jpg", "template_13773.jpg"],
    "farmers market": ["template_13876.jpg", "template_9376.jpg"],
    "photo collage": ["template_19143.jpg", "template_4209.jpg"],
    prom: ["template_20577.jpg", "template_19247.jpg"],
    cool: ["template_13365.jpg", "template_21067.jpg"],
    "classroom welcome": ["template_15431.jpg", "template_15518.jpg"],
    fundraising: ["template_1427.jpg", "template_9287.jpg"],
    easter: ["template_8211.jpg", "template_965.jpg"],
    volleyball: ["template_7940.jpg", "template_5147.jpg"],
    gaming: ["template_12467.jpg", "template_20885.jpg"],
    election: ["template_20236.jpg", "template_16306.jpg"],
    thanksgiving: ["template_1410.jpg", "template_19719.jpg"],
    club: ["template_17258.jpg", "template_14778.jpg"],
    "veterans day": ["template_13072.jpg", "template_7610.jpg"],
    recruitment: ["template_18620.jpg", "template_7698.jpg"],
    "missing person": ["template_996.jpg", "template_6038.jpg"],
    "breast cancer awareness": ["template_4515.jpg", "template_11322.jpg"],
    construction: ["template_2475.jpg", "template_13448.jpg"],
    "block party": ["template_3512.jpg", "template_6060.jpg"],
    medicine: ["template_20011.jpg", "template_15445.jpg"],
    infographic: ["template_11918.jpg", "template_15414.jpg"],
    diwali: ["template_1243.jpg", "template_7324.jpg"],
    church: ["template_13660.jpg", "template_13827.jpg"],
    horse: ["template_1992.jpg", "template_11264.jpg"],
    geometric: ["template_22730.jpg", "template_12031.jpg"],
    "hiv aids": ["template_6848.jpg", "template_10162.jpg"],
    "womens rights": ["template_31674", "template_10460.jpg"],
    "research posters": ["template_31675", "template_31678"],
    absract: ["template_1290.jpg"],
  },
  presentations: {
    "engaging presentations": ["template_12019.jpg", "template_14715.jpg"],
    keynote: ["template_3428.jpg", "template_3867.jpg"],
    "pitch decks": ["template_17456.jpg", "template_5795.jpg"],
    "simple presentations": ["template_22432.jpg", "template_15193.jpg"],
    "business presentations": ["template_6415.jpg", "template_7791.jpg"],
    christmas: ["template_10705.jpg", "template_14379.jpg"],
    cool: ["template_3062.jpg", "template_4066.jpg"],
    portfolio: ["template_4897.jpg", "template_18398.jpg"],
    education: ["template_10466.jpg", "template_17987.jpg"],
    "user persona": ["template_169.jpg", "template_18795.jpg"],
    "about me": ["template_20148.jpg", "template_3997.jpg"],
    math: ["template_1359.jpg", "template_15707.jpg"],
    animated: ["template_20757.jpg", "template_10804.jpg"],
    "brand guidelines": ["template_17117.jpg", "template_18140.jpg"],
    professional: ["template_4205.jpg", "template_15183.jpg"],
    church: ["template_14687.jpg", "template_21595.jpg"],
    game: ["template_5014.jpg", "template_20696.jpg"],
    research: ["template_1598.jpg", "template_9132.jpg"],
    brainstorm: ["template_6898.jpg", "template_17780.jpg"],
    halloween: ["template_22994.jpg", "template_15670.jpg"],
    leadership: ["template_18526.jpg", "template_23135.jpg"],
    "mothers day": ["template_18359.jpg", "template_30027"],
    "fathers day": ["template_8882.jpg", "template_1860.jpg"],
    "visual charts": ["template_18464.jpg", "template_23584.jpg"],
    pride: ["template_16226.jpg", "template_3828.jpg"],
    marketing: ["template_623.jpg", "template_5473.jpg"],
    brand: ["template_20439.jpg", "template_3269.jpg"],
    medical: ["template_9328.jpg", "template_16096.jpg"],
    project: ["template_9654.jpg", "template_15423.jpg"],
    modern: ["template_23896.jpg", "template_15620.jpg"],
    sales: ["template_19545.jpg", "template_6207.jpg"],
    sports: ["template_16696.jpg", "template_15520.jpg"],
    finances: ["template_7145.jpg", "template_5692.jpg"],
    music: ["template_17741.jpg", "template_16648.jpg"],
    science: ["template_5580.jpg", "template_9331.jpg"],
    timeline: ["template_4719.jpg", "template_20261.jpg"],
    "social media": ["template_9615.jpg", "template_2834.jpg"],
    technology: ["template_17493.jpg", "template_18506.jpg"],
    architecture: ["template_13142.jpg", "template_9857.jpg"],
    vintage: ["template_11245.jpg", "template_12275.jpg"],
    advertising: ["template_3820.jpg", "template_19219.jpg"],
    product: ["template_8813.jpg", "template_9939.jpg"],
    webinar: ["template_10057.jpg", "template_1136.jpg"],
    data: ["template_14998.jpg", "template_7019.jpg"],
    conference: ["template_409.jpg", "template_18396.jpg"],
    roadmap: ["template_5247.jpg", "template_3983.jpg"],
    "creative presentations": ["template_13724.jpg", "template_18828.jpg"],
    birthday: ["template_30667", "template_30436"],
    health: ["template_30629", "template_30077"],
    corporate: ["template_31735", "template_785.jpg"],
    agenda: ["template_30041", "template_18711.jpg"],
    company: ["template_30378", "template_31736"],
    quiz: ["template_30168", "template_22751.jpg"],
    wedding: ["template_21791.jpg", "template_11361.jpg"],
    listing: ["template_14042.jpg", "template_822.jpg"],
    "group project": ["template_21956.jpg", "template_9158.jpg"],
    "valentines day": ["template_9733.jpg", "template_7704.jpg"],
    graduation: ["template_6662.jpg", "template_2580.jpg"],
    scrapbook: ["template_13155.jpg", "template_3314.jpg"],
    "book report": ["template_17650.jpg", "template_8716.jpg"],
    art: ["template_14866.jpg", "template_11899.jpg"],
    minimalist: ["template_15798.jpg", "template_21892.jpg"],
    consulting: ["template_12710.jpg", "template_6960.jpg"],
    "business presntations": ["template_30698", "template_30322"],
  },
  "price lists": {
    photographer: ["template_18548.jpg", "template_897.jpg"],
    spa: ["template_14028.jpg", "template_873.jpg"],
    "car wash": ["template_15009.jpg", "template_19249.jpg"],
    "nail salon": ["template_3670.jpg", "template_23174.jpg"],
    handyman: ["template_6639.jpg", "template_23431.jpg"],
    cleaning: ["template_1513.jpg", "template_7651.jpg"],
    "car detailing": ["template_5041.jpg", "template_4020.jpg"],
    "beauty salon": ["template_1272.jpg", "template_21133.jpg"],
    retail: ["template_30054", "template_31744"],
    "personalized items": ["template_30644", "template_30711"],
    services: ["template_31740", "template_31745"],
    bakery: ["template_31741", "template_31746"],
    "event planning": ["template_31742", "template_31747"],
    "marketing and advertising": ["template_31743", "template_31748"],
  },
  proposals: {
    consulting: ["template_3918.jpg", "template_83.jpg"],
    "business proposals": ["template_16220.jpg", "template_30270"],
    marketing: ["template_10295.jpg", "template_30431"],
    events: ["template_18907.jpg", "template_7923.jpg"],
    "brand management": ["template_18962.jpg", "template_7490.jpg"],
    research: ["template_2473.jpg", "template_16151.jpg"],
    professional: ["template_52.jpg", "template_9923.jpg"],
    sponsorship: ["template_1869.jpg", "template_13175.jpg"],
    project: ["template_16975.jpg", "template_17393.jpg"],
    construction: ["template_8330.jpg", "template_4589.jpg"],
    service: ["template_838.jpg", "template_15666.jpg"],
    job: ["template_3434.jpg", "template_2986.jpg"],
    sales: ["template_544.jpg", "template_2910.jpg"],
    design: ["template_10246.jpg", "template_10146.jpg"],
    pricing: ["template_12563.jpg", "template_4672.jpg"],
    healthcare: ["template_2110.jpg", "template_14732.jpg"],
    grant: ["template_7105.jpg", "template_12465.jpg"],
    conference: ["template_19264.jpg", "template_12756.jpg"],
    informal: ["template_10138.jpg", "template_16846.jpg"],
    formal: ["template_6663.jpg", "template_3004.jpg"],
    external: ["template_741.jpg", "template_12763.jpg"],
    internal: ["template_18633.jpg", "template_9819.jpg"],
    unsolicited: ["template_6528.jpg", "template_1042.jpg"],
    solicited: ["template_5958.jpg", "template_16575.jpg"],
    software: ["template_4490.jpg", "template_12354.jpg"],
    telecommunication: ["template_5458.jpg", "template_22964.jpg"],
    legal: ["template_15831.jpg", "template_413.jpg"],
    "financial service": ["template_1224.jpg", "template_30144"],
    "government and public sector": ["template_23703.jpg", "template_3994.jpg"],
    technology: ["template_5802.jpg", "template_4906.jpg"],
    development: ["template_5613.jpg", "template_21278.jpg"],
    cleaning: ["template_21010.jpg", "template_16125.jpg"],
    "home service": ["template_12269.jpg", "template_11056.jpg"],
    "accounting and tax": ["template_2937.jpg", "template_3174.jpg"],
    price: ["template_23507.jpg", "template_1193.jpg"],
    bidding: ["template_10205.jpg", "template_23767.jpg"],
    training: ["template_15357.jpg", "template_15199.jpg"],
    partnership: ["template_5045.jpg", "template_10897.jpg"],
    corporate: ["template_22945.jpg", "template_17627.jpg"],
    branding: ["template_19127.jpg", "template_2542.jpg"],
    coaching: ["template_18640.jpg", "template_13291.jpg"],
    manufacturing: ["template_7285.jpg", "template_7670.jpg"],
    "real estate": ["template_9865.jpg", "template_4015.jpg"],
    education: ["template_30083", "template_19171.jpg"],
    business: ["template_31800"],
  },
  reports: {
    "monthly reports": ["template_23757.jpg", "template_31805"],
    consulting: ["template_16175.jpg", "template_21464.jpg"],
    "case study": ["template_11560.jpg", "template_16597.jpg"],
    "human resources": ["template_1066.jpg", "template_20881.jpg"],
    "quarterly reports": ["template_12061.jpg", "template_18209.jpg"],
    nonprofit: ["template_10951.jpg", "template_8668.jpg"],
    "annual reports": ["template_16394.jpg", "template_8272.jpg"],
    "executive summary": ["template_19590.jpg", "template_14289.jpg"],
    "survey results": ["template_6772.jpg", "template_22234.jpg"],
    charity: ["template_12633.jpg", "template_19437.jpg"],
    "project status": ["template_15604.jpg", "template_12975.jpg"],
    financial: ["template_4341.jpg", "template_18231.jpg"],
    expense: ["template_12635.jpg", "template_19230.jpg"],
    incident: ["template_11060.jpg", "template_23871.jpg"],
    sales: ["template_11259.jpg", "template_10308.jpg"],
    okr: ["template_18605.jpg", "template_11798.jpg"],
    progress: ["template_1446.jpg", "template_6781.jpg"],
    weekly: ["template_16896.jpg", "template_31803"],
    daily: ["template_16710.jpg", "template_12514.jpg"],
    data: ["template_18645.jpg", "template_16810.jpg"],
    production: ["template_5233.jpg", "template_5703.jpg"],
    kpi: ["template_15657.jpg", "template_13951.jpg"],
    audit: ["template_7918.jpg", "template_10086.jpg"],
    research: ["template_21361.jpg", "template_14560.jpg"],
    assessment: ["template_14526.jpg", "template_17106.jpg"],
    trend: ["template_16877.jpg", "template_21689.jpg"],
    construction: ["template_443.jpg", "template_18319.jpg"],
    corporate: ["template_2444.jpg", "template_23561.jpg"],
    analytical: ["template_1743.jpg", "template_11111.jpg"],
    book: ["template_30211", "template_30136"],
    "marketing reports": ["template_31801", "template_336.jpg"],
    school: ["template_31802", "template_14155.jpg"],
  },
  resumes: {
    "modern ": ["template_7541.jpg", "template_13762.jpg"],
    "professional resume": ["template_16880.jpg", "template_3873.jpg"],
    "cover letters": ["template_5921.jpg", "template_20612.jpg"],
    "student resume": ["template_10685.jpg", "template_16519.jpg"],
    "simple resume": ["template_4979.jpg", "template_4758.jpg"],
    teacher: ["template_11006.jpg", "template_60.jpg"],
    acting: ["template_11511.jpg", "template_9111.jpg"],
    colorful: ["template_4616.jpg", "template_21185.jpg"],
    corporate: ["template_8771.jpg", "template_13354.jpg"],
    college: ["template_23485.jpg", "template_11569.jpg"],
    minimalist: ["template_3897.jpg", "template_8229.jpg"],
    tech: ["template_15348.jpg", "template_681.jpg"],
    photo: ["template_7420.jpg", "template_23017.jpg"],
    creative: ["template_20767.jpg", "template_14025.jpg"],
    scholarship: ["template_8273.jpg", "template_4275.jpg"],
    infographics: ["template_8986.jpg", "template_23519.jpg"],
    academic: ["template_21184.jpg", "template_503.jpg"],
    babysitter: ["template_982.jpg", "template_16269.jpg"],
    "high school": ["template_13288.jpg", "template_9755.jpg"],
    "graphic design": ["template_16160.jpg", "template_2647.jpg"],
    modern: ["template_16407.jpg", "template_1583.jpg"],
  },
  roadmaps: {
    "marketing roadmaps": ["template_8606.jpg", "template_23685.jpg"],
    "product roadmaps": ["template_17280.jpg", "template_399.jpg"],
    sales: ["template_11099.jpg", "template_9082.jpg"],
    software: ["template_5888.jpg", "template_1011.jpg"],
    startup: ["template_15320.jpg", "template_7351.jpg"],
    strategic: ["template_9036.jpg", "template_733.jpg"],
    technology: ["template_22651.jpg", "template_20025.jpg"],
    website: ["template_21264.jpg", "template_19462.jpg"],
    it: ["template_11151.jpg", "template_233.jpg"],
    lean: ["template_2502.jpg", "template_17100.jpg"],
    portfolio: ["template_13562.jpg", "template_16241.jpg"],
    "product development": ["template_18324.jpg", "template_15905.jpg"],
    program: ["template_6696.jpg", "template_2225.jpg"],
    project: ["template_18034.jpg", "template_5293.jpg"],
    quality: ["template_12441.jpg", "template_12459.jpg"],
    release: ["template_5393.jpg", "template_20410.jpg"],
    scrum: ["template_4220.jpg", "template_18549.jpg"],
    "content marketing": ["template_20840.jpg", "template_9007.jpg"],
    "data strategy": ["template_17779.jpg", "template_8850.jpg"],
    business: ["template_7321.jpg", "template_19910.jpg"],
    "cloud strategy": ["template_10204.jpg", "template_3589.jpg"],
    application: ["template_13709.jpg", "template_9453.jpg"],
  },
  schedules: {
    "wedding schedules": ["template_7296.jpg", "template_16813.jpg"],
    "weekly schedules": ["template_14081.jpg", "template_5348.jpg"],
    "work schedules": ["template_9924.jpg", "template_7284.jpg"],
    employee: ["template_14963.jpg", "template_18556.jpg"],
  },
  "social media": {
    facebook: ["template_3438.jpg", "template_13132.jpg"],
    pinterest: ["template_17590.jpg", "template_15088.jpg"],
    snapchat: ["template_22117.jpg", "template_134.jpg"],
    twitter: ["template_6587.jpg", "template_13915.jpg"],
    instagram: ["template_9148.jpg", "template_30315"],
    linkedin: ["template_14989.jpg", "template_31876"],
  },
  "table of contents": {
    "employee handbooks": ["template_14341.jpg", "template_3418.jpg"],
    recipe: ["template_14301.jpg", "template_9466.jpg"],
    plans: ["template_13440.jpg", "template_13421.jpg"],
    report: ["template_15640.jpg", "template_23064.jpg"],
    books: ["template_2347.jpg", "template_11775.jpg"],
    "white papers": ["template_22203.jpg", "template_13806.jpg"],
  },
  "white papers": {
    technology: ["template_11928.jpg", "template_23167.jpg"],
    business: ["template_3426.jpg", "template_20538.jpg"],
    marketing: ["template_8188.jpg", "template_17459.jpg"],
    government: ["template_19876.jpg", "template_6677.jpg"],
  },
};
