// @ts-nocheck

import React, { useEffect, useRef } from "react";
import { useSVG } from "./SVGContext";
import { getCoordsInSvg } from "./utils/utils";

const DivWithEvents = ({ children, style, className, onMouseDown, onMouseMove, onMouseUp, onMouseOut, onTouchStart, onTouchMove, onTouchEnd, onTouchCancel, onContextMenu }) => {
  const { svgRef, zoomIn, zoomOut } = useSVG();
  const svgParentRef = useRef(null);
  let [lastTouch, setLastTouch] = React.useState(null);

  const handleWheel = (event) => {
    const xy = getCoordsInSvg(svgRef, {
      x: event.clientX,
      y: event.clientY,
    });
    event.preventDefault(); // Prevent default scrolling behavior

    if (event.ctrlKey || event.metaKey) {
      if (event.deltaY < 0) {
        zoomIn(xy);
      } else {
        zoomOut(xy);
      }
    }
  };
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      e.currentTarget.style.overflowY = "auto";
    } else {
      e.currentTarget.style.overflowY = "hidden";
    }
    onTouchStart(e);
    lastTouch = e.touches[0];
    setLastTouch(lastTouch);
  };

  const handleTouchMove = (e) => {
    // see GOTCHA #1
    lastTouch = e.touches[0];
    setLastTouch(lastTouch);
    onTouchMove(e);
  };

  const handleTouchEnd = (e) => {
    // GOTCHA #1: touchend does not propogate the location, see https://stackoverflow.com/a/46263868/968903
    e.currentTarget.style.overflowY = "hidden";

    e.touches = [lastTouch];
    onTouchEnd(e);

    lastTouch = null;
    setLastTouch(lastTouch);
  };

  useEffect(() => {
    // doing this manually and not like <svg onWheel={...}> because react makes wheel & touch events as passive listeners
    // where you cannot call e.preventDefault() https://stackoverflow.com/a/67258046

    svgParentRef.current.addEventListener("wheel", handleWheel);
    return () => {
      svgParentRef.current && svgParentRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div className={className} style={style} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp} onMouseOut={onMouseOut} onTouchCancel={onTouchCancel} onContextMenu={onContextMenu} ref={svgParentRef}>
      {children}
    </div>
  );
};

export default DivWithEvents;
