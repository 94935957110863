// @ts-nocheck

import { DARKTHEME, useSVG } from "./SVGContext";

import { Button, Dialog, Drawer, Link, List, ListItem, ListItemText, ThemeProvider, useMediaQuery } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import SVGContainer from "./SVGContainer";

import Pointer from "./usePointer";
import ShortcutHandler from "./ShortcutHandler";
import { FileUploader } from "./FileUploader";
import LeftPanel from "./LeftPanel";
import LoggedoutTemplates, { LoggedOutTemplates } from "./StackedTemplateCategories";
import { createDesignServer } from "./utils/ServerUtils";
import { MyDrawer } from "./MyDrawer";

const Editor = memo(() => {
  const { userInfo } = useSVG();
  const { settings, tool, setTool, fileManager, items, setItems, groups, selectedIds, svgRef, drawerOpen, setDrawerOpen, designId, setDesignId, designTitle, setDesignTitle, pages } = useSVG();
  const provider = useSVG();
  const appRef = useRef(null);
  const pointer = Pointer(provider);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const fileRef = useRef(null);
  const imageRef = useRef(null);
  const startFileUpload = (type) => {
    const ref = type === "place" ? imageRef : fileRef;
    ref.current.value = ""; // Without this, clicking it 2nd time doesn't work
    ref.current.click();
  };

  useEffect(() => {
    if (!tool) setTool(pointer);
  }, [tool]);

  useEffect(() => {
    setTool(pointer);
  }, []);

  // useEffect(() => {
  //   if (!designId) {
  //     createDesignServer({ pages, title: "Untitled Design" }, function (res) {
  //       setDesignId(res.design_id);
  //       setDesignTitle("Untitled Design");
  //     });
  //   }
  // }, [userInfo]);

  const shortcutHandler = ShortcutHandler({ startFileUpload });

  return (
    <>
      <div
        ref={appRef}
        tabIndex="-1"
        className="App"
        onKeyDown={(event) => shortcutHandler.handleKeyDown({ event, items, groups, selectedIds })}
        onKeyUp={shortcutHandler.handleKeyUp}
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "stretch",
          alignItems: "stretch",

          flexGrow: 1,
        }}
      >
        <ThemeProvider theme={DARKTHEME}>{!isMobile && <LeftPanel />}</ThemeProvider>
        <SVGContainer />
        <ThemeProvider theme={DARKTHEME}>{isMobile && <LeftPanel />} </ThemeProvider>
      </div>

      <FileUploader fileRef={fileRef} imageRef={imageRef} />

      <MyDrawer />
    </>
  );
});

export default Editor;
