// @ts-nocheck

function Zoom({ multiSelectBox }) {
  const scaleFactor = 1.05;

  function zoom(xy, zoomingIn, viewBox, setViewBox, width, height) {
    let x = width / 2,
      y = height / 2;

    if (multiSelectBox) {
      x = multiSelectBox.x;
      y = multiSelectBox.y;
    }
    x = viewBox.x;
    y = viewBox.y;

    const mult = zoomingIn ? scaleFactor : 1 / scaleFactor;

    const dx = (viewBox.width * (1 - mult)) / 2;
    const dy = (viewBox.height * (1 - mult)) / 2;

    viewBox.x = x - dx;
    viewBox.y = y - dy;
    viewBox.width = viewBox.width + 2 * dx;
    viewBox.height = viewBox.height + 2 * dy;

    setViewBox({ ...viewBox });
  }

  function zoomIn(xy, viewBox, setViewBox, width, height, setZoomLevel) {
    zoom(xy, true, viewBox, setViewBox, width, height);
    setZoomLevel((prev) => prev + 1);

  }
  function zoomOut(xy, viewBox, setViewBox, width, height, setZoomLevel) {
    zoom(xy, false, viewBox, setViewBox, width, height);
    setZoomLevel((prev) => prev - 1);

  }

  return { zoomIn, zoomOut };
}
export default Zoom;
