// @ts-nocheck
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Cursor, DEFTYPES } from "./values/enums";

import useFileManager from "./FileManager";
import History from "./History";
import { copy, cut, paste } from "./utils/ClipboardUtils";
import { Group } from "./Group";
import { createMultiSelectBox } from "./utils/PointerUtils";
import { LABELS } from "./values/labels";
import { arrange } from "./utils/ArrangeUtils";
import { useIdGenerator } from "./IdGenerator";
import { ARROW_MARKER_DEFS, FRAME_DEFS, ROOT_GROUP_ID, SITEROOT } from "./values/constants";
import { deleteItems } from "./utils/DeleteUtils";
import Zoom from "./Zoom";
import { snap, snapAngle, snapItem, snapMouse } from "./utils/SnapUtils";
import { getTemplatePreviewUrl, getZoomLevel, rand } from "./utils/utils";
import { allGoogleFonts } from "./values/all-google-fonts";
import { createDesignServer, deleteDesignServer, fetchDesign, req, updateDesignServer } from "./utils/ServerUtils";
import { createTheme, useMediaQuery } from "@mui/material";
import { fetchDesigns } from "./utils/ServerUtils";
import { createDesignFromTemplateServer } from "./utils/ServerUtils";
import { getCoordsInSvg } from "./utils/utils";
import { generateHTML, useEditor } from "@tiptap/react";
import { EditorExtensions } from "./views/Tiptap";
import { GoogleFontFamilies, WebSafeFonts } from "./values/GoogleFontFamilies";

const mainGroup = new Group(ROOT_GROUP_ID);

let subdomain = window.location.host.split(".")[0];
if (subdomain == "designkart") subdomain = null;
export const LOGIN_URL = SITEROOT + "/login" + (subdomain ? "?from=" + subdomain : "");
console.log("login url:", LOGIN_URL, subdomain);

const SVGContext = createContext({});
export const SVGProvider = ({ children }) => {
  const [items, setItems] = useState({});
  const [groups, setGroups] = useState({ [ROOT_GROUP_ID]: mainGroup }); // FIXME, use constants
  const [defs, setDefs] = useState([]);
  const [selectedIds, setSelectedIdsLocal] = useState([]);
  const [outline, setOutline] = useState(null);

  const [multiSelectBox, setMultiSelectBox] = useState(null);

  const [shouldSnap, setShouldSnap] = useState(true);

  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(600);
  const [viewBox, setViewBox] = useState({
    x: 0,
    y: 0,
    width: width,
    height: height,
  });

  const svgRef = React.useRef<SVGSVGElement>(null);

  const [tool, setToolInternal] = useState(null);
  const [cursor, setCursor] = useState(Cursor.Default);

  const [fill, setFill] = useState("transparent");
  const [stroke, setStroke] = useState("black");

  const [resizeRect, setResizeRect] = useState(null);

  const [leftPanel, setLeftPanel] = React.useState(null);
  const [rightPanel, setRightPanel] = React.useState(null);

  const [snapLines, setSnapLines] = useState([]);
  const [showGrid, setShowGrid] = useState(false);

  const [userInfo, setUserInfo] = React.useState(null);
  const [designs, setDesigns] = React.useState(null);
  const [designId, setDesignId] = React.useState(null);
  const [designTitle, setDesignTitle] = React.useState(null);
  const [designTemplateId, setDesignTemplateId] = React.useState(null);

  const [replacingItemId, setReplacingItemId] = React.useState(null);
  const [croppingItemId, setCroppingItemId] = React.useState(null);

  const [pages, setPages] = React.useState([]);
  const [selectedPage, setSelectedPage] = React.useState(null);
  const [showPages, setShowPages] = React.useState(false);
  const [fullscreenLoading, setFullscreenLoading] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(100);

  function getFontDefs(fonts) {
    return fonts.map((font) => {
      return {
        type: DEFTYPES.IMPORTURL,
        url: `https://fonts.googleapis.com/css2?family=${font.replace(" ", "+")}&display=swap`,
      };
    });
  }

  useEffect(() => {
    if (pages && pages[selectedPage]) {
      let page = pages[selectedPage];

      for (let id in page.items) {
        const item = page.items[id];
        item.id = "" + item.id; // delete once fixed on server

        if (item.proseMirrorData) {
          item.html = item.html || generateHTML(item.proseMirrorData, EditorExtensions);
        }
      }
      setItems(page.items);
      setGroups(page.groups);
      const defs = page.defs;
      setDefs(defs);
      setWidth(page.width);
      setHeight(page.height);
      setViewBox(page.viewbox);
      setSelectedIds([]);
    }
  }, [pages, selectedPage]);

  const clearTopbarSelectedButtons = () => {
    setCroppingItemId(null);
    setReplacingItemId(null);
    setRightPanel(null);
  };
  const setSelectedIds = (indices) => {
    setSelectedIdsLocal(indices);
    createMultiSelectBox(items, indices, setMultiSelectBox, svgRef);
    clearTopbarSelectedButtons();
  };

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("/login") || url.includes("/register")) {
      return;
    }

    // if url has localhost
    if (window.location.href.includes("localhost")) {
      if (window.location.href.includes("loggedin")) {
        setUserInfo({
          name: "Yash",
          email: "yash@gmail.com",
        });
        fetchDesigns().then(setDesigns);
      } else {
        setUserInfo(null);
      }
    } else {
      req(`/user_info`)
        .then((uinfo) => {
          setUserInfo(uinfo);
          if (!designs) {
            fetchDesigns().then(setDesigns);
          }
        })
        .catch((error) => {
          window.location.href = LOGIN_URL;

          console.error("Error fetching user info:", error);
          setUserInfo(null);
        });
    }

    const location = window.location;

    const designId = location.pathname.split("?")[0].split("/design/")[1];
    if (designId) {
      fetchDesign(designId)
        .then((data) => {
          console.log("Design fetched successfully!", data);
          setPages(data.pages);
          setSelectedPage(0);
          setDesignTitle(data.title);
          setDesignId(data.id);
          data.pages
            .map((page) => page.fonts)
            .flat()
            .forEach((font) => {
              if (allGoogleFonts[font] && fonts.indexOf(font) == -1) {
                fonts.push(font);
              }
            });
          setFonts([...fonts]);
        })
        .catch((error) => {
          console.error("Error fetching design:", error);
          setDesignId(null);
        });
    }
  }, []);

  const onTemplateSelect = (id) => {
    setFullscreenLoading(true);
    if (!userInfo) {
      setFullscreenLoading(false);
      window.location.href = LOGIN_URL;
      return;
    }
    if (designId) {
      req(`/template?id=${id}`)
        .then((pages) => {
          setFullscreenLoading(false);

          pages = pages.map((page, i) => {
            let preview = `https://movingvectors.s3.amazonaws.com/template-previews/${id}`;
            if (!preview.endsWith(".jpg")) {
              preview += `.${i}.webp`;
            }
            return {
              ...page,
              preview,
            };
          });
          setPages(pages);
          setSelectedPage(0);
          setDesignTemplateId(id);
          setDesignTitle(title);
        })
        .catch((e) => {
          setFullscreenLoading(false);
        });
    } else {
      createDesignFromTemplateServer(id)
        .then((res) => {
          setFullscreenLoading(false);
          window.location.href = `/design/${res.design_id}`;
        })
        .catch((e) => {
          setFullscreenLoading(false);
        });
    }
  };
  // when selectedIds is changed, multibox has to be updated
  let [debounceTimeout, setDebounceTimeout] = useState(null);
  useEffect(() => {
    if (selectedIds.length < 10) {
      // debounce
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        createMultiSelectBox(items, selectedIds, setMultiSelectBox, svgRef);
        const fills = Array.from(new Set(selectedIds.map((i) => items[i]?.fill)));
        const strokes = Array.from(new Set(selectedIds.map((i) => items[i]?.stroke)));
        if (fills.length == 1) setFill(fills[0]);
        if (strokes.length == 1) setStroke(strokes[0]);
      }, 50);
      setDebounceTimeout(debounceTimeout);
    }
  }, [selectedIds, items, groups]);

  const addToGroup = (item, group = mainGroup) => {
    item.group = group.id;
    groups[group.id].children.push(item.id);
    setGroups({ ...groups });
    return item;
  };
  const deleteFromGroup = (item) => {
    const group = groups[item.group];
    const idx = group.children.findIndex((x) => x == item.id);
    group.children.splice(idx, 1);
    groups[item.group] = group;
    setGroups({ ...groups });

    delete item.group;
    return item;
  };

  /********** MANAGERS ************/
  const idGenerator = useIdGenerator();
  const fileManager = useFileManager();
  const zoom = Zoom({ multiSelectBox });
  const history = History({ setItems, setSelectedIds });

  const setTool = (nextTool) => {
    if (tool && tool.onToolUnselect) {
      tool.onToolUnselect(items, selectedIds);
    }

    tool && tool.decorateItem && removeDecorator(tool);
    tool && tool.decorateSvg && removeSvgDecorator(tool);

    setToolInternal(nextTool);
    if (nextTool && nextTool.onToolSelect) {
      nextTool.onToolSelect(items, selectedIds);
    }
    nextTool && nextTool.decorateItem && addDecorator(nextTool);
    nextTool && nextTool.decorateSvg && addSvgDecorator(nextTool);
  };

  /*********************************** saving & loading json, svg etc *******************/

  function changeDimensions(args) {
    args.width = parseInt(("" + args.width).replace("px", "")) || 500;
    args.height = parseInt(("" + args.height).replace("px", "")) || 500;
    setWidth(args.width);
    setHeight(args.height);
    args.viewbox = args.viewbox || {
      x: 0,
      y: 0,
      width: args.width,
      height: args.height,
    };
    setViewBox(args.viewbox);
  }
  function addText(text, proseMirrorData) {
    const item = {
      id: "text-" + rand(),
      type: "text",
      x: 0,
      y: 0,
      width: 300,
      height: 100,
      proseMirrorData: proseMirrorData,
      html: generateHTML(proseMirrorData, EditorExtensions),
    };
    items[item.id] = item;
    item.group = ROOT_GROUP_ID;
    groups[ROOT_GROUP_ID].children.push(item.id);
    setItems({ ...items });
    setGroups({ ...groups });
  }
  function addImage(image) {
    const w = width / 2;
    const aspectRatio = image.width && image.height ? image.width / image.height : 1;
    const h = w / aspectRatio;

    const src = image.src;
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const src = reader.result;
          console.log(src);
          replace({
            id: "mpimage-" + rand(), // will get overwritten if replacing
            type: "mpimage",
            x: 0,
            y: 0,
            width: w,
            height: h,
            url: src,
            crop: {
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            },
            imageRect: {
              height: h,
              width: w,
            },
          });
        };
        reader.readAsDataURL(blob);
      })
      .catch((e) => console.error("Error fetching image", e));
  }

  function processIconSvg(item) {
    // note: this same function exists in server-side preprocessing code, in py.
    // note: expects item to have svg, width, height

    let svg = item.svg.replace(/\n/g, ""); // \n causes regex matching issues

    const svgTag = svg.match(/<svg[^>]*>/)[0];

    // extract viewbox
    let viewbox = mat(svgTag, / viewBox="([^"]*)"/i);
    item.viewbox = item.viewbox || viewbox;
    if (!item.viewbox || item.viewbox.split(" ").length != 4) {
      // if no viewbox or invalid viewbox, set it to 0 0 width height
      item.viewbox = `0 0 ${item.width} ${item.height}`;
    }

    // extract fill
    const fill = mat(svgTag, / fill="([^"]*)"/i);
    if (fill) {
      item.fill = fill;
    }

    // extract stroke and stroke-width
    const stroke = mat(svgTag, / stroke="([^"]*)"/i);
    const strokeWidth = mat(svgTag, / stroke-width="([^"]*)"/i);
    item.stroke = stroke;
    item.strokeWidth = strokeWidth;

    // finally, delete the svg tag and just keep the inner code
    svg = mat(svg, /<svg[^>]*>(.*)<\/svg>/);

    item.svg = svg;
    return item;
  }

  function addShape(shape, fill, stroke) {
    const item = {
      type: "path",
      id: "path-" + rand(),
      ...shape,

      x: width / 2,
      y: height / 2,
      fill: fill,
      stroke: stroke,
      strokeWidth: 1,
      width: width / 3,
      height: height / 3,
    };
    items[item.id] = item;
    item.group = ROOT_GROUP_ID;
    groups[ROOT_GROUP_ID].children.push(item.id);
    setGroups({ ...groups });
    setItems({ ...items });
  }
  function addIcon(svg) {
    replace(
      processIconSvg({
        type: "icon",
        id: "icon-" + rand(), // will get overwritten if replacing
        x: width / 2,
        y: height / 2,
        height: height / 3,
        width: width / 3,
        fill: "black",
        stroke: "black",
        strokeWidth: 1,
        svg: svg,
      }),
    );
  }

  function replace(item) {
    if (replacingItemId) {
      // delete this
      const oldItem = items[replacingItemId];

      // set same props
      for (const key of ["x", "y", "height", "width", "fill", "stroke", "strokeColor", "rotateDeg", "group", "frame", "imageRect", "mpTransforms", "mpTransformStr", "crop"]) {
        item[key] = oldItem[key];
      }
      item.id = replacingItemId;
      items[replacingItemId] = item;

      setReplacingItemId(null);
    } else {
      item.group = ROOT_GROUP_ID;
      items[item.id] = item;
      groups[ROOT_GROUP_ID].children.push(item.id);
    }
    setItems({ ...items });
    setGroups({ ...groups });
  }

  /***************** items, groups, defs management ************************/

  function setThings(items, groups, ids, msg) {
    setItems({ ...items });
    setGroups({ ...groups });
    setSelectedIds([...ids]);
  }

  function modifyItem(item) {
    items[item.id] = item;
    setItems({ ...items });
  }

  function modifyItems(o) {
    deleteItems(o.delete);
    addItems(o.add, o.msg);
  }

  function addItems(its, msg = "add items") {
    for (let it of its) {
      items[it.id] = it;
      it.group = it.group || mainGroup.id;
      groups[it.group].children.push(it.id);
    }
    const ids = its.map((i) => i.id);
    setThings(items, groups, ids, msg);
  }

  function addItem(item, msg = "add item", group = mainGroup) {
    items[item.id] = item;
    item.group = group.id;
    if (!groups[group.id]) {
      groups[group.id] = group;
      groups[mainGroup.id].children.push(group.id);
    }
    groups[group.id].children.push(item.id);
    setThings(items, groups, [item.id], msg);

    history.addUndo(() => {
      delete items[item.id];
      deleteFromGroup(item);
      setItems({ ...items });
      setGroups({ ...groups });
      setSelectedIds([]);
    });
  }

  function deleteItems(ids, msg = "delete items") {
    for (let i of ids) {
      const item = items[i];
      if (!item.group) continue; // shapebuilder items
      const idx = groups[item.group].children.findIndex((x) => x == item.id);
      groups[item.group].children.splice(idx, 1);
    }
    for (let i of ids) {
      delete items[i];
    }
    for (let id in groups) {
      if (id != ROOT_GROUP_ID && groups[id].children.length == 0) {
        delete groups[id];
      }
    }

    setThings(items, groups, [], msg);
    return { items, groups };
  }

  function modifyDef(def) {
    const idx = defs.findIndex((d) => d.id === def.id);
    if (idx > -1) {
      defs[idx] = def;
      setDefs([...defs]);
    }
  }

  // this is just for showing in the top bar
  const [fonts, setFonts] = useState([...WebSafeFonts, ...GoogleFontFamilies]);
  const [editorState, setEditorState] = useState({
    font: "Arial",
    color: "black",
    fontSize: "12px",
  });

  const editor = useEditor({
    extensions: EditorExtensions,
    onUpdate: ({ editor }) => {
      if (!items[selectedIds[0]]?.proseMirrorData) return;

      items[selectedIds[0]].proseMirrorData = editor.getJSON();
      items[selectedIds[0]].html = editor.getHTML();
      setItems({ ...items });

      setEditorState({
        ...editorState,
        font: editor.getAttributes("textStyle").fontFamily || "Arial",
        color: editor.getAttributes("textStyle").color || "black",
        fontSize: editor.getAttributes("textStyle").fontSize || "12px",
      });
    },
    onSelectionUpdate: ({ editor }) => {
      setEditorState({
        ...editorState,
        font: editor.getAttributes("textStyle").fontFamily || "Arial",
        color: editor.getAttributes("textStyle").color || "black",
        fontSize: editor.getAttributes("textStyle").fontSize || "12px",
      });
    },
  });

  useEffect(() => {
    editor?.commands.setContent(items[selectedIds[0]]?.proseMirrorData);
  }, [selectedIds]);

  return (
    <SVGContext.Provider
      value={{
        items,
        setItems,

        // mainGroup,
        groups,
        setGroups,

        width,
        height,
        svgRef,
        arrange: (type) =>
          arrange({
            type,
            selectedIds,
            items,
            setItems,
            groups,
            setGroups,
            setSelectedIds,
          }),

        multiSelectBox,
        setMultiSelectBox,

        history: history,
        undo: history.undo,
        redo: history.redo,
        addToHistory: history.addToHistory,
        historyList: history.historyList,

        zoomIn: (xy) => zoom.zoomIn(xy, viewBox, setViewBox, width, height, setZoomLevel),
        zoomOut: (xy) => zoom.zoomOut(xy, viewBox, setViewBox, width, height, setZoomLevel),
        zoomLevel,
        setZoomLevel,

        viewBox: viewBox,
        setViewBox: setViewBox,
        handleWheel: zoom.handleWheel,
        tool,
        setTool,
        selectedIds,
        setSelectedIds,
        defs,
        setDefs,

        cursor,
        setCursor,

        fileManager: fileManager,

        fill,
        setFill,
        stroke,
        setStroke,

        cut: () => cut({ items, addItems, selectedIds, deleteItems }),
        copy: (asFormat = "json") =>
          copy({
            asFormat,
            items,
            setItems,
            selectedIds,
            setSelectedIds,
            svgRef,
            fileManager,
            width,
            height,
          }),
        paste: () =>
          paste({
            items,
            changeDimensions,
            addItems,
          }),

        resizeRect,
        setResizeRect,
        changeDimensions,

        outline,
        setOutline,

        label: (str) => {
          const labels = LABELS["English"];
          return (labels && labels[str]) || str;
        },

        idGenerator,
        id: idGenerator.id,

        addToGroup: addToGroup,
        deleteFromGroup: deleteFromGroup,

        deleteItems,
        addItems,
        addItem,
        modifyItems,
        modifyItem,
        modifyDef,

        leftPanel,
        setLeftPanel,
        rightPanel,
        setRightPanel,

        snapLines,
        setSnapLines,
        shouldSnap,
        setShouldSnap,
        snapMouse: (xy) => {
          // shouldSnap && snapMouse({ xy, selectedIds, items, snapLines, setSnapLines });
        },
        snapAngle: (xy, pxy, center) => {
          snapAngle({ xy, pxy, center });
        },
        snapItem: ({ xy, items: its }) => shouldSnap && snapItem({ xy, items: its, selectedIds, snapLines, setSnapLines }),

        showGrid,
        setShowGrid,

        toSvgUnits: (px) => getZoomLevel(svgRef) * px,

        mousePos,
        setMousePos,

        replacingItemId,
        setReplacingItemId,

        userInfo,
        designs,

        createDesignServer: (args, cb) => createDesignServer(args, cb),
        deleteDesign: (designId, cb) => deleteDesignServer(designId, cb),

        addImage,
        addText,
        addShape,
        addIcon,

        croppingItemId,
        setCroppingItemId,
        clearTopbarSelectedButtons,

        pages,
        setPages,
        selectedPage,
        setSelectedPage,
        setDesignTemplateId,
        designTitle,
        designId,
        setDesignTitle,
        showPages,
        setShowPages,
        onTemplateSelect,

        fullscreenLoading,
        setFullscreenLoading,

        isMobile,
        drawerOpen,
        setDrawerOpen,

        editor: editor,
        editorState,
        setEditorState,
        fonts,
      }}
    >
      {children}
    </SVGContext.Provider>
  );
};

export const useSVG = () => useContext(SVGContext);

function toCamelCase(obj) {
  for (let key in obj) {
    let newKey = key.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    if (newKey != key) {
      obj[newKey] = obj[key];
      delete obj[key];
    }
  }
  return obj;
}

function mat(str, pat) {
  return str.match(pat) ? str.match(pat)[1] : null;
}

export const DARKTHEME = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#dcdcdc",
    },
  },
});

export const THEME_BG_DULLER = "#383838";
export const THEME_BG = "#191919";
export const THEME_TEXT = "#f0f0f0";
