import { useRef, useState } from "react";
import { getItemFilterString } from "../RightPanel";
import { useSVG } from "../SVGContext";
import Cropper, { getPoints } from "../Cropper";

export function MpImageView({ item, overlay }) {
  if (!item.imageRect) return null; // qr code

  const { croppingItemId, items, setItems } = useSVG();
  const [points, setPoints] = useState(getPoints(item));
  const imageRef = useRef(null);
  let [mouseDown, setMouseDownInt] = useState(null);
  let [mouseMove, setMouseMoveInt] = useState(null);

  const setMouseDown = (xy) => {
    mouseDown = xy;
    setMouseDownInt(xy);
  };
  const setMouseMove = (xy) => {
    mouseMove = xy;
    setMouseMoveInt(xy);
  };

  const onMouseDown = (e) => {
    if (!overlay || croppingItemId != item.id) return;

    setMouseDown({
      x: e.clientX,
      y: e.clientY,
    });
    setMouseMove({
      x: e.clientX,
      y: e.clientY,
    });
    e.preventDefault();
    e.stopPropagation();
  };
  const onMouseMove = (e) => {
    if (!overlay || croppingItemId != item.id) return;

    if (mouseDown) {
      const dx = mouseMove.x - e.clientX;
      const dy = mouseMove.y - e.clientY;
      let newCrop = {
        top: +item.crop.top + dy,
        right: +item.crop.right - dx,
        bottom: +item.crop.bottom - dy,
        left: +item.crop.left + dx,
      };
      item.crop = newCrop;
      items[item.id] = item;
      setItems({ ...items });

      setMouseMove({
        x: e.clientX,
        y: e.clientY,
      });
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const onMouseUp = (e) => {
    if (!overlay || croppingItemId != item.id) return;

    setMouseDown(null);
    setMouseMove(null);
    e.preventDefault();
    e.stopPropagation();
  };
  if (item.type == "mpimage") {
    const frameStyle = getFrameStyle(item.frame);
    return (
      <>
        <foreignObject id={item.id} x={item.x} y={item.y} width={item.width} height={item.height}>
          <div xmlns="http://www.w3.org/1999/xhtml" style={frameStyle}>
            <div
              style={{
                inset: insetStyle(item.crop),
                height: (item.imageRect.height || item.imageRect.heightPx) + "px",
                width: (item.imageRect.width || item.imageRect.widthPx) + "px",
                position: "relative",
              }}
            >
              <img
                ref={imageRef}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  filter: getItemFilterString(item.filter),
                }}
                draggable="false"
                src={item.url}
                alt={item.altText || ""}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
              ></img>
            </div>
          </div>
        </foreignObject>
        {croppingItemId == item.id && overlay && <Cropper item={item} imageRef={imageRef} />}
      </>
    );
  }
}

export function getFrameStyle(frame) {
  if (!frame) return {};
  return {
    position: "absolute",
    width: "100%",
    height: "100%",
    clipPath: `url(#frame_${frame.toLowerCase()})`,
  };
}

export const insetStyle = (crop) => {
  if (!crop || crop.length == 0) return null;
  return -crop.top + "px " + crop.right + "px " + crop.bottom + "px " + -crop.left + "px";
};
