import { Button } from "@mui/material";
import { useSVG } from "./SVGContext";

export function AddTemplateBtn({ addString }) {
  return (
    <div
      className="add-btn"
      style={{
        position: "absolute",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        display: "none",

        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        style={{
          backgroundColor: "rgb(20 25 122 )",
          color: "white",
          borderRadius: "5px",
          padding: "10px 15px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        }}
      >
        {addString}
      </Button>
    </div>
  );
}
