import { IconButton } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { toDegree } from "../utils/utils";
import { getTransformStr } from "../utils/transformUtils";

const useRotator = ({ item, setItems }) => {
  let [moving, setMoving] = useState(false);
  let [angle, setAngle] = useState(0);
  let [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  let [center, setCenter] = useState({ x: 0, y: 0 });
  let [originalAngle, setOriginalAngle] = useState(0);

  function onMouseDownNew({ event }) {
    moving = true;
    setMoving(true);

    const box = document.getElementById(item.id).getBBox();
    center = { x: box.x + box.width / 2, y: box.y + box.height / 2 };
    setCenter(center);

    originalAngle = +item.mpTransforms?.rotate?.angle || 0;
    setOriginalAngle(originalAngle);

    angle = 0;
    setAngle(angle);

    mousePos = { x: event.clientX, y: event.clientY };
    setMousePos(mousePos);

    event.stopPropagation();
    event.preventDefault();
  }

  function onMouseMoveNew({ event, z }) {
    if (!moving) return;

    const pos = { x: event.clientX, y: event.clientY };
    const deg = angleAtB(mousePos, center, pos);

    angle = +deg;
    setAngle(deg);

    item.mpTransforms = {
      ...item.mpTransforms,
      rotate: { angle },
    };
    item.mpTransformStr = getTransformStr(item);
    setItems({ ...z.items });

    event.stopPropagation();
    event.preventDefault();
  }

  function onMouseUpNew({ event, z }) {
    moving = false;
    setMoving(false);

    item.mpTransforms = {
      ...item.mpTransforms,
      rotate: { angle },
    };
    item.mpTransformStr = getTransformStr(item);
    setItems({ ...z.items });

    mousePos = { x: 0, y: 0 };
    setMousePos(mousePos);

    center = null;
    setCenter(center);

    event.stopPropagation();
    event.preventDefault();

    z.setTool(null);
  }
  return {
    onMouseDownNew,
    onMouseMoveNew,
    onMouseUpNew,
  };
};

export const RotateBtn = ({ item }) => {
  const { setTool, tool, setItems } = useSVG();

  const r = useRotator({ item, setItems });
  const x = item.x + item.width / 2;
  const y = item.y + item.height + 20;

  return (
    <foreignObject x={x} y={y} width={200} height={200}>
      <IconButton
        className="rotate-btn"
        size="small"
        onMouseEnter={() => {
          setTool(r);
        }}
        onMouseLeave={() => {
          if (tool.name == "rotate") {
            setTool(null);
          }
        }}
      >
        <LoopIcon />
      </IconButton>
    </foreignObject>
  );
};

function angleAtB(a, b, c) {
  const ang1 = Math.atan2(b.y - a.y, b.x - a.x);
  const ang2 = Math.atan2(b.y - c.y, b.x - c.x);
  let deg = toDegree(ang2 - ang1);
  return deg;
}
