export const TEMPLATE_CATEGORIES = [
  "Layouts",
  "Infographics",
  "Business Cards",
  "Social Media",
  "Posters",
  "Invitations",
  "Cards",
  "Banners",
  "Contracts",
  "Roadmaps",
  "Education",
  "Diagrams",
  "Flyers",
  "Newsletters",
  "Coupons",
  "Presentations",
  "Brochures",
  "Certificates",
  "Proposals",
  "Album Covers",
  "Collages",
  "Resumes",
  "Plans",
  "Checklists",
  "Logos",
  "Mind Maps",
  "Letterheads",
  "Human Resources",
  "Reports",
  "Estimates",
  "Book Covers",
  "Planners",
  "Schedules",
  "Postcards",
  "Labels",
  "Price Lists",
  "Invoices",
  "Menus",
  "Table Of Contents",
  "Ebooks",
  "Letters",
  "Magazine Covers",
  "Catalogs",
  "Forms",
  "Charts",
  "White Papers",
];
